import * as yup from 'yup';

/* eslint-disable import/prefer-default-export */

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is not valid!'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});
