import { PriceParts } from 'graphql/generated/PriceParts';

export const normalizePrice = (input: PriceParts) => {
  return {
    date: input.startDate || undefined,
    price: input.pricePerDay || undefined,
    instantBooking: input.instantBooking || undefined,
  };
};

export type Price = ReturnType<typeof normalizePrice>;
