import React, { FC, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Props } from './types';
import {
  FullScreen,
  Fade,
  Default,
  Header,
  Title,
  Button,
  CloseIcon,
  ModalContent,
  Body,
  Footer,
  EmptyBox,
  HeaderMobile,
} from './styles';
import useConnect from './connect';

const Modal: FC<Props> = ({ open, handleClose, title, headerElement, children, ...props }) => {
  const { isMobile } = useConnect();

  if (isMobile) {
    return (
      <Dialog open={open} onClose={handleClose} fullScreen {...props}>
        <FullScreen>
          <Fade in={open}>
            <>
              <HeaderMobile>
                <Button type="button" onClick={handleClose}>
                  <CloseIcon />
                </Button>
                <Title>{title}</Title>
                {headerElement || <EmptyBox />}
              </HeaderMobile>
              <Body>{children}</Body>
            </>
          </Fade>
        </FullScreen>
      </Dialog>
    );
  }

  return (
    <Default
      open={open}
      onClose={handleClose}
      closeAfterTransition
      {...props}
    >
      <ModalContent className="modal-ui">
        <Header>
          <Button type="button" onClick={handleClose}>
            <CloseIcon />
          </Button>
          <Title>{title}</Title>
          <EmptyBox />
        </Header>
        <Body>{children}</Body>
      </ModalContent>
    </Default>
  );
};

export { Footer };

export default memo(Modal);
