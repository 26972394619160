import React, { FC, memo } from 'react';
import ModalScreen, { CloseModalButton } from 'components/ModalScreen';
import { Footer, Pagination, Image } from './styles';
import { Props } from './types';
import useLogic from './logic';

const Gallery: FC<Props> = ({
  images,
  open,
  handleClose,
  children,
  ...props
}) => {
  const { currentPage, handleChangePage } = useLogic();
  return (
    <ModalScreen open={open} handleClose={handleClose} {...props}>
      <CloseModalButton onClick={handleClose} />

      {images && (
        <Image
          src={process.env.REACT_APP_API_URL+images[currentPage - 1]?.src}
          alt={images[currentPage - 1].alt}
        />
      )}

      <Footer>
        <Pagination
          fullRange={false}
          page={currentPage}
          pages={images && images.length}
          onChangePage={handleChangePage}
        />
      </Footer>
    </ModalScreen>
  );
};

export default memo(Gallery);
