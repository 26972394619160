import styled from 'styled-components';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg';

export const Component = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem;
  border: 0;
  border-radius: 0.3125rem;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  :hover {
    background-color: ${({ theme }) => theme.colors.royalBlue100};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.royalBlue200};
    path {
      fill: ${({ theme }) => theme.colors.royalBlue600};
    }
  }
`;

export const Icon = styled(ArrowBackIcon)`
  fill: ${({ theme }) => theme.colors.royalBlue};
  width: 1.75rem;
  height: 1.75rem;
`;
