import { parse } from 'date-fns';
import {
  toInterfaceDayFormat,
  INTERFACE_DAY_FORMAT,
  today as todayUtil,
} from 'utils/formatDate';
import { DateUtils } from 'react-day-picker';

export const today = todayUtil;

export const WEEKDAYS = ['D', 'L', 'M', 'X', 'J', 'V', 'S'];
export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const parseDate = (str: string) => {
  const parsed = parse(str, INTERFACE_DAY_FORMAT, new Date());

  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
};

export const formatDate = (date: Date) => {
  return toInterfaceDayFormat(date);
};

export const parseCurrentDate = (date: Date) => {
  const monthNumber = date.getMonth();
  const year = date.getFullYear();
  return `${MONTHS[monthNumber]} ${year}`;
};
