import { useState } from 'react';

const useLogic = () => {
  const [isLiked, setIsLiked] = useState(false);


  return {
    isLiked,
    setIsLiked,
  };
};

export default useLogic;
