import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { appConfig } from 'config';
import { getToken, removeToken } from 'services/token';
import jwtDecode from 'jwt-decode';
import useAuth from "./hooks/useAuth";

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken();

  if (token) {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken?.exp < currentTime) {
      const { logout } = useAuth();
      logout().then();

      return forward(operation);
    }

    // @ts-ignore
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }));
  }

  return forward(operation);
});

const cache = new InMemoryCache();

const httpLink = new HttpLink({ uri: `${appConfig.apiUrl}/graphql` });

const logoutLink = onError(({ networkError }) => {
  const error =
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401;

  if (error) removeToken();
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([logoutLink, authLink, httpLink]),
});

export default client;
