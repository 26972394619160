import React from 'react';
import TextStyles from 'components/TextStyles';
import { Container } from './styles';

const GeneralTerms = () => {
  return (
    <TextStyles>
      <Container>
        <h2>Términos Generales</h2>
        <p>
          De conformidad con lo establecido en la Ley 34/2002, de 11 de julio,
          de servicios de la sociedad de la información y de comercio
          electrónico, se facilita la siguiente información:
        </p>

        <h4>Datos identificativos</h4>
        <p>
          Nombre: SELECCIÓN DE ESPACIOS PARA EVENTOS SL (en adelante Sedee)

          N.I.F.: B90371220

          Domicilio social: C. Antonio Machado, 13, 41120, Gelves, Sevilla Correo electrónico: info@sedee.io

          Web: www.sedee.io
        </p>
        <h4>
          Usuarios
        </h4>
        <p>Las presentes condiciones (en adelante Aviso Legal) tiene por finalidad regular el uso de la página web de EL TITULAR que pone a disposición del público.</p>
        <p>
          El acceso y/o uso de esta página web atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, las condiciones generales de uso aquí reflejadas. Las citadas condiciones serán de aplicación independientemente de las condiciones generales de contratación que en su caso resulten de obligado cumplimiento.
        </p>
        <h4>
          Uso del portal
        </h4>
        <p>
          www.sedee.io proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante, “los contenidos”) en Internet pertenecientes a EL TITULAR o a sus licenciantes a los que el USUARIO puede tener acceso.
        </p>
        <p>
          El usuario asume la responsabilidad del uso del portal. Dicha responsabilidad se extiende al registro que fuese necesario para acceder a determinados servicios o contenidos. En dicho registro el USUARIO será responsable de aportar información veraz y lícita. Como consecuencia de este registro, al USUARIO se le puede proporcionar una contraseña de la que será responsable, comprometiéndose a hacer un uso diligente y confidencial de la misma.
        </p>
        <p>
          El USUARIO se compromete a hacer un uso adecuado de los contenidos y servicios (p.e. servicios de chat, foros de discusión o grupos de noticias) que EL TITULAR ofrece a través de su portal y con carácter enunciativo pero no limitativo, a no emplearlos para:
        </p>
        <ul>
          <li>
            Incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público.
          </li>

          <li>
            Difundir contenidos o propaganda racista, xenófoba, pornográfico-ilegal, de apología del terrorismo o atentatoria contra los derechos humanos.
          </li>

          <li>
            Provocar daños en los sistemas físicos y lógicos de Sedee, de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados.
          </li>

          <li>
            Intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes.
          </li>
          <li>
            Utilizar el sitio web ni las informaciones que en él se contienen con fines comerciales, políticos, publicitarios y para cualquier uso comercial, sobre todo en el envío de correos electrónicos no solicitados.
          </li>
        </ul>
        <p>
          EL TITULAR se reserva el derecho a retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultarán adecuados para su publicación. En cualquier caso, EL TITULAR no será responsable de las opiniones vertidas por los usuarios a través de los foros, chats, u otras herramientas de participación.
        </p>
        <h4>
          Protección de datos
        </h4>
        <p>
          Todo lo relativo a la política de protección de datos se encuentra recogido en el documento de política de privacidad.
        </p>
        <h4>
          Contenidos. Propiedad intelectual e industrial
        </h4>
        <p>
          EL TITULAR es propietario de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo: imágenes, fotografías, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc), titularidad del TITULAR o bien de sus licenciantes.
        </p>
        <p>
          Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización del TITULAR.
        </p>
        <h4>
          Exclusión de garantías y responsabilidad
        </h4>
        <p>
          EL USUARIO reconoce que la utilización de la página web y de sus contenidos y servicios se desarrolla bajo su exclusiva responsabilidad. En concreto, a título meramente enunciativo, EL TITULAR no asume ninguna responsabilidad en los siguientes ámbitos:
        </p>
        <ul>
          <li>
            La disponibilidad del funcionamiento de la página web, sus servicios y contenidos y su calidad o interoperabilidad.
          </li>
          <li>
            La finalidad para la que la página web sirva a los objetivos del USUARIO.
          </li>
          <li>
            La infracción de la legislación vigente por parte del USUARIO o terceros y, en concreto, de los derechos de propiedad intelectual e industrial que sean titularidad de otras personas o entidades.
          </li>
          <li>
            La existencia de códigos maliciosos o cualquier otro elemento informático dañino que pudiera causar el sistema informático del USUARIO o de terceros. Corresponde al USUARIO, en todo caso, disponer de herramientas adecuadas para la detección y desinfección de estos elementos.
          </li>
          <li>
            El acceso fraudulento a los contenidos o servicios por terceros no autorizados, o, en su caso, la captura, eliminación, alteración, modificación o manipulación de los mensajes y comunicaciones de cualquier clase que dichos terceros pudiera realizar.
          </li>
          <li>
            La exactitud, veracidad, actualidad y utilidad de los contenidos y servicios ofrecidos y la utilización posterior que de ellos haga el USUARIO. EL TITULAR empleará todos los esfuerzos y medios razonables para facilitar la información actualizada y fehaciente.
          </li>
          <li>
            Los daños producidos a equipos informáticos durante el acceso a la página web y los daños producidos a los USUARIOS cuando tengan su origen en fallos o desconexiones en las redes de telecomunicaciones que interrumpan el servicio.
          </li>
          <li>
            Los daños o perjuicios que se deriven de circunstancias acaecidas por caso fortuito o fuerza mayor.
          </li>
        </ul>
        <p>
          En caso de que existan foros, el uso de los mismos u otros espacios análogos, ha de tenerse en cuenta que los mensajes reflejen únicamente la opinión del USUARIO que los remite, que es el único responsable. EL TITULAR no se hace responsable del contenido de los mensajes enviados por el USUARIO.
        </p>
        <h4>
          Modificación de este aviso legal y duración
        </h4>
        <p>
          EL TITULAR se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tantos contenidos y servicios que se presten a través de la misma, como la forma en la que éstos aparezcan representados o localizados en su portal.
        </p>
        <p>
          La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
        </p>
        <h4>
          Enlaces
        </h4>
        <p>
          En el caso de que en www.sedee.io incluyesen enlaces o hipervínculos hacia otros sitios de Internet, EL TITULAR no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso EL TITULAR asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier materia o información contenida en ninguno de dichos hipervínculos y otros sitios en Internet. Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.
        </p>
        <h4>
          Derechos de exclusión
        </h4>
        <p>
          EL TITULAR ser reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de advertencia previa, a instancia propia o de un tercero, a aquellos usuarios que incumplan el contenido de este aviso legal.
        </p>
        <h4>
          Generalidades
        </h4>
        <p>
          EL TITULAR perseguirá el incumplimiento de las presentes condiciones así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.
        </p>
        <h4>
          Legislación aplicable y jurisdicción
        </h4>
        <p>
          Para cuantas cuestiones interpretativas o litigiosas relacionadas con el portal web será de aplicación la legislación española y en caso de controversia, ambas partes acuerdan someterse, con renuncia a cualquier otro fuero que pudiera corresponderle, a la jurisdicción de los Juzgados y Tribunales del domicilio del consumidor o presentando la reclamación oportuna rellenando el siguiente formulario electrónico: http://ec.europa.eu/consumers/odr/
        </p>
        <h4>
          Medidas de seguridad.- SSL
        </h4>
        <p>
          El Titular ha contratado para su sitio web un certificado SSL («SecureSockets Layer»)
        </p>
        <p>
          Un certificado SSL permite proteger toda la información personal y confidencial que se pueda manejar en un sitio web, independientemente de la información que se esté transmitiendo, como por ejemplo, desde cualquiera de los formularios de contacto del sitio web hasta el servidor, o los datos introducidos para la suscripción de boletines de noticias o accesos a las áreas protegidas, etc
        </p>
        <p>
          La dirección del sitio web aparecerá en color verde, activándose el protocolo “https” que permite conexiones seguras desde un servidor web al navegador del usuario.
        </p>
      </Container>
    </TextStyles>
  );
};

export default GeneralTerms;
