import styled from 'styled-components';
import DefaultMaxContent from "../../../components/MaxContent";

/* eslint-disable import/prefer-default-export */

export const Container = styled(DefaultMaxContent)`
  padding: 2rem;
`;

export const StarText = styled("h5")`
  padding-left: 1rem;
  font-style: italic;
`;
