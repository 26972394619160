import React, { FC, memo } from 'react';
import { Component, Icon } from './styles';
import { Props } from './types';

const ButtonBack: FC<Props> = ({ ...props }) => {
  return (
    <Component {...props}>
      <Icon />
    </Component>
  );
};

export default memo(ButtonBack);
