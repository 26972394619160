import { useState } from 'react';
import DayPickerInput from 'react-day-picker/types/DayPickerInput';

const useConnect = () => {
  const [nextInput, setNextInput] = useState<DayPickerInput | null>(null);

  return {
    nextInput,
    setNextInput,
  };
};

export default useConnect;