import styled from 'styled-components';
import { H4, H5, Caption } from 'components/Typography';
import DefaultAvatar from 'components/Avatar';
import DefaultButton from 'components/Button';
import DefaultInput from 'components/Input';
import { ReactComponent as PhotoIcon } from 'assets/icons/picture.svg';
import { from } from 'styles/media';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 39.25rem;
  width: 100%;
  padding: 1rem;
  ${from.tablet} {
    margin-left: 5.75rem;
  }
`;

export const Title = styled(H4)`
  display: none;

  ${from.mobile} {
    display: block;
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
  }
`;

export const RowSubtitle = styled(H5)`
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  ${from.tablet} {
    display: grid;
    gap: 5.1875rem;
    grid-template-columns: 134px 1fr;
  }
`;

export const Avatar = styled(DefaultAvatar)`
  display: flex;
  margin-right: 0;
  ${from.tablet} {
    margin-right: 5.1875rem;
  }
`;

export const PhotoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 21.4375rem;
  width: 100%;
`;

export const Button = styled(DefaultButton)``;

export const FormButton = styled(DefaultButton)`
  margin-top: 2.5rem;
  margin-bottom: 6.375rem;
`;

export const UploadHelperText = styled(Caption)`
  margin-top: 1rem;
  text-align: center;
`;

export const IconPhoto = styled(PhotoIcon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 60px;
`;

export const Input = styled(DefaultInput)``;

export const RowForm = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  margin-bottom: 1rem;

  ${from.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const RowFormSave = styled.div`
  display:flex;
  justify-content: flex-end;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray300};
  margin: 3.5rem 0;
`;

export const MobileActions = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 1rem;
  z-index: 9999;
  box-shadow: 0px -5px 20px rgba(51, 46, 191, 0.1);
  ${from.tablet} {
    display: none;
  }
`;

export const CancelButton = styled.button`
  outline: none;
  cursor: pointer;
  background: none;
  border: 0;
  text-align: center;
  margin: 0;
  padding: 0rem 0.85rem;
  font-weight: 500;
  text-decoration: underline;
  transition: all 0.2s;
`;
