import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { SedeeError } from 'graphql/helpers';

import {
    CREATE_BOOKING,
    PAY_BOOKING,
    CANCEL_BOOKING,
    PAY_IN_PROGRESS, BOOKING_CONFIRMED, TRANSFER_BOOKING, BOOKING_TOTAL_PAYMENT,
} from 'graphql/mutations/bookings';
import {
  CreateBooking,
  CreateBookingVariables,
} from 'graphql/generated/CreateBooking';
import { PayBooking, PayBookingVariables } from 'graphql/generated/PayBooking';
import {
  CancelBooking,
  CancelBookingVariables,
} from 'graphql/generated/CancelBooking';
import {
    // eslint-disable-next-line
    payInProgress,
    payInProgressVariables
} from 'graphql/generated/payInProgress';
// eslint-disable-next-line
import {bookingConfirmed, bookingConfirmedVariables} from "../generated/bookingConfirmed";
// eslint-disable-next-line
import {transferBooking, transferBookingVariables} from "../generated/transferBooking";
// eslint-disable-next-line
import {changeTotalPayment, changeTotalPaymentVariables} from "../generated/changeTotalPayment";

let data: any;

const useBookingActions = () => {
  const [createBookingMutation, { loading: isCreateLoading }] =
    useMutation<CreateBooking, CreateBookingVariables>(CREATE_BOOKING);


  const createBooking = useCallback(
    async (input: CreateBookingVariables) => {
      try {
        const response = await createBookingMutation({ variables: input });
        return response.data?.createMeBooking;
      } catch (error) {
        throw new SedeeError('Failed to created booking', error);
      }
    },
    [createBookingMutation]
  );


  const [payInProgressMutation, { loading: isPayInProgressLoading }] =
    useMutation<payInProgress, payInProgressVariables>(PAY_IN_PROGRESS);

  const payInProgress = useCallback(
    async (input: payInProgressVariables) => {
      try {
          await payInProgressMutation({ variables: input });
      } catch (error) {
        throw new SedeeError('Error pagando la reserva', error);
      }
    },
    [payInProgressMutation]
  );
    const [totalPaymentMutation, { loading: isTotalPaymentLoading }] =
        useMutation<changeTotalPayment, changeTotalPaymentVariables>(BOOKING_TOTAL_PAYMENT);

    const totalPayment = useCallback(
        async (input: transferBookingVariables) => {
            try {
                await totalPaymentMutation({ variables: input });
            } catch (error) {
                throw new SedeeError('Error pagando la reserva', error);
            }
        },
        [totalPaymentMutation]
    );

    const [transferBookingMutation, { loading: isTransferBookingLoading }] =
        useMutation<transferBooking, transferBookingVariables>(TRANSFER_BOOKING);

    const transferBooking = useCallback(
        async (input: transferBookingVariables) => {
            try {
                await transferBookingMutation({ variables: input });
            } catch (error) {
                throw new SedeeError('Error pagando la reserva', error);
            }
        },
        [transferBookingMutation]
    );

    const [bookingConfirmedMutation, { loading: isBookingConfirmedLoading }] =
        useMutation<bookingConfirmed, bookingConfirmedVariables>(BOOKING_CONFIRMED);

    const bookingConfirmed = useCallback(
        async (input: bookingConfirmedVariables) => {
            try {
                await bookingConfirmedMutation({ variables: input });
            } catch (error) {
                throw new SedeeError('Error pagando la reserva', error);
            }
        },
        [bookingConfirmedMutation]
    );

    const [payBookingMut, { loading: isPayBookinLoading }] =
        useMutation<PayBooking, PayBookingVariables>(PAY_BOOKING);

    const payBooking = useCallback(
        async (input: PayBookingVariables) => {
            try {
                data = await payBookingMut({ variables: input });
            } catch (error) {
                throw new SedeeError('Error pagando la reserva', error);
            }
        },
        [payBookingMut]
    );

  const [cancelBookingMut, { loading: isCancelBookingLoading }] =
    useMutation<CancelBooking, CancelBookingVariables>(CANCEL_BOOKING);

  const cancelBooking = useCallback(
    async (input: CancelBookingVariables) => {
      try {
        await cancelBookingMut({ variables: input });
      } catch (error) {
        throw new SedeeError('Error cancelando la reserva', error);
      }
    },
    [cancelBookingMut]
  );


  return {
    loading:
        isCreateLoading ||
        isPayBookinLoading ||
        isCancelBookingLoading ||
        isPayInProgressLoading ||
        isTransferBookingLoading ||
        isBookingConfirmedLoading ||
        isTotalPaymentLoading,
        transferBooking,
        createBooking,
        payBooking,
        cancelBooking,
        payInProgress,
        totalPayment,
        data,
        bookingConfirmed
  };
};

export default useBookingActions;
