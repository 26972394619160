import styled from 'styled-components';
import MaxContent from 'components/MaxContent';
import DefaultCity from 'components/Card/City';
import DefaultHero from 'containers/Shared/Hero';

import { from } from 'styles/media';
import { H5 } from 'components/Typography';

export const Hero = styled(DefaultHero)``;

export const Content = styled(MaxContent)`
  margin-bottom: 5rem;
`;

export const Title = styled(H5)`
  font-weight: 600;
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 2rem;
  margin-top: 1.125rem;
  width: 100%;
  height: 100%;

  ${from.tablet} {
    grid-template-rows: repeat(1fr);
    grid-gap: 1.5rem;
  }
`;

export const Card = styled(DefaultCity)``;
