import { memo } from 'react';
import DefaultCheckbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';

const Checkbox = styled(DefaultCheckbox)`
  &.MuiCheckbox-root {
    width: 2.5rem;
    height: 2.5rem;
    overflow: visible;
  }

  &.MuiCheckbox-colorSecondary:hover {
    background-color: ${({ theme }) => theme.colors.gray300};
  }

  &.MuiCheckbox-colorSecondary:focus {
    background-color: ${({ theme }) => theme.colors.gray600};
  }

  &.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.1)};
  }

  &.MuiCheckbox-colorSecondary.Mui-checked:focus {
    background-color: ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.2)};
  }

  &.MuiCheckbox-root.Mui-checked .MuiTouchRipple-root {
    color: ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.8)};
  }

  .MuiSvgIcon-root {
    color: transparent;
    border: 0.1rem solid ${({ theme }) => theme.colors.black};
    border-radius: 0.15rem;
    width: 1rem;
    height: 1rem;
  }

  &.Mui-checked .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.royalBlue};
    border: none;
    width: 100%;
    height: 100%;
  }

  &.Mui-disabled .MuiSvgIcon-root {
    border: 0.1rem solid ${({ theme }) => theme.colors.gray600};
  }

  &.Mui-disabled.Mui-checked .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.gray400};
    border: none;
  }

  &.MuiCheckbox-indeterminate .MuiSvgIcon-root {
    color: transparent;
    border: 0.1rem solid ${({ theme }) => theme.colors.black};
    border-radius: 0.15rem;
    width: 1rem;
    height: 1rem;
  }

  &.MuiCheckbox-indeterminate.Mui-checked .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.royalBlue};
    border: none;
    width: 100%;
    height: 100%;
  }

  &.MuiCheckbox-indeterminate.Mui-disabled .MuiSvgIcon-root {
    border: 0.1rem solid ${({ theme }) => theme.colors.gray600};
  }

  &.MuiCheckbox-indeterminate.Mui-disabled.Mui-checked .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.royalBlue100};
    border: none;
  }
`;

export default memo(Checkbox);
