import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const PRICE_FRAGMENT = gql`
  fragment PriceParts on Price {
    startDate
    pricePerDay
    instantBooking
  }
`;