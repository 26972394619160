import React from 'react';
import { Form, Formik, Field } from 'formik';
import { validationSchema } from './constants';
import {
  Container,
  Content,
  Title,
  RowSubtitle,
  Button,
  FormButton,
  Input,
  RowForm,
  RowFormSave,
  Separator,
  MobileActions,
  CancelButton,
} from './styles';
import useConnect from './connect';

const Edit = () => {
  const { isLoading, handleSubmit, initialValues } = useConnect();

  if (isLoading) return <>Loading...</>

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <MobileActions>
              <CancelButton type="button">Cancelar</CancelButton>
              <Button variant="primary" size="big" isCentered fullWidth>
                Guardar
              </Button>
            </MobileActions>
            <Content>
              <Title>Editar perfil</Title>
              <Form noValidate>
                <RowSubtitle>Información personal</RowSubtitle>
                <RowForm>
                  <Field
                    as={Input}
                    label="Nombre"
                    name="firstName"
                    type="text"
                  />
                  <Field
                    as={Input}
                    label="Apellido"
                    name="lastName"
                    type="text"
                  />
                </RowForm>

                <RowForm>
                  <Field
                    as={Input}
                    label="Dirección"
                    name="homeAddress"
                    type="text"
                  />
                  <Field as={Input} label="Ciudad" name="city" type="text" />
                </RowForm>
                <Separator />
                <RowSubtitle>Información adiccional</RowSubtitle>
                <RowForm>
                  <Field as={Input} label="Email" name="email" type="email" disabled />
                  <Field as={Input} label="Móvil" name="phoneNumber" />
                </RowForm>
                <Separator />

                <RowSubtitle>Información fiscal</RowSubtitle>

                <RowForm>
                  <Field as={Input} label="Nombre" name="companyName" type="text" />
                  <Field as={Input} label="CIF" name="dni" type="text" />
                </RowForm>
                <Field as={Input} label="Dirección fiscal" name="fiscalAddress" type="text"/>
                <RowFormSave>
                  <FormButton type="submit" disabled={isSubmitting} fullWidth>
                    Guardar cambios
                  </FormButton>
                </RowFormSave>
              </Form>
            </Content>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default Edit;
