import styled from 'styled-components';
import { H5, Body } from 'components/Typography';
import DefaultModal, { Footer as DefaultModalFooter } from 'components/Modal';
import DefaultInput from 'components/Input';
import DefaultButton from 'components/Button';
import DefaultCheckbox from 'components/Checkbox';
import { from } from 'styles/media';

export const Modal = styled(DefaultModal)``;

export const Container = styled.div`
  padding: 2rem 1rem 7.6875rem 1rem;

  ${from.mobile} {
    padding: 2.5rem;
  }
`;

export const Title = styled(H5)``;

export const Text = styled(Body)``;

export const Input = styled(DefaultInput)``;

export const ModalFooter = styled(DefaultModalFooter)``;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Rangue = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 3.125rem;
  max-width: 28.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Capacity = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 3.125rem;
  max-width: 13.8125rem;
  display: flex;
  align-items: center;
`;

export const Categories = styled.div`
  margin-top: 1.5rem;
  max-width: 37.8125rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Movility = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const DeleteButton = styled.button`
  outline: none;
  cursor: pointer;
  background: none;
  border: 0;
  text-align: center;
  margin: 0;
  padding: 0rem 0.85rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-decoration: underline;
  transition: all 0.2s;
`;

export const Button = styled(DefaultButton)``;

export const Checkbox = styled(DefaultCheckbox)``;
