import useBookings from 'graphql/hooks/useBookings';
import useMe from 'graphql/hooks/useMe';
const date = new Date();
let day: any;
const month = date.getMonth()+1;
const year = date.getFullYear();
let finalMonth: string;

switch (month) {
  case 1:
    finalMonth = "01"
        break;
  case 2:
    finalMonth = "02";
    break;
  case 3:
    finalMonth = "03";
    break;
  case 4:
    finalMonth = "04";
    break;
  case 5:
    finalMonth = "05";
    break;
  case 6:
    finalMonth = "06";
    break;
  case 7:
    finalMonth = "07";
    break;
  case 8:
    finalMonth = "08";
    break;
  case 9:
    finalMonth = "09";
    break;
  case 10:
    finalMonth = "10";
    break;
  case 11:
    finalMonth = "11";
    break;
  case 12:
    finalMonth = "12";
    break;

}

switch (date.getDate()) {
  case 1:
    day = "01"
    break;
  case 2:
    day = "02";
    break;
  case 3:
    day = "03";
    break;
  case 4:
    day = "04";
    break;
  case 5:
    day = "05";
    break;
  case 6:
    day = "06";
    break;
  case 7:
    day = "07";
    break;
  case 8:
    day = "08";
    break;
  case 9:
    day = "09";
    break;
  default:
    day = date.getDate()
}

const useConnect = () => {
  const { me } = useMe();
  const {
    bookings: previousBookings,
    bookingLoading: previousBookingsLoading,
  } = useBookings({
    where: { user: me?.id, startDate_lt: `${year}-${finalMonth}-${day}T00:00:00.000Z`},
  });
  const {
    bookings: nextBookings,
    bookingLoading: nextBookingsLoading,
  } = useBookings({
    sort: "startDate:desc",
    where: { user: me?.id, startDate_gte: `${year}-${finalMonth}-${day}T00:00:00.000Z`},
  });
  return {
    previousBookings,
    previousBookingsLoading,
    nextBookings,
    nextBookingsLoading,
  };
};

export default useConnect;
