import React from 'react';
import {
  Container,
  Content,
  Row,
  Title,
  Section,
  SectionTitle,
  Separator,
  Text,
  Button,
} from './styles';
import useConnect from './connect';

const Account = () => {
  const { isLoading, handleForgotPassword, info } = useConnect();
  return (
    <Container>
      <Content>
        <Row>
          <Title>Cuenta</Title>
        </Row>

        <Row>
          <SectionTitle>Actualizar contraseña</SectionTitle>
        </Row>

        <Row>
          <Text>
            Te enviaremos un email con las instrucciones para cambiarla.
          </Text>
          <Section>
            <Button
              onClick={handleForgotPassword}
              variant="primary"
              size="big"
              disabled={isLoading}
              isCentered
            >
              CAMBIAR CONTRASEÑA
            </Button>
            <Text>{info}</Text>
          </Section>
        </Row>

        <Separator />

        <Row>
          <SectionTitle>Eliminar cuenta</SectionTitle>
        </Row>

        <Row>
          <Text>
            Si eliminas tu cuenta, no podrás acceder a la información sobre tus
            reservas, mensajes, favoritos, etc.
          </Text>
          <Section>
            <Button
              disabled={isLoading}
              variant="outlined"
              size="big"
              isCentered
            >
              ELIMINAR CUENTA
            </Button>
          </Section>
        </Row>
      </Content>
    </Container>
  );
};

export default Account;
