import { Bookings_bookings } from 'graphql/generated/Bookings';
import { normalizeSpace } from 'model/Space';
import { toInterfaceDayFormat } from 'utils/formatDate';
import { normalizeFile } from './global';

type InputSpace = Bookings_bookings;

const PERCENT = 10;

export const normalizeBooking = (input: InputSpace) => {
  const initDate = new Date(input.startDate).getDate();
  const endDate  = new Date(input.endDate).getDate();
  const diff = endDate - initDate;
  return {
    id: input.id,
    startDate: input.startDate,
    endDate: input.endDate,
    total: input.total || undefined,
    state: input.state,
    isCancelled: input.isCanceled || undefined,
    cancelReason: input.cancelReason || undefined,
    created_at: input.created_at ||undefined,
    space: input.space ? normalizeSpace(input.space) : undefined,
    bookingPrice: input.total || undefined,
    finalPayment: input.total
      ? input.total - input.total * (PERCENT / 100)
      : undefined,
    percentage: PERCENT,
    totalPayment: input.totalPayment || false,
    dates: `${initDate} al ${toInterfaceDayFormat(
      input.endDate
    )}`,
    totalDays: Number((diff/(1000*60*60*24)).toFixed(0))+1,
    isTransfered: input.isTransfered || false,
    prereservaInvoice: input.prereservaInvoice
      ? normalizeFile(input.prereservaInvoice)
      : undefined,
    reservaInvoice: input.reservaInvoice
      ? normalizeFile(input.reservaInvoice)
      : undefined,
  };
};

export type Booking = ReturnType<typeof normalizeBooking>;
