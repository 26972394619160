import React, { memo, FC } from 'react';
import {
  Header,
  InfoCard,
  InfoTitle,
  InfoText,
  Link,
  Timer,
  CheckIcon,
  BoldText,
  Section
} from '../styles';
import useConnect from '../connect';
import { Props } from '../types';
import DeferPayment from '../DeferPayment';

const Reservation:FC<Props>= ({
    dateToTime
  }) => {
    const { isLoading, booking} = useConnect();
    if (isLoading || !booking) return null;
    return (
        <Section>
            <InfoCard>
                <Header>
                    <CheckIcon />
                    <InfoTitle $isBlue>Solicitud de reserva aceptada</InfoTitle>
                </Header>
                <InfoText>
                    <p>¡Hola! Hemos <BoldText>aceptado tu solicitud de reserva</BoldText> del espacio{' '}
                    <Link to={`/spaces/${booking?.space?.slug as string}`}>
                    {booking?.space?.name}
                    </Link>.</p>
                    <p>Para confirmarla, dispones de un plazo de 48 horas. Es necesario abonar el {booking.percentage}% del total.</p>
                    <p>Si lo prefieres, puedes abonar ahora todo el importe del alquiler y recibir un 5% de descuento.</p> 
                </InfoText>
                <Timer date={dateToTime} />
            </InfoCard>
            <DeferPayment/> 
        </Section>
        
    );
  };
  
  export default memo(Reservation);