import { useEffect, useState } from 'react';
import { Props, TimeArgs } from './types';
import getTimeDiferrence from './utils';

const useLogic = ({ date }: Props) => {
  const currentDate = new Date();
  const [timeArgs, setTimeArgs] = useState<TimeArgs>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeArgs = getTimeDiferrence({
        to: date,
        from: currentDate,
      });

      setTimeArgs(newTimeArgs);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [date, currentDate]);

  return {
    timeArgs,
  };
};

export default useLogic;
