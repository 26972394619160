import styled from 'styled-components';
import { NavLink as NavLinkDefault } from 'react-router-dom';
import { FiPlusCircle as DefaultPlusCircle } from 'react-icons/fi';

import { H2, H5, Overline, Small } from 'components/Typography';
import DefaultCity from 'components/Card/City';
import DefaultSpaceType from 'components/Card/SpaceType';
import DefaultSpace from 'components/Card/Space';
import DefaultHero from 'containers/Shared/Hero';
import MaxContent from 'components/MaxContent';

import { from } from 'styles/media';

export const Hero = styled(DefaultHero)``;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const RowContainer = styled.div<{ $colored?: boolean }>`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4.0625rem;
  padding: ${({ $colored }) => ($colored ? '2rem 0' : '0')};
  background-color: ${({ theme, $colored }) =>
    $colored ? theme.colors.royalBlue : theme.colors.white};
  color: ${({ theme, $colored }) =>
    $colored ? theme.colors.white : theme.colors.ebonyClay};

  :last-child {
    margin-bottom: 5rem;
  }
  ${from.mobile} {
    margin-bottom: 5rem;
    padding: ${({ $colored }) => ($colored ? '5rem 0' : null)};

    :last-child {
      margin-bottom: 6.5rem;
    }
  }
`;

export const Row = styled(MaxContent)`
  padding: 0 1rem;
`;

export const RowTextContainer = styled.div<{ $bottomAlign?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ $bottomAlign }) =>
    $bottomAlign ? 'flex-end' : 'baseline'};
  flex-wrap: wrap;
  margin-bottom: 0rem;
`;

export const RowSubtitle = styled(H5)`
  font-weight: 600;
`;

export const BigRowSubtitle = styled(H2)`
  font-size: 1.6875rem;
  ${from.tablet} {
    font-size: 2.25rem;
  }
`;

export const RowText = styled(Small)`
  margin: 1rem 0 3rem;
  font-size: 0.875rem;
`;

export const Link = styled(NavLinkDefault)`
  color: ${({ theme }) => theme.colors.ebonyClay};
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const LinkText = styled(Overline)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
`;

export const LinkIcon = styled(DefaultPlusCircle)`
  margin-right: 0.5813rem;
`;

export const BorderedLink = styled(NavLinkDefault)`
  color: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  padding: 0.5rem;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 1rem;
  ${from.tablet} {
    margin-top: 0;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 2rem;
  margin-top: 1.5rem;
  width: 100%;
  height: 100%;

  ${from.tablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;

    > :last-child {
      display: none;
    }
  }

  ${from.desktop} {
    grid-template-columns: repeat(4, 1fr);

    > :last-child {
      display: flex;
    }
  }
`;

export const CityCard = styled(DefaultCity)``;

export const SpaceTypeCard = styled(DefaultSpaceType)``;

export const SpaceCard = styled(DefaultSpace)``;
