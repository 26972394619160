import { ChangeEvent, useCallback, useState } from 'react';
import { from, useMediaQuery } from 'styles/media';
import { useHistory } from 'react-router-dom';
import { SelectType } from 'model/global';

const useConnect = () => {
  const { push } = useHistory();
  const [currentValue, setCurrentValue] = useState('/profile/edit');
  const isMobile = !useMediaQuery(from.tablet);
  const options = [
    { label: 'Editar perfil', value: '/profile/edit' },
    { label: 'Cuenta', value: '/profile/account' },
    { label: 'Notificaciones', value: '/profile/notifications' },
  ];

  const handleChange = useCallback(
    (event: ChangeEvent<SelectType>) => {
      setCurrentValue(event.target.value as string);
      push(event.target.value as string);
    },
    [setCurrentValue, push]
  );

  return {
    currentValue,
    handleChange,
    options,
    isMobile,
  };
};

export default useConnect;
