import * as yup from 'yup';

type InitialValues = {
  minPrice: number | undefined;
  maxPrice: number | undefined;
  maxCapacity: number | undefined;
  reducedMovility: boolean | undefined;
};

export const initialValues: InitialValues = {
  minPrice: undefined,
  maxPrice: undefined,
  maxCapacity: undefined,
  reducedMovility: undefined,
};

export const validationSchema = yup.object().shape({
  minPrice: yup.number(),
  maxPrice: yup.number(),
  maxCapacity: yup.number(),
});
