import styled, { css } from 'styled-components';
import MaxContent from 'components/MaxContent';
import Drop from 'containers/Shared/DropMenu';
import addAlpha from 'utils/addAlpha';
import { ContainerProps } from '../types';

export const Container = styled.div<ContainerProps>`
  position: sticky;
  top: 0;
  z-index: 3;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0rem 0.3125rem 1.25rem
    ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.2)};
  display: none;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      display: flex;
    `}
`;

export const Content = styled(MaxContent)`
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-align: right;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
`;

export const DropMenu = styled(Drop)``;
