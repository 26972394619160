import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const AVAILABILITY_SPACE_FRAGMENT = gql`
  fragment AvailabilityParts on Availability {
    available
    message
    total
    canInstantBooking
  }
`;
