import styled from 'styled-components';
import { from } from 'styles/media';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${from.mobile} {
    flex-direction: row;
  }
`;

export default Container;
