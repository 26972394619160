import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const ME_FRAGMENT = gql`
  fragment MeParts on UsersPermissionsMe {
    id
    email
    confirmed
    blocked
    role {
      id
      name
      description
      type
    }
  }
`;