import React, { FC, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Container, Fade, Button, CloseIcon, BulletButton, } from './styles';
import { Props, ButtonProps } from './types';
import useLogic from './logic';

export const CloseModalButton: FC<ButtonProps> = ({ ...props }) => {
  const { isMobile } = useLogic();

  if (isMobile) return <BulletButton icon={<CloseIcon />} {...props} />;

  return (
    <Button variant="floating" size="small" leftIcon={<CloseIcon />} {...props}>
      Cerrar
    </Button>
  );
};

const ModalScreen: FC<Props> = ({ open, handleClose, children, ...props }) => {
  return (
    <Dialog fullScreen open={open} onClose={handleClose} {...props}>
      <Container>
        <Fade in={open}>
          <>{children}</>
        </Fade>
      </Container>
    </Dialog>
  );
};

export default memo(ModalScreen);
