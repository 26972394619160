import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { normalizePrice } from 'model/Price';
import notEmpty from 'utils/notEmpty';
import {AVAILABLE_PRICES} from "../queries/availablePrices";
import {availablePrices, availablePricesVariables} from "../generated/availablePrices";

const usePrices = (variables: availablePricesVariables) => {
  const { data, loading } = useQuery<
      availablePrices, availablePricesVariables>(AVAILABLE_PRICES, {
    variables,
  });

  const prices = useMemo(
    () => (data?.availablePrices || []).filter(notEmpty).map(normalizePrice),
    [data]
  );

  return {
    prices,
    loading,
  };
};

export default usePrices;
