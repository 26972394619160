import * as yup from 'yup';

export const initialValues = {
  identifier: '',
  password: '',
};

export const validationSchema = yup.object().shape({
  identifier: yup.string().email('Tiene que ser un email').required('Email requerido'),
  password: yup.string().required('Contraseña requerida'),
});
