import useFavorites from 'graphql/hooks/useFavorites';
import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const { me } = useMe();

  const { favorites, favoriteLoading } = useFavorites({
    where: { user: me?.id },
  });

  return {
    favorites,
    favoriteLoading,
  };
};

export default useConnect;
