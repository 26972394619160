import styled from 'styled-components';
import MaxContent from 'components/MaxContent';
import { from } from 'styles/media';

/* eslint-disable import/prefer-default-export */

export const Container = styled(MaxContent)`
  margin-bottom: 6.5rem;
  padding: 0 1.2rem;

  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;

    ${from.tablet} {
      margin-top: 5rem;
    }
  }

  h4 {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
`;