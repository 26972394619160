import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { SPACE_FAVORITES } from 'graphql/queries/favorites';
import { Favorites, FavoritesVariables } from 'graphql/generated/Favorites';
import { normalizeSpace, Space } from 'model/Space';
import notEmpty from 'utils/notEmpty';

const useFavorites = (variables?: FavoritesVariables) => {
  const { loading: favoriteLoading, data } = useQuery<
    Favorites,
    FavoritesVariables
  >(SPACE_FAVORITES, {
    variables,
  });

  const favorites = useMemo(
    () =>
      (data?.favorites || [])
        .filter(notEmpty)
        .reduce<Space[]>(
          (acum, { space }) =>
            space ? acum.concat(normalizeSpace(space)) : acum,
          []
        ),
    [data]
  );

  return {
    favorites,
    favoriteLoading,
  };
};

export default useFavorites;
