import {useQuery} from '@apollo/client';
import {useMemo} from 'react';
import { User, UserVariables } from 'graphql/generated/User';
import { USER } from 'graphql/queries/user';
import { normalizeUser } from 'model/User';

const useUser = (variables: UserVariables) => {
  const { data, loading } = useQuery<User, UserVariables>(USER, { variables });

  const user = useMemo(
    () => (data?.user ? normalizeUser(data.user) : undefined),
    [data]
  );

  return {
    user,
    loading,
  };
};

export default useUser;