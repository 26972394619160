import React, { FC, memo } from 'react';
import EmptyImage from 'assets/images/Card/emptyCards.svg';
import {
  Component,
  ImageWrapper,
  Image,
  Content,
  Section,
  Text,
  Title,
} from './styles';
import { LinkOverlay } from '../styles';
import { Props } from './types';

const SpaceType: FC<Props> = ({
  linkProps,
  title,
  description,
  image,
  ...props
}) => {
  return (
    <Component {...props}>
      {linkProps && <LinkOverlay {...linkProps} />}
      <ImageWrapper>
        {image ? (
          <Image src={process.env.REACT_APP_API_URL+image.src} alt={image.alt} />
        ) : (
          <Image src={EmptyImage} alt={title} />
        )}
      </ImageWrapper>
      <Content>
        <Section>
          <Title>{title}</Title>
          <Text>{description}</Text>
        </Section>
      </Content>
    </Component>
  );
};

export default memo(SpaceType);
