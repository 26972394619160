import styled, { css } from 'styled-components';
import { Props } from './types';

const Avatar = styled.img<Props>`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  object-fit: cover;
  ${({ size }) =>
    size === 'big' &&
    css`
      height: 8.375rem;
      width: 8.375rem;
    `}
`;

export default Avatar;