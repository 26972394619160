import { ChangeEvent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useLocations from 'graphql/hooks/useLocations';
import { SelectType } from 'model/global';
import useQuery from 'utils/useQueryParams';
import {toApiDayFormat} from 'utils/formatDate';

const today = new Date();

const useConnect = () => {

  const { push } = useHistory();
  const { locations } = useLocations();
  const { query, setQuery, search } = useQuery();
  const { startDate, endDate, location } = query;
  const cityOptions = useMemo(() => {
    const locationOptions = [];

    locationOptions.push({ label: '¿Dónde?', value: 'default' });

    locations.list.map((item) => {
      locationOptions.push({
        label: item.name,
        value: item.slug,
      });

      return null;
    });

    return locationOptions;
  }, [locations]);

  const handleCityChange = useCallback(
    (event: ChangeEvent<SelectType>) => {
      try {
        const selectedCity = event.target.value as string;
        setQuery({
          location: selectedCity === 'default' ? undefined : selectedCity,
        });
      } catch {
        setQuery({ location: undefined });
      }
    },
    [setQuery]
  );

  const handleFromChange = useCallback(
    (day: Date) => {
      setQuery({ startDate: toApiDayFormat(day) });
    },
    [setQuery]
  );

  const handleToChange = useCallback(
    (day: Date) => {
      setQuery({ endDate: toApiDayFormat(day) });
    },
    [setQuery]
  );

  const goToSearch = useCallback(() => {
    push({ pathname: '/search', search });
  }, [push, search]);

  return {
    currentCity: location || 'default',
    handleCityChange,
    cityOptions,
    from: (startDate && new Date(startDate)) || today,
    to: (endDate && new Date(endDate)) || today,
    handleFromChange,
    handleToChange,
    goToSearch,
  };
};

export default useConnect;
