import React, { FC, memo } from 'react';
import useLogic from './logic';
import {
  Component,
  CarouselWrapper,
  Carousel,
  LikeButton,
  Content,
  Section,
  BottomSection,
  Text,
  Title,
  StateTag,
} from './styles';
import { LinkOverlay } from '../styles';
import { Props } from './types';

const BookedSpaceCard: FC<Props> = ({
  linkProps,
  title,
  location,
  images,
  price,
  availabilityRange,
  state,
  isPrevious,
    startDate,
  ...props
}) => {
  const { isLiked, setIsLiked } = useLogic();
  let date = new Date();
  if (startDate) {
     date = new Date(startDate);
     date.setHours(-48);
  }

  return (
    <Component {...props}>
      <CarouselWrapper>
        <Carousel images={images} />
        <LikeButton isLiked={isLiked} onClick={() => setIsLiked(!isLiked)} />
      </CarouselWrapper>
      <Content>
        {linkProps && <LinkOverlay {...linkProps} />}
        <Section>
          <Title>{title}</Title>
          <Text>{location}</Text>
        </Section>
        <BottomSection>
          <Text>{availabilityRange}</Text>
          {state && <StateTag state={state} isPrevious={isPrevious}/>}
        </BottomSection>

      </Content>
    </Component>
  );
};

export default memo(BookedSpaceCard);
