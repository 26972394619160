/* eslint-disable import/prefer-default-export */

import {gql} from "@apollo/client";
import {PRICE_FRAGMENT} from "../fragments/price";

export const AVAILABLE_PRICES = gql`
  query availablePrices($id: ID!) {
    availablePrices(id: $id) {
      ...PriceParts
    }
  }
  ${PRICE_FRAGMENT}
`;