import React, { FC, memo } from 'react';
import { NavLink, LinkLabel, CheckIcon } from './styles';
import { Props } from './types';

const Option: FC<Props> = ({
  disabled,
  icon,
  label,
  hasCheck = true,
  ...props
}) => {
  if ('to' in props) {
    return (
      <NavLink activeClassName="selected" $disabled={disabled} {...props}>
        {hasCheck && <CheckIcon />}
        {icon && <span>{icon}</span>}
        <LinkLabel>{label}</LinkLabel>
      </NavLink>
    );
  }
  return (
    <NavLink as="button" $disabled={disabled} {...props}>
      {hasCheck && <CheckIcon />}
      {icon && <span>{icon}</span>}
      <LinkLabel>{label}</LinkLabel>
    </NavLink>
  );
};

export default memo(Option);
