import styled, { css } from 'styled-components';
import { SmallSemi, Caption } from 'components/Typography';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { FiX } from 'react-icons/fi';
import { StyledProps } from './types';
import {from} from "../../styles/media";

export const Container = styled.div`
  width: 85%;
  display: flex;
  & :nth-child(2) div{
    margin-left: 83%;
  }
  ${from.tablet}{
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

export const Indicator = styled(SmallSemi)``;

export const DateIndicator = styled(Caption)`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const CheckIcon = styled(Check)`
  display: none;
  position: absolute;
  left: 15.4%;
  top: 0.75rem;
  transform: translate(-50%, -12%);
  -webkit-transform: translate(-50%, -12%);
  width: 1rem;
  height: 1rem;
  ${from.mobile}{
    left: 10.4%;
  }
  ${from.tablet}{
    left: 9.4%;
  }
  ${from.laptop}{
    left: 9%;
  }
  
`;

export const CancelledIcon = styled(FiX)`
  display: none;
  position: absolute;
  left: 9.6%;
  top: 0.75rem;
  transform: translate(-50%, -12%);
  -webkit-transform: translate(-50%, -12%);
  width: 1rem;
  height: 1rem;
`;

export const Step = styled.div<StyledProps>`
  width: 100%;
  position: relative;
  color: ${({ theme }) => theme.colors.gray300};

  ::before {
    content: ' ';
    width: 1.8125rem;
    height: 1.8125rem;
    line-height: 1rem;
    border: 0.1875rem solid ${({ theme }) => theme.colors.gray300};
    display: block;
    margin-left: 5%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
  }

  ::after {
    content: '';
    position: absolute;
    width: 190%;
    height: 0.1875rem;
    background-color: ${({ theme }) => theme.colors.gray300};
    top: 1.0625rem;
    left: -100%;
    margin-left: 5%;
    z-index: -1;
  }
  :first-child {
    ::after {
      content: none;
    }
  }
  :nth-child(2) {
    ::before {
      margin-left: 90%;
    }
  }
  ${({ $active }) =>
  $active && css`
    :nth-child(-n + ${$active}) {
      color: ${({ theme }) => theme.colors.royalBlue};
      ::after {
        background-color: ${({ theme }) => theme.colors.royalBlue};
      }
      ::before {
        border-color: ${({ theme }) => theme.colors.royalBlue};
        background-color: ${({ theme }) => theme.colors.royalBlue};
      }
    }
    :nth-child(${$active + 1}) {
      color: ${({ theme }) => theme.colors.royalBlue};
      ::before {
        border-color: ${({ theme }) => theme.colors.royalBlue};
        color: ${({ theme }) => theme.colors.royalBlue};
      }
      ::after {
        background-color: ${({ theme }) => theme.colors.royalBlue};
      }
    }
  `}

  ${({ $active, $reservation})=>
    $reservation && css`
    :nth-child(-n + ${$active}) {
      ${CheckIcon} {
        display: inherit;
        color: ${({ theme }) => theme.colors.white};
      }
      color: ${({ theme }) => theme.colors.royalBlue100};
      ::after {
        background-color: ${({ theme }) => theme.colors.royalBlue100};
      }
      ::before {
        border-color: ${({ theme }) => theme.colors.royalBlue100};
        background-color: ${({ theme }) => theme.colors.royalBlue100};
      }
    }
    :nth-child(${$active}) {
      ${CheckIcon} {
        display: inherit;
        color: ${({ theme }) => theme.colors.white};
      }
      color: ${({ theme }) => theme.colors.royalBlue};
      ::after {
        background-color: ${({ theme }) => theme.colors.royalBlue100};
      }
      ::before {
        ::before {
          margin-left: 90%;
        }
        border-color: ${({ theme }) => theme.colors.royalBlue};
        background-color: ${({ theme }) => theme.colors.royalBlue};
      }
    }`
  }
  ${({ $active, $completed }) =>
    $completed &&
    css`
      :nth-child(-n + ${$active}) {
        color: ${({ theme }) => theme.colors.royalBlue200};
        ${CheckIcon} {
          display: inherit;
          color: ${({ theme }) => theme.colors.white};
        }
        ::before {
          border-color: ${({ theme }) => theme.colors.royalBlue200};
          background-color: ${({ theme }) => theme.colors.royalBlue200};
        }
        ::after {
          background-color: ${({ theme }) => theme.colors.royalBlue200};
        }
      }
      :nth-child(${$active + 1}) {
        ${CheckIcon} {
          display: inherit;
          color: ${({ theme }) => theme.colors.white};
        }
        color: ${({ theme }) => theme.colors.royalBlue};
        ::before {
          border-color: ${({ theme }) => theme.colors.royalBlue};
          background-color: ${({ theme }) => theme.colors.royalBlue};
          color: ${({ theme }) => theme.colors.royalBlue};
        }
        ::after {
          background-color: ${({ theme }) => theme.colors.royalBlue200};
        }
      }
    `}
    ${({ $active, $pending }) =>
    $pending &&
    css`
      :nth-child(${$active + 2}) {
        ::after {
          background-color: ${({ theme }) => theme.colors.royalBlue};
        }
      }
      :nth-child(${$active + 1}) {
        color: ${({ theme }) => theme.colors.royalBlue};
        ::before {
          border-color: ${({ theme }) => theme.colors.royalBlue};
          background-color: ${({ theme }) => theme.colors.royalBlue};
          color: ${({ theme }) => theme.colors.royalBlue};
        }
        ::after {
          background-color: ${({ theme }) => theme.colors.royalBlue};
        }
      }
    `}
    ${({ $active, $cancelled }) =>
    $cancelled &&
    css`
    :nth-child(${$active}) {
      color: ${({ theme }) => theme.colors.outrageousOrange};
      ${CancelledIcon} {
        display: inherit;
        color: ${({ theme }) => theme.colors.white};
      }
      ::before {
        border-color: ${({ theme }) => theme.colors.outrageousOrange};
        background-color: ${({ theme }) => theme.colors.outrageousOrange};
      }
      ::after {
        background-color: ${({ theme }) => theme.colors.royalBlue200};
      }
    }
    :nth-child(${$active+1}) {
      color: ${({ theme }) => theme.colors.gray300};
      ::before {
        border-color: ${({ theme }) => theme.colors.gray300};
        background-color: ${({ theme }) => theme.colors.white};
      }
      ::after {
        background-color: ${({ theme }) => theme.colors.gray300};
      }
    }
    :nth-child(${$active-1}) {
      ${CheckIcon} {
        display: inherit;
        color: ${({ theme }) => theme.colors.white};
      }
      color: ${({ theme }) => theme.colors.royalBlue200};
      ::before {
        border-color: ${({ theme }) => theme.colors.royalBlue200};
        background-color: ${({ theme }) => theme.colors.royalBlue200};
      }
      ::after {
        background-color: ${({ theme }) => theme.colors.royalBlue200};
      }
    }
      
    `}
`;
