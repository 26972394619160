import styled from 'styled-components';
import { ReactComponent as DefaultLogo } from 'assets/brand/logo.svg';
import DefaultAvatar from 'components/Avatar';
import { FiAlignJustify } from 'react-icons/fi';
import DefaultLoginModal from 'containers/Shared/Modals/LoginModal';
import DefaultRegisterModal from 'containers/Shared/Modals/RegisterModal';
import DefaultForgotPassword from 'containers/Shared/Modals/ForgotPassword';

export const Logo = styled(DefaultLogo)``;

export const Clickable = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const Box = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 3rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.royalBlue};
  box-sizing: border-box;
  border-radius: 0.3125rem;
`;

export const Icon = styled(FiAlignJustify)`
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.colors.royalBlue};
`;

export const Avatar = styled(DefaultAvatar)``;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginModal = styled(DefaultLoginModal)``;
export const RegisterModal = styled(DefaultRegisterModal)``;
export const ForgotPassword = styled(DefaultForgotPassword)``;
