import React, { FC } from 'react';
import { Form, Formik, Field } from 'formik';
import { validationSchema } from './constants';
import useConnect from './connect';
import {
  Container,
  Content,
  Title,
  FormButton,
  Input,
  RowForm,
  Text,
} from './styles';

const ResetPassword: FC = () => {
  const { isLoading, handleSubmit, initialValues } = useConnect();

  return (
    <Container>
      <Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Title>Actualizar contraseña</Title>
              <Text>Introduce un nueva contraseña:</Text>

              <RowForm>
                <Field
                  as={Input}
                  label="Nueva contraseña *"
                  name="password"
                  type="password"
                />
              </RowForm>

              <RowForm>
                <Field
                  as={Input}
                  label="Repita la contraseña *"
                  name="passwordConfirmation"
                  type="password"
                />
              </RowForm>

              <RowForm>
                <FormButton
                  type="submit"
                  disabled={isSubmitting || isLoading}
                  fullWidth
                >
                  Actualizar contraseña
                </FormButton>
              </RowForm>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default ResetPassword;
