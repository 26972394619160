import React, { FC, memo } from 'react';
import { useField, ErrorMessage } from 'formik';
import {
  Container,
  Component,
  Label,
  InputContainer,
  HelperText,
  Error,
} from './styles';
import { Props } from './types';

const TextArea: FC<Props> = ({
  className,
  label,
  helper,
  value,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props);
  const hasError = !!(meta.touched && meta.error);

  return (
    <Container className={className}>
      {label && (
        <Label as="label" $disabled={disabled} htmlFor={field.name}>
          {label}
        </Label>
      )}

      <InputContainer $error={hasError}>
        <Component {...field} {...props} id={field.name} disabled={disabled}>
          {value}
        </Component>
      </InputContainer>
      <ErrorMessage component={Error} name={field.name} />
      {!hasError && <HelperText $disabled={disabled}>{helper}</HelperText>}
    </Container>
  );
};

export default memo(TextArea);
