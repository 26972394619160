import React, { FC } from 'react';
import useLogic from './logic';
import { Props } from './types';
import { Container, Section, Time, Unit, Divider } from './styles';

const Timer: FC<Props> = ({ date, ...props }) => {
  const { timeArgs } = useLogic({ date });

  return (
    <Container {...props}>
      <Section>
        <Time>{timeArgs.hours}</Time> <Unit>horas</Unit>
      </Section>

      <Divider />

      <Section>
        <Time>{timeArgs.minutes}</Time> <Unit>minutos</Unit>
      </Section>

      <Divider />

      <Section>
        <Time>{timeArgs.seconds}</Time> <Unit>segundos</Unit>
      </Section>
    </Container>
  );
};

export default Timer;
