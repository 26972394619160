import React from 'react';

const Help = () => {
  return (
    <div>
      <h1>Help</h1>
    </div>
  );
};

export default Help;
