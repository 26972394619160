import styled, { css } from 'styled-components';
import DefaultDayPickerInput from 'react-day-picker/DayPickerInput';
import DefaultCheckbox from 'components/Checkbox';
import {
  SmallSemi,
  PSemi,
  Caption as HelperFont,
  Body,
  VerySmall,
} from 'components/Typography';
import { FiChevronLeft, FiChevronRight, FiZap } from 'react-icons/fi';
import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  width: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  ${({ $isColumn }) =>
    $isColumn &&
    css`
      grid-template-columns: 1fr;
    `}
`;

export const DateText = styled(Body)``;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const FlashIcon = styled(FiZap)`
  width: 10px;
  height: 10px;
  stroke: ${({ theme }) => theme.colors.gray500};
`;

export const PriceText = styled(VerySmall)`
  color: ${({ theme }) => theme.colors.gray500};
`;

export const Label = styled(SmallSemi)`
  color: ${({ theme }) => theme.colors.gray700};
  margin-bottom: 0.25rem;
`;

export const DayPickerStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  &&& {
    .DayPicker,
    .DayPicker-wrapper {
      color: ${({ theme }) => theme.colors.black};
      :focus {
        outline: none;
      }
    }
    .DayPicker-wrapper {
      padding: 1.4063rem 1rem;
    }
    .DayPicker-Weekday {
      color: ${({ theme }) => theme.colors.black};
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.4rem;
    }
    .DayPicker-Body {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.gray800};
      font-size: 1rem;
      line-height: 1.4rem;
      font-weight: 400;
    }
    .DayPicker-Caption {
      display: none;
    }
    .DayPicker-Month {
      margin: 0;
    }
    .DayPicker-Day--today {
      color: ${({ theme }) => theme.colors.royalBlue};
    }
    .DayPicker-Day:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
      :hover {
        background-color: ${({ theme }) => theme.colors.royalBlue};
        color: ${({ theme }) => theme.colors.white};
        ${PriceText} {
          color: ${({ theme }) => theme.colors.white};
        }
        ${FlashIcon} {
          stroke: ${({ theme }) => theme.colors.white};
        }
      }
    }
    .DayPicker-Day--disabled {
      pointer-events: none;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
      background-color: ${({ theme }) => theme.colors.gray200};
      color: ${({ theme }) => theme.colors.gray800};
    }
    .DayPicker-Day--selected {
      border-radius: 0;
    }
    .DayPicker-Day--end:not(.DayPicker-Day--outside),
    .DayPicker-Day--start:not(.DayPicker-Day--outside) {
      background-color: ${({ theme }) => theme.colors.royalBlue};
      color: ${({ theme }) => theme.colors.white};
      ${PriceText} {
        color: ${({ theme }) => theme.colors.white};
      }
      ${FlashIcon} {
        stroke: ${({ theme }) => theme.colors.white};
      }
      font-weight: 500;
    }
    .DayPicker-Day--start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    .DayPicker-Day--end {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    input {
      width: 100%;
      outline: none;
      border: 0.125rem solid ${({ theme }) => theme.colors.white};
      border-radius: 0.3125rem;
      height: 3rem;
      padding: 0.9063rem 1.5rem;
      background-color: ${({ theme }) => theme.colors.gray200};
      font-size: 0.875rem;
      line-height: 140%;
      border: none;
      margin: 0;
      color: ${({ theme }) => theme.colors.gray700};
      transition: 0.3s;
      :focus-within {
        border: 0.125rem solid ${({ theme }) => theme.colors.royalBlue};
      }
      :hover {
        background-color: ${({ theme }) => theme.colors.gray300};
        cursor: pointer;
      }
      :disabled {
        color: ${({ theme }) => theme.colors.gray400};
      }
    }
  }
`;

export const DayPickerInput = styled(DefaultDayPickerInput)``;

export const Nav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Clickeable = styled.button`
  outline: none;
  border: none;
  background: none;
  :hover {
    cursor: pointer;
  }
`;

export const LeftIcon = styled(FiChevronLeft)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.colors.ebonyClay};
`;

export const RightIcon = styled(FiChevronRight)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.colors.ebonyClay};
`;

export const Helper = styled.div`
  display: flex;
  align-items: center;
`;

export const Caption = styled(PSemi)``;

export const HelperText = styled(HelperFont)`
  color: ${({ theme }) => theme.colors.gray700};
`;

export const Checkbox = styled(DefaultCheckbox)``;

export const Day = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
`;
