import React from 'react';
import useConnect from './connect';
import Cancelled from './Cancelled';
import Completed from './Completed';
import PreReservation from './Pre-reservation';
import Reservation from './Reservation';
import ReservationPreCompleted from './ReservationPreCompleted';
import ReservationCompleted from './ReservationCompleted';
import {
    Container,
    Stepper,
    Title,
    ButtonBack,
    Header,
    Content,
    Section,
    Card, BothContainers, Button, ArrowIcon,
} from './styles';
import Payment from './Payment';
import {BookingButtonWrapper} from "../../Open/Spaces/styles";
import PayButton from "../../../components/PayButton";
const Booking = () => {
  const { steps, isLoading, booking, handleGoBack, states, isCompleted, date, handleBookingAgain } =
    useConnect();
    const cancelReason = `Lo sentimos pero tu solicitud de reserva para el espacio ${booking?.space?.name} no pudo ser aceptada, 
              inténtalo de nuevo en otra fecha disponible y te confirmaremos lo antes posible.`;
              
  if (isLoading || !booking) return null;

  if (isCompleted) return (
    <Container>
      <Completed />
    </Container>
 
  );

  if (booking?.state === states.reserva_solicitada)
    return (
        <BothContainers>
            <Container>
                <Header>
                    <ButtonBack type="button" onClick={handleGoBack} />
                    <Title>
                        {booking.isCancelled
                            ? `Reserva cancelada nº${booking.id}`
                            : 'Solicitud de Reserva'}
                    </Title>
                </Header>
                <Content>
                    <Section>
                        <Stepper
                            steps={steps}
                            activeStep={1}
                            cancelled={booking.isCancelled}
                        />
                        {booking.isCancelled ? (
                            <Cancelled preReservation reason={cancelReason}/>
                        ) : (
                            <PreReservation
                                dateToTime={date}/>
                        )}
                    </Section>
                    {booking.space && booking.space.location ?  booking.isCancelled ? (
                        <Card
                            id={booking.id}
                            spaceName={booking.space.name}
                            location={booking.space.location.name}
                            images={booking.space.images}
                            dates={booking.dates}
                            capacity={booking.space.capacity}
                            totalToPay={booking.total}
                            bookingPrice={booking.bookingPrice}
                            percentage={booking.percentage}
                            allowCancel={!booking.isCancelled}
                        />
                    ) : (
                        <Card
                            id={booking.id}
                            spaceName={booking.space.name}
                            location={booking.space.location.name}
                            images={booking.space.images}
                            dates={booking.dates}
                            capacity={booking.space.capacity}
                            totalToPay={booking.total}
                            percentage={booking.percentage}
                            allowCancel={!booking.isCancelled}
                        />
                    ) : (
                        <Section>

                        </Section>
                    )
                    }
                </Content>
            </Container>

        </BothContainers>
    );

  if (booking?.state === states.pre_reserva_aprobada)
    return (
      <Container>
        <Header>
          <ButtonBack type="button" onClick={handleGoBack} />
          <Title>
            {booking.isCancelled ? `Reserva cancelada nº`  : 'Pre-reserva nº'}{' '}
            {booking.id}
          </Title>
        </Header>
        <Content>
            {booking.isCancelled ? (
              <Section>
                  <Stepper
                  steps={steps}
                  activeStep={1}
                  cancelled={booking.isCancelled}
                />
                <Cancelled reason={cancelReason} />
              </Section>
            ) : (
              <Section>
                <Stepper
                  steps={steps}
                  activeStep={1}
                  cancelled={booking.isCancelled}
                  reservation
                />
                  <Reservation
                  dateToTime={date}/>
              </Section>
            )}

          {booking.space && booking.space.location ?  booking.isCancelled ? (
                <Card
                id={booking.id}
                spaceName={booking.space.name}
                location={booking.space.location.name}
                images={booking.space.images}
                dates={booking.dates}
                capacity={booking.space.capacity}
                totalToPay={booking.total}
                bookingPrice={booking.bookingPrice}
                percentage={booking.percentage}
              />
              ) : (
                <Card
                id={booking.id}
                spaceName={booking.space.name}
                location={booking.space.location.name}
                images={booking.space.images}
                dates={booking.dates}
                capacity={booking.space.capacity}
                totalToPay={booking.total}
                bookingPrice={booking.bookingPrice}
                percentage={booking.percentage}
                allowCancel={!booking.isCancelled}
                Reservation
                seeFinalPayment
                payButtons
                payEverything
                cancelOptions
              />
              ) : (
                <Section>
                  
                </Section>
              )
            }
        </Content>
      </Container>
    );

  if (
    booking?.state === states.reserva_confirmada
  )
    return (
        <BothContainers>
            <Container>
                <Header>
                    <ButtonBack type="button" onClick={handleGoBack} />
                    <Title>
                        {booking.isCancelled ? 'Reserva cancelada' : 'Reserva nº'}{' '}
                        {booking.id}
                    </Title>
                </Header>
                <Content>
                    {booking.isCancelled ? (
                        <Section>
                            <Stepper
                                steps={steps}
                                activeStep={1}
                                cancelled={booking.isCancelled}
                            />
                            <Cancelled reason={cancelReason} />
                        </Section>
                    ) : (
                        <Section>
                            <Stepper
                                steps={steps}
                                activeStep={1}
                                cancelled={booking.isCancelled}
                                reservation
                            />
                            <ReservationPreCompleted/>
                        </Section>
                    )}

                    {booking.space && booking.space.location ?  booking.isCancelled ? (
                        <Card
                            id={booking.id}
                            spaceName={booking.space.name}
                            location={booking.space.location.name}
                            images={booking.space.images}
                            dates={booking.dates}
                            capacity={booking.space.capacity}
                            totalToPay={booking.total}
                            bookingPrice={booking.bookingPrice}
                            percentage={booking.percentage}
                            allowCancel={!booking.isCancelled}
                            daysCount={booking.totalDays}
                            cancellationInsurance={false}
                            pricePerDay={booking.bookingPrice}
                        />
                    ) : (
                        <Card
                            id={booking.id}
                            spaceName={booking.space.name}
                            location={booking.space.location.name}
                            images={booking.space.images}
                            dates={booking.dates}
                            capacity={booking.space.capacity}
                            totalToPay={booking.total}
                            bookingPrice={booking.bookingPrice}
                            percentage={booking.percentage}
                            allowCancel={!booking.isCancelled}
                            cancellationInsurance={false}
                            daysCount={booking.totalDays}
                            pricePerDay={booking.bookingPrice}
                            Reservation
                            payButtons
                            cancelOptions
                        />
                    ) : (
                        <Section>

                        </Section>
                    )
                    }
                </Content>
            </Container>
            <BookingButtonWrapper>
                <PayButton isTransfered={booking.isTransfered} id={booking.id}/>
            </BookingButtonWrapper>
        </BothContainers>

    );
    
    if(booking?.state === states.reserva_en_proceso){
        return (
            <>
            {booking.isCancelled ?
                (
                <Container>
                    <Header>
                        <ButtonBack type="button" onClick={handleGoBack} />
                        <Title>
                            Reserva cancelada nº{booking.id}
                        </Title>
                    </Header>
                    <Content>
                        <Section>
                            <Stepper
                                steps={steps}
                                activeStep={1}
                                cancelled={booking.isCancelled}
                            />
                            <Cancelled preReservation reason={cancelReason}/>
                        </Section>
                        <Section>
                            {booking.space && booking.space.location &&  booking.isCancelled && (
                                <Card
                                    id={booking.id}
                                    spaceName={booking.space.name}
                                    location={booking.space.location.name}
                                    images={booking.space.images}
                                    dates={booking.dates}
                                    capacity={booking.space.capacity}
                                    totalToPay={booking.total}
                                    cancellationInsurance={false}
                                    bookingPrice={booking.bookingPrice}
                                    percentage={booking.percentage}
                                    daysCount={booking.totalDays}
                                    Reservation={!booking.totalPayment}
                                />
                                )
                            }
                        </Section>
                    </Content>
                </Container>
                ) : (
                <Payment/>
                )}
            </>
        );
    }
    if(booking?.state === states.reserva_pago_enviado)
    return (
        <>
      <Container>
        <Header>
          <ButtonBack type="button" onClick={handleGoBack} />
          <Title>
            {booking.isCancelled ? 'Reserva cancelada' : 'Reserva aceptada nº'}{' '}
            {booking.id}
          </Title>
        </Header>
        <Content>
            {booking.isCancelled ? (
              <Section>
                <Stepper
                  steps={steps}
                  activeStep={2}
                  cancelled={booking.isCancelled}
                />
                <Cancelled reason={cancelReason} />
              </Section>
            ) : (
              <Section>
                <Stepper
                  steps={steps}
                  activeStep={2}
                  cancelled={booking.isCancelled}
                  reservation
                />
                <ReservationCompleted/>
              </Section>
            )}
            {booking.space && booking.space.location ?  booking.isCancelled ? (
                <Card
                id={booking.id}
                spaceName={booking.space.name}
                location={booking.space.location.name}
                images={booking.space.images}
                dates={booking.dates}
                capacity={booking.space.capacity}
                totalToPay={booking.total}
                bookingPrice={booking.bookingPrice}
                percentage={booking.percentage}
                cancellationInsurance={false}

                Reservation={!booking.totalPayment}
              />
              ) : (
                <Card
                id={booking.id}
                spaceName={booking.space.name}
                location={booking.space.location.name}
                images={booking.space.images}
                dates={booking.dates}
                capacity={booking.space.capacity}
                totalToPay={booking.total}
                bookingPrice={booking.bookingPrice}
                percentage={booking.percentage}
                allowCancel={!booking.isCancelled}
                cancellationInsurance={false}

                Reservation={!booking.totalPayment}
              />
              ) : (
                <Section>
                  
                </Section>
              )
            }
        </Content>
      </Container>
        <BookingButtonWrapper>
            <Button
                type="button"
                onClick={handleBookingAgain}
                rightIcon={<ArrowIcon />}
                fullWidth
            >
                Volver a reservar
            </Button>
        </BookingButtonWrapper>
    </>
    );
  return null;
};
export default Booking;
