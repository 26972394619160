import { LOCATIONS } from 'graphql/queries/locations';
import { normalizeCustomLocations } from 'model/Location';
import { Locations, LocationsVariables } from 'graphql/generated/Locations';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

const useLocations = (variables?: LocationsVariables) => {
  const { loading, data } = useQuery<Locations, LocationsVariables>(LOCATIONS, {
    variables: { ...variables },
  });

  const locations = useMemo(
    () => normalizeCustomLocations(data?.customLocations),
    [data]
  );

  return {
    locations,
    loading,
  };
};

export default useLocations;
