import React, { FC, useState } from 'react';
import ReactMapGL from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Props, Viewport } from './types';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map: FC<Props> = ({
  latitude,
  longitude,
  zoom = 16,
  children,
  ...props
}) => {
  const [viewport, setViewport] = useState<Viewport>({
    latitude,
    longitude,
    width: '100%',
    height: '100%',
    zoom,
  });
  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="100%"
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      onViewportChange={(newViewport: Viewport) => {
        setViewport(newViewport);
      }}
      {...props}
    >
      {children}
    </ReactMapGL>
  );
};

export default Map;
