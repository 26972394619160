import { Me_me, Me_me_role } from 'graphql/generated/Me';

type InputUser = Me_me;
type InputRole = Me_me_role;

const normalizeRole = (input: InputRole) => {
  return {
    id: input.id,
    name: input.name,
    description: input.description || undefined,
    type: input.type || undefined,
  };
};

export const normalizeMe = (input: InputUser) => {
  return {
    id: input.id,
    email: input.email,
    confirmed: input.confirmed || undefined,
    blocked: input.blocked || false,
    role: input.role ? normalizeRole(input.role) : undefined,
  };
};

export type Me = ReturnType<typeof normalizeMe>;
