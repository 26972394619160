import styled from 'styled-components';
import { from } from 'styles/media';

export const H1 = styled.h1`
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.7rem;

  ${from.tablet} {
    font-size: 3rem;
    line-height: 3.875rem;
  }
`;

export const H2 = styled.h2`
  font-weight: 600;
  font-size: 1.6875rem;
  line-height: 2.5313rem;

  ${from.tablet} {
    font-size: 2.25rem;
    line-height: 3.125rem;
  }
`;

export const H3 = styled.h3`
  font-weight: 600;
  font-size: 1.3125rem;
  line-height: 1.8375rem;

  ${from.tablet} {
    font-size: 1.75rem;
    line-height: 2.4375rem;
  }
`;

export const H4 = styled.h4`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;

  ${from.mobile} {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`;

export const H5 = styled.h5`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;

  ${from.mobile} {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
`;

export const H6 = styled.h6`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
`;

export const Subtitle = styled.p`
  font-weight: 600;
  font-size: 1.0625rem;
  line-height: 1.4875rem;

  ${from.mobile} {
    font-size: 1.375rem;
    line-height: 1.9375rem;
  }
`;

export const PBig = styled.p`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2rem;
`;

export const PBigSemi = styled(PBig)`
  font-weight: 600;
`;

export const P = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
`;

export const PSemi = styled(P)`
  font-weight: 600;
`;

export const Body = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
`;

export const BodyMedium = styled(Body)`
  font-weight: 500;
`;

export const Small = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

export const SmallSemi = styled(Small)`
  font-weight: 500;
`;

export const Caption = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.0625rem;
`;

export const CaptionSemi = styled(Caption)`
  font-weight: 600;
`;

export const Overline = styled.label`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const Button = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3125rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const LinkText = styled.span`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
`;

export const LinkTextSmall = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
`;

export const VerySmall = styled.span`
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1rem;
`;