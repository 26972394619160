import React, { FC, useCallback, memo } from 'react';
import { useField, useFormikContext, ErrorMessage } from 'formik';
import {
  Container,
  Component,
  Label,
  IconError,
  IconClean,
  InputContainer,
  Button,
  Error,
} from './styles';
import { Props } from './types';

const Input: FC<Props> = ({ className, label, value, disabled, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = !!(meta.touched && meta.error);
  const { setFieldValue } = useFormikContext();

  const handleClean = useCallback(() => {
    setFieldValue(field.name, '');
  }, [setFieldValue, field.name]);

  return (
    <Container className={className}>
      {label && (
        <Label as="label" $disabled={disabled} htmlFor={field.name}>
          {label}
        </Label>
      )}

      <InputContainer $error={hasError}>
        <Component {...field} {...props} id={field.name} disabled={disabled} />
        {hasError && <IconError />}
        {!hasError && field.value !== '' && (
          <Button onClick={handleClean} type="button" $disabled={disabled}>
            <IconClean />
          </Button>
        )}
      </InputContainer>
      <ErrorMessage component={Error} name={field.name} />
    </Container>
  );
};

export default memo(Input);
