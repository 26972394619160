import React, { FC, memo } from 'react';
import { Component, Icon } from './styles';
import { Props } from './types';

const BulletButton: FC<Props> = ({ icon, onClick, ...props }) => {
  return (
    <Component type="button" onClick={onClick} {...props}>
      <Icon>{icon}</Icon>
    </Component>
  );
};

export default memo(BulletButton);
