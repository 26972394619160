import styled from 'styled-components';
import { from } from 'styles/media';

const MaxContent = styled.div<{ $wide?: boolean }>`
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme, $wide }) =>
    $wide ? theme.maxWidth.wide : theme.maxWidth.normal};

  ${from.tablet} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  ${from.largeScreen} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export default MaxContent;
