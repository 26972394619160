import React, { memo,} from 'react';

import {
  Header,
  InfoCard,
  InfoTitle,
  InfoText,
  Button,
  Invoices,
  PaperPlaneIcon,
  Section,
  Content,
  ArrowIcon
} from '../styles';
import useConnect from '../connect';
import {ButtonSection} from "../../../../components/Card/BookingDetails/styles";

const ReservationCompleted = () => {
    const { isLoading, booking, handleBookingAgain} = useConnect();
    if (isLoading || !booking) return null;
    return (
        <Section>
            <InfoCard>
                <Header>
                <PaperPlaneIcon />
                <InfoTitle $isBlue>¡A celebrar!</InfoTitle>
                </Header>
                <InfoText>
                    ¡Hola! Acabas de realizar el pago del 10% del total.
                    En breve nos pondremos en contacto contigo para completar el pago del espacio.
                </InfoText>
                <InfoText>
                    Contacta con nosotros si tienes alguna consulta
                </InfoText>
                <Invoices
                preReserva={booking.prereservaInvoice?.url}
                reserva={booking.reservaInvoice?.url}
                />
            </InfoCard>
            <Content>
            <ButtonSection>
                <Button
                    type="button"
                    onClick={handleBookingAgain}
                    rightIcon={<ArrowIcon />}
                    fullWidth
                >
                    Volver a reservar
                </Button>
            </ButtonSection>
            </Content>
        </Section>
    );
  };

export default memo(ReservationCompleted);
            