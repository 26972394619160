import styled, { css } from 'styled-components';
import DefaultMaxContent from 'components/MaxContent';
import {ReactComponent as messageCircle} from 'assets/icons/message-circle-outline.svg';
import {ReactComponent as paperPlane} from 'assets/icons/paper-plane.svg';
import { H4, H5, Caption, Small, P } from 'components/Typography';
import DefaultButtonBack from 'components/ButtonBack';
import DefaultButton from 'components/Button';
import { ReactComponent as Check } from 'assets/icons/circle-check.svg';
import { ReactComponent as Slash } from 'assets/icons/circle-slash.svg';
import { ReactComponent as Mid } from 'assets/icons/circle-mid.svg';
import { from } from 'styles/media';


export const Container = styled(DefaultMaxContent)`
  padding: 2rem;

`;
export const InfoText = styled(P)`
  margin-top: 1rem;
  padding: 0.25rem 1rem;
  :first-child {
    margin-top: 0;
  }
`;
export const InfoTitle = styled(H5)<{ $isBlue?: boolean }>`
  color: ${({ theme }) => theme.colors.ebonyClay};
  ${({ $isBlue }) =>
    $isBlue &&
    css`
      color: ${({ theme }) => theme.colors.royalBlue};
    `}
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 1.75rem;
`;

export const InfoContainer = styled.div`
  padding: 0.75rem 0;
`;

export const Content = styled.div`
  max-width: 100%;
  padding-bottom: 2rem;
`;

export const MessageCircle = styled(messageCircle)`
  width: 1.5rem;
  height: 1.5rem;
  padding-top: 4px;
  margin-top: 4px;
  path {
    fill: ${({ theme }) => theme.colors.royalBlue};
  }
`;


export const ContentCancel = styled.div`
margin-top: 2.5rem;
margin-bottom: 1.75rem;
max-width: 55%;
`;

export const PaperPlane = styled(paperPlane)`
  width: 1.5rem;
  height: 1.5rem;
  padding-top: 4px;
  margin-top: 4px;
  path {
    fill: ${({ theme }) => theme.colors.royalBlue};
  }
`;

export const Button = styled(DefaultButton)`
  vertical-align: middle;
  width: 25%;
  border-radius: 0.3125rem;
  margin-bottom: 0.4rem;
  padding-bottom: 8px;
`;

export const Title = styled(H4)``;

export const ButtonBack = styled(DefaultButtonBack)`
  margin-right: 1.5rem;
`;

export const SectionQuestion = styled.div`
  margin-top: 2.375rem;
  width: 100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionBottom = styled.div`
  margin-top: 2.375rem;
  width: 100%;
  display:flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Section = styled.div`
  width: 100%;
  margin-top: 2.375rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 2rem;

  ${from.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }

  ${from.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const CardTitle = styled(H4)`
  margin-top: 0.375rem;
`;

export const CardCaption = styled(Caption)`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const CardText = styled(Small)`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const CheckIcon = styled(Check)``;

export const SlashIcon = styled(Slash)``;

export const MinusIcon = styled(Mid)``;
