import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const SPACE_FRAGMENT = gql`
  fragment SpaceParts on Space {
    id
    slug
    name
    DescriptionExtended
    extraInformation
    capacity
    numberOfRooms
    parkingSpaces
    openSpace
    style
    music
    airConditioner
    cateringExclusivity
    audiovisualMedia
    festivals
    meetings
    wardrobe
    instantBooking
    web
    googleMapsUrl
    lat
    long
    address
    tourVirtual
    location {
      id
      slug
      name
    }
    town
    space_categories {
      id
      slug
      name
    }
    images {
      url
      name
    }
  }
`;

export const CUSTOM_SPACE_FRAGMENT = gql`
  fragment CustomSpaceParts on customSpace {
    id
    slug
    canInstantBooking
    name
    DescriptionExtended
    extraInformation
    capacity
    numberOfRooms
    parkingSpaces
    openSpace
    style
    music
    airConditioner
    cateringExclusivity
    audiovisualMedia
    festivals
    meetings
    wardrobe
    instantBooking
    web
    address
    price
    googleMapsUrl
    lat
    long
    tourVirtual
    location {
      id
      slug
      name
    }
    town
    space_categories {
      id
      slug
      name
    }
    images {
      url
      name
    }
  }
`;
