import { SpaceCategories_spaceCategories } from 'graphql/generated/SpaceCategories';
import { normalizeImage } from './global';

type InputCategory = SpaceCategories_spaceCategories;

export const normalizeSpaceCategory = (input: InputCategory) => {
  return {
    id: input.id,
    slug: input.slug,
    name: input.name,
    description: input.description || '',
    image: input.image ? normalizeImage(input.image) : undefined,
  };
};

export type Location = ReturnType<typeof normalizeSpaceCategory>;
