import styled, { css } from 'styled-components';
import { ReactComponent as Heart } from 'assets/icons/heart.svg';
import { HeartIconProps } from './types';

export const Component = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export const HeartIcon = styled(Heart)<HeartIconProps>`
  fill-opacity: 1;
  transition: all 0.3s;
  :hover {
    transform: scale(1.1);
  }
  ${({ $variant }) => {
    switch ($variant) {
      case 'black':
        return css`
          color: transparent;
          path {
            stroke: ${({ theme }) => theme.colors.ebonyClay};
          }
        `;
      case 'white':
      default:
        return css`
          color: ${({ theme }) => theme.colors.gray500};
          fill-opacity: 0.25;
          path {
            stroke: ${({ theme }) => theme.colors.white};
          }
        `;
    }
  }}
  ${({ $isLiked }) =>
    $isLiked &&
    css`
      color: ${({ theme }) => theme.colors.outrageousOrange};
      fill-opacity: 1;
    `}
`;
