import styled, { css } from 'styled-components';
import { NavLink as NavLinkDefault } from 'react-router-dom';
import { Small } from 'components/Typography';
import { FiCheck as Icon } from 'react-icons/fi';
import { StylesProps } from './types';

export const CheckIcon = styled(Icon)`
  opacity: 0;
  margin-right: 0.5rem;
`;

export const NavLink = styled(NavLinkDefault)<StylesProps>`
  height: 2rem;
  padding: 0.375rem 1.25rem;
  text-decoration: none;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;

  span {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
  span svg:last-child {
    color: ${({ theme }) => theme.colors.royalBlue};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.royalBlue};
    color: ${({ theme }) => theme.colors.white};
    span svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  &.selected {
    font-weight: 500;
    opacity: 100%;
    ${CheckIcon} {
      opacity: 1;
    }
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray500};
      pointer-events: none;
      span svg:last-child {
        color: ${({ theme }) => theme.colors.gray500};
      }
    `}
`;

export const LinkLabel = styled(Small)``;
