import React, { memo} from 'react';
import {
    InfoTitle,
    InfoText,
    DoubleTitle,
    Content,
    Section,
} from '../styles';
import useConnect from '../connect';
import MoreInfo from "../../../Shared/Modals/MoreInfo/MoreInfo";

const DeferPayment = () => {
    const { isLoading, booking} = useConnect();
    if (isLoading || !booking) return null;
    return (
        <Section>
            <Content>
                <DoubleTitle>
                <InfoTitle>¿Prefieres aplazar el pago?</InfoTitle>
                <InfoTitle>Aplázame</InfoTitle>
                </DoubleTitle>
            </Content>
            <Content>
                    <MoreInfo/>
            </Content>
            <Content>
                <Section>
                    <InfoTitle>Seguro de cancelación</InfoTitle>
                    <InfoText>Contrata nuestro seguro por si necesitas cancelar tu evento en cualquier momento.Para
                        más información, ponte en contacto con el equipo de Sedee.
                    </InfoText>
                </Section>
            </Content>
        </Section>
        
    );
  };
  
  export default memo(DeferPayment);
