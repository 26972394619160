import React from 'react';

const SendSpace = () => {
  return (
    <div>
      <iframe
        id="typeform-full"
        width="100%"
        height="800px"
        title="Enviar espacio"
        frameBorder="0"
        allow="camera; microphone; autoplay; encrypted-media;"
        src="https://form.typeform.com/to/LTlyhPQV?typeform-medium=embed-snippet"
       />
      <script
        type="text/javascript"
        src="https://embed.typeform.com/embed.js"
       />
    </div>
  );
};

export default SendSpace;
