import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';
import DefaultCarousel from 'components/Carousel';
import { BodyMedium, Small } from 'components/Typography';
import DefaultStateTag from 'components/StateTag';
import DefaultBulletButton from 'components/BulletButton';
import DefaultLikeButton from 'components/LikeButton';
import DefaultTimer from "../../Timer";

export const Section = styled.div``;

export const CarouselWrapper = styled.div`
  position: relative;
  border-radius: 0.3125rem 0.3125rem 0 0;
  overflow: hidden;
`;

export const Carousel = styled(DefaultCarousel)``;

export const Timer = styled(DefaultTimer)`
    padding: 1rem;
    height: auto;
    width: auto;
`;

export const LikeButton = styled(DefaultLikeButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const PrevButton = styled(DefaultBulletButton)`
  position: absolute;
  top: 45%;
  left: 1rem;
  opacity: 0;
`;

export const NextButton = styled(DefaultBulletButton)`
  position: absolute;
  top: 45%;
  right: 1rem;
  opacity: 0;
`;

export const Content = styled.div`
  position: relative;
  padding: 1rem;
  border-radius: 0 0 0.3125rem 0.3125rem;
  min-height: 7.875rem;
`;

export const Title = styled(BodyMedium)`
  font-size: 1rem;
`;

export const Text = styled(Small)`
  display: block;
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 0.875rem;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1.5rem;
`;

export const StateTag = styled(DefaultStateTag)``;

export const Component = styled.div`
  text-decoration: none;
  border-radius: 0.3125rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.ebonyClay};
  box-shadow: 0rem 0.3125rem 1.25rem rgba(33, 35, 48, 0.05);
  transition: 0.3s;
  display: block;
  :hover {
    box-shadow: 0rem 0.3125rem 1.25rem
      ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.2)};
    ${PrevButton}, ${NextButton} {
      opacity: 1;
    }
  }
`;
