import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const BOOKING_FRAGMENT = gql`
  fragment BookingParts on Booking {
    id
    startDate
    endDate
    total
    state
    isCanceled
    cancelReason
    created_at
    totalPayment
    isTransfered
    prereservaInvoice {
      url
      name
    }
    reservaInvoice {
      url
      name
    }
  }
`;
