import { InfoText } from 'containers/Private/Booking/styles';
import React from 'react';
import useConnect from './connect';
import {
  Container,
  Header,
  Title,
  ButtonBack,
  Content,
  Li
} from './styles';
import {Ol} from "../ CancellationPolicies/styles";
import {Strong} from "../../Shared/Modals/TransferModal/styles";

const CancellationOptions = () => {
  const { handleGoBack } = useConnect();

  return (
    <Container>
      <Header>
        <ButtonBack onClick={handleGoBack} />
        <Title>Formas de pago</Title>
      </Header>

      <Content>
        <Title>
          ¿Cuáles son los métodos de pago disponibles?
        </Title>
        <InfoText>
          En Sedee nos esforzamos por brindarle opciones de pago seguras y convenientes. Para garantizar su tranquilidad, ofrecemos dos métodos de pago confiables:
        </InfoText>
        <Ol>
          <Li>
            <Strong>Pago con Tarjeta Bancaria:</Strong> Aceptamos una amplia gama de tarjetas, incluyendo VISA, VISA Electron, MasterCard, American Express y más. Con esta opción, puede completar sus reservas de forma rápida y sencilla, aprovechando la comodidad que ofrecen las tarjetas de crédito o débito.
          </Li>
          <Li>
            <Strong>Transferencia Bancaria:</Strong> Si prefiere realizar una transferencia bancaria, también puede optar por esta alternativa. Le proporcionaremos todos los detalles necesarios para que puedas efectuar la transferencia de manera segura.
          </Li>
        </Ol>
        <InfoText>
          En Sedee, su seguridad es nuestra prioridad. Para proteger sus datos personales durante el proceso de pago, utilizamos un sistema de encriptación SSL (Secure Sockets Layer). Esto garantiza que toda la información que compartes con nosotros esté protegida y se mantenga confidencial.
        </InfoText>
        <InfoText>
          Gracias por confiar en nosotros para tus reservas. Si tiene alguna pregunta adicional sobre nuestros métodos de pago o necesitas asistencia, no dudes en ponerte en contacto con nuestro equipo de atención al cliente. Estaremos encantados de ayudarte en todo lo que necesites.
        </InfoText>
      </Content>
      <Content>
        <Title>
          ¿Puedo usar varios métodos de pago en la misma reserva?
        </Title>
        <InfoText>
          Por supuesto, en Sedee entendemos que la flexibilidad es clave cuando se trata de satisfacer las necesidades de nuestros clientes. Si desea utilizar varios métodos de pago para una misma reserva, estamos dispuestos a facilitarle esa opción.
        </InfoText>
        <InfoText>
          Es importante tener en cuenta que la viabilidad de esta opción dependerá del tiempo en que esté disponible el dinero en cada método de pago seleccionado. En el caso de las transferencias bancarias, los plazos comienzan a contar a partir del recibo del importe en nuestra cuenta. Por lo tanto, le recomendamos que coordine y gestione los diferentes métodos de pago con suficiente antelación para garantizar una experiencia de reserva sin complicaciones.
        </InfoText>
        <InfoText>
          En Sedee, estamos comprometidos a brindarte un servicio eficiente y adaptado a tus necesidades. Si tienes alguna pregunta específica sobre cómo utilizar varios métodos de pago para tu reserva o necesitas asistencia adicional, no dude en ponerse en contacto con nuestro equipo de atención al cliente. Estaremos encantados de ayudarte a lograr una reserva exitosa y sin inconvenientes.
        </InfoText>
      </Content>
      <Content>
        <Title>
          ¿Puedo modificar una reserva una vez confirmada?
        </Title>
        <InfoText>
          En Sedee, entendemos que pueden surgir cambios inesperados en tus planes. Por lo tanto, te ofrecemos la flexibilidad de modificar tu reserva una vez que haya sido confirmada.
        </InfoText>
        <InfoText>
          Sin embargo, es importante tener en cuenta que si has entregado un depósito como parte de tu reserva, cualquier modificación estará sujeta a revisión por parte de nuestro equipo de Sedee. Para garantizar una atención personalizada y eficiente, te invitamos a ponerte en contacto con nuestro personal a través de teléfono o correo electrónico. Estaremos encantados de ayudarte a gestionar cualquier cambio en tu reserva y encontrar la mejor solución para ti.
        </InfoText>
        <InfoText>
          En Sedee, nuestra prioridad es brindarte un servicio de alta calidad y adaptado a tus necesidades cambiantes. No dudes en comunicarte con nosotros para cualquier pregunta o solicitud de modificación de reserva. Estamos aquí para atenderte y asegurarnos de que tu experiencia con nosotros sea excepcional.
        </InfoText>
      </Content>
      <Content>
        <Title>
          ¿Cómo puedo obtener mi factura?
        </Title>
        <InfoText>
          Para obtener tu factura, simplemente debes enviar una solicitud por correo electrónico a la siguiente dirección: info@sedee.io. Estaremos encantados de proporcionarte la factura que necesitas de manera oportuna y eficiente.
        </InfoText>
      </Content>
    </Container>
  );
};

export default CancellationOptions;
