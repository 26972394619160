import { useCallback, useState } from "react";

const useConnect = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, [setOpenMenu]);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, [setOpenMenu]);

  return {
    openMenu,
    handleOpenMenu,
    handleCloseMenu,
  };
}

export default useConnect;