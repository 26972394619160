import styled from 'styled-components';
import DefaultContentLoader from 'react-content-loader';
import { ComponentProps } from './types';

export const Card = styled.div`
  width: 18.375rem;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TextContainer = styled.span`
  width: 100%;
  height: 100%;
`;

export const ContentLoader = styled(DefaultContentLoader)<ComponentProps>`
  width: 100%;
  opacity: ${(props) => props.$opacity};
`;
