import styled, { css } from 'styled-components';
import { Button } from 'components/Typography';
import { ComponentProps } from './types';

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.6rem;
  border-radius: 0.22rem 0rem 0rem 0.22rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const TextWrapper = styled(Button)`
  margin-left: 2rem;
`;

export const Component = styled.button<ComponentProps>`
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 2.9375rem;
  transition: all 0.2s;
  ${({ theme, $variant }) => {
    switch ($variant) {
      case 'outlined':
        return css`
          background-color: transparent;
          color: ${theme.colors.royalBlue};
          box-shadow: 0rem 0rem 0rem 0.0625rem ${theme.colors.royalBlue};
          ${TextWrapper} {
            margin-left: 1.5rem;
          }
          :hover:not(:disabled) {
            background-color: ${theme.colors.royalBlue100};
          }
          :active:not(:disabled) {
            background-color: ${theme.colors.royalBlue200};
            color: ${theme.colors.royalBlue600};
            box-shadow: 0rem 0rem 0rem 0.125rem ${theme.colors.royalBlue};
          }
          :disabled {
            color: ${theme.colors.gray400};
            box-shadow: 0rem 0rem 0rem 0.1125rem ${theme.colors.gray400};
            background-color: transparent;
            ${Icon} path {
              fill: ${theme.colors.gray400};
            }
          }
          ${Icon} {
            background-color: transparent;
          }
        `;
      case 'primary':
      default:
        return css`
          background-color: ${theme.colors.royalBlue};
          color: ${theme.colors.white};
          border: 0.18rem solid transparent;
          :hover:not(:disabled) {
            background-color: ${theme.colors.royalBlue300};
          }
          :active:not(:disabled) {
            background-color: ${theme.colors.royalBlue300};
            border: 0.18rem solid ${theme.colors.royalBlue};
          }
          :disabled {
            background-color: ${theme.colors.gray300};
            color: ${theme.colors.gray500};
            ${Icon} {
              background-color: transparent;
            }
            ${Icon} path {
              fill: ${theme.colors.gray500};
            }
          }
        `;
    }
  }}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
      ${TextWrapper} {
        margin: 0 auto;
      }
    `}
`;
