import styled, { css } from 'styled-components';
import { Button } from 'components/Typography';
import { ComponentProps } from './types';

export const Component = styled.button<ComponentProps>`
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0;
  padding: 0rem 0.85rem;
  border: 0.0625rem solid;
  border-radius: 0.2rem;

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: 2.375rem;
        `;
      case 'big':
      default:
        return css`
          height: 3rem;
        `;
    }
  }}

  transition: all 0.2s;

  ${({ theme, $variant }) => {
    switch ($variant) {
      case 'floating':
        return css`
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.royalBlue};

          color: ${theme.colors.royalBlue};
          path {
            fill: ${theme.colors.royalBlue};
          }

          :hover {
            background-color: ${theme.colors.royalBlue100};
            border-color: ${theme.colors.royalBlue};
          }

          :active,
          :focus {
            background-color: ${theme.colors.royalBlue200};
            border-color: ${theme.colors.royalBlue600};
            box-shadow: 0 0 0 0.0625rem ${theme.colors.royalBlue600};
          }

          :disabled {
            border-color: ${theme.colors.gray400};
            color: ${theme.colors.gray400};
          }
        `;
      case 'outlined':
        return css`
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.royalBlue};

          color: ${theme.colors.royalBlue};
          path {
            fill: ${theme.colors.royalBlue};
          }

          :hover {
            background-color: ${theme.colors.royalBlue100};
            border-color: ${theme.colors.royalBlue};
          }

          :active,
          :focus {
            background-color: ${theme.colors.royalBlue200};
            border-color: ${theme.colors.royalBlue600};
            box-shadow: 0 0 0 0.0625rem ${theme.colors.royalBlue600};
          }

          :disabled {
            border-color: ${theme.colors.gray400};
            color: ${theme.colors.gray400};
          }
        `;
      case 'hidden':
        return css`
          background-color: none;
          border-color: none;

          color: none;
          position:absolute;
          margin-right: 999999%;
        `
      case 'secondary':
        return css`
          background-color: ${theme.colors.hawkesBlue};
          border-color: ${theme.colors.hawkesBlue};

          color: ${theme.colors.royalBlue};
          path {
            fill: ${theme.colors.royalBlue};
          }

          :hover {
            background-color: ${theme.colors.hawkesBlue400};
            border-color: ${theme.colors.hawkesBlue400};
          }

          :active,
          :focus {
            background-color: ${theme.colors.hawkesBlue600};
            border-color: ${theme.colors.royalBlue600};
            box-shadow: 0 0 0 0.0625rem ${theme.colors.royalBlue600};
          }

          :disabled {
            background-color: ${theme.colors.gray200};
            border-color: ${theme.colors.gray200};
            color: ${theme.colors.gray400};
          }
        `;
      case 'primary':
      default:
        return css`
          background-color: ${theme.colors.royalBlue};
          border-color: ${theme.colors.royalBlue};

          color: ${theme.colors.white};
          path {
            fill: ${theme.colors.white};
          }

          :hover {
            background-color: ${theme.colors.royalBlue300};
            border-color: ${theme.colors.royalBlue300};
          }

          :active,
          :focus {
            background-color: ${theme.colors.royalBlue300};
            border-color: ${theme.colors.royalBlue600};
            box-shadow: 0 0 0 0.0625rem ${theme.colors.royalBlue600};
          }

          :disabled {
            background-color: ${theme.colors.gray300};
            border-color: ${theme.colors.gray300};
            color: ${theme.colors.gray500};
          }
        `;
    }
  }}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
  
    ${({ $isCentered }) =>
    $isCentered &&
    css`
      justify-content: center;
    `}
`;

export const Icon = styled.span`
  padding-top: 0.3rem;
  font-size: 1.3rem;

  &:not(:only-child) {
    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }
`;

export const TextWrapper = styled(Button)`
  margin: 0;
`;
