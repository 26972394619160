import React, { FC, memo } from 'react';
import { Props } from './types';
import { Container, Text, Icon } from './styles';

const Invoices: FC<Props> = ({ preReserva, reserva }) => {
  return (
    <>
      {preReserva && (
        <Container href={preReserva} target="_blank">
          <Icon /> <Text>Factura reserva</Text>
        </Container>
      )}

      {reserva && (
        <Container href={reserva} target="_blank">
          <Icon /> <Text>Factura reserva</Text>
        </Container>
      )}
    </>
  );
};

export default memo(Invoices);
