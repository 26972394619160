import styled from 'styled-components';
import { Drawer } from '@material-ui/core';
import Option from 'components/Option';
import DefaultButton from 'components/Button';
import addAlpha from 'utils/addAlpha';
import { Logo as DefaultLogo } from '../../styles';

export const Container = styled(Drawer)`
  box-shadow: 0rem 0.5rem 2rem 0rem
    ${({ theme }) => addAlpha(theme.colors.ebonyClay, 0.05)};
  .MuiDrawer-paper {
    width: 20rem;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0rem;
  }
`;

export const Logo = styled(DefaultLogo)`
  margin: 2.1563rem 0rem 4rem 1rem;
`;

export const Button = styled(DefaultButton)`
  margin: 3rem 1rem;
  margin-bottom: 0;
`;

export const Fragment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Link = styled(Option)`
  height: 3rem;
  padding: 1rem 1.625rem;

  span {
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
  }
`;

export const Divider = styled.span`
  display: block;
  width: 100%;
  height: 0.0625rem;
  background-color: ${({ theme }) => theme.colors.gray400};
  margin: 1rem 0rem;
`;
