import React, { FC, memo } from 'react';
import ModalScreen, { CloseModalButton } from 'components/ModalScreen';
import { Frame } from './styles';
import { Props } from './types';

const VirtualTour: FC<Props> = ({
  virtualTour,
  open,
  handleClose,
  children,
  ...props
}) => {
  return (
    <ModalScreen open={open} handleClose={handleClose} {...props}>
      <CloseModalButton onClick={handleClose} />
      <Frame src={virtualTour} />
    </ModalScreen>
  );
};

export default memo(VirtualTour);
