import React, { FC, memo } from 'react';
import { Props } from './types';
import { Container, Caption } from './styles';

const Tag: FC<Props> = ({ type = 'outlined', children, ...props }) => {
  return (
    <Container $type={type} {...props}>
      <Caption>{children}</Caption>
    </Container>
  );
};

export default memo(Tag);
