import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const LOCATION_FRAGMENT = gql`
  fragment LocationParts on CustomLocation {
    id
    slug
    name
    description
    minPrice
    spacesCount
    image {
      url
      name
    }
  }
`;
