import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Topbar from 'containers/Shared/Topbar';
import Menu from 'containers/Private/Profile/Menu';
import Edit from 'containers/Private/Profile/Edit';
import Account from 'containers/Private/Profile/Account';
// import Notifications from 'containers/Private/Profile/Notifications';

import Container from './styles';

const Profile = () => {
  return (
    <>
      <Topbar isAuth />
      <Container>
        <Menu />
        <>
          <Switch>
            <Route component={Edit} exact path="/profile/edit" />
            <Route component={Account} exact path="/profile/account" />
            {/* <Route
              component={Notifications}
              exact
              path="/profile/notifications"
            /> */}
            <Redirect to="/profile/edit" />
          </Switch>
        </>
      </Container>
    </>
  );
};

export default Profile;
