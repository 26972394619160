import React, { memo, FC } from 'react';
import {
  Header,
  InfoCard,
  InfoTitle,
  PaperPlaneIcon,
  InfoText,
  Link,
  Timer
} from '../styles';
import useConnect from '../connect';
import { Props } from '../types';

const PreReservation:FC<Props>= ({
    dateToTime
  }) => {
    const { isLoading, booking} = useConnect();
    if (isLoading || !booking) return null;
    return (
        <InfoCard>
        <Header>
          <PaperPlaneIcon />
          <InfoTitle $isBlue>¡Listo! Ya tienes tu solicitud de reserva.</InfoTitle>
        </Header>
        <InfoText>
        Acabamos de recibir tu solicitud de reserva para celebrar tu evento en :{' '}
          <Link to={`/spaces/${booking?.space?.slug as string}`}>
            {booking?.space?.name}
          </Link>.
        </InfoText>
        <InfoText>
        Nuestro equipo te la confirmará antes de:
        </InfoText>
        <Timer date={dateToTime} />
      </InfoCard>
    );
  };
  
  export default memo(PreReservation);