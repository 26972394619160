import React, {FC, memo, useCallback} from 'react';
import { Price } from 'model/Price';
import {
  Container,
  DayPickerInput,
  DayPickerStyles,
  Label,
  LeftIcon,
  RightIcon,
  Nav,
  Caption,
  Clickeable,
  Checkbox,
  HelperText,
  Helper,
  Day,
  DateText,
  PriceText,
  Content,
  FlashIcon,
} from './styles';
import {
  today,
  formatDate,
  parseDate,
  WEEKDAYS,
  MONTHS,
  parseCurrentDate,
} from './utils';
import { Props, NavbarProps } from './types';
import useConnect from './connect';
import 'react-day-picker/lib/style.css';

const Navbar: FC<NavbarProps> = ({ onPreviousClick, onNextClick, date }) => {
  const currentDate = parseCurrentDate(date);

  return (
    <Nav>
      <Clickeable onClick={() => onPreviousClick()}>
        <LeftIcon />
      </Clickeable>

      <Caption>{currentDate}</Caption>

      <Clickeable onClick={() => onNextClick()}>
        <RightIcon />
      </Clickeable>
    </Nav>
  );
};
const renderDay = (date: Date, prices?: Price[]) => {
  const current = prices?.find(
    (item) =>
      new Date(item.date).getDate() === date.getDate() &&
      new Date(item.date).getMonth() === date.getMonth() &&
      new Date(item.date).getFullYear() === date.getFullYear()
  );
  return (
    <Day>
      <DateText
        style={(current && current?.price !== undefined) || !prices ? {} : { textDecoration: 'line-through' }}
      >
        {date.getDate()}
      </DateText>
      <Content>
        {current && current.instantBooking && <FlashIcon />}
        <PriceText>{current && current.price !== undefined ? `${current.price}€` : ''}</PriceText>
      </Content>
    </Day>
  );
};

const DateRangePicker: FC<Props> = ({
  isColumn = false,
  fromHandleChange,
  toHandleChange,
  from,
  to,
  labelLeft,
  labelRight,
  helperLeft,
  helperRight,
  checkLeftHandleChange,
  checkRightHandleChange,
  checkLeftState,
  checkRightState,
  prices,
  ...props
}) => {

  const { nextInput, setNextInput } = useConnect();
  const modifiers = { start: from, end: to };
  
  const handleOnFromDayChange = useCallback(
    (day: Date) => {
      if (!fromHandleChange || !toHandleChange) {
        return;
      }

      if (day > to) {
        fromHandleChange(to);
        toHandleChange(day);
      }

      fromHandleChange(day);
    },
    [fromHandleChange, toHandleChange, to]
  );

  const handleOnToDayChange = useCallback(
    (day: Date) => {
      if (!fromHandleChange || !toHandleChange) {
        return;
      }

      if (from > day) {
        fromHandleChange(day);
        toHandleChange(day);
      }

      toHandleChange(day);
    },
    [fromHandleChange, toHandleChange, from]
  );

  return (
    <Container {...props} $isColumn={isColumn}>
      <DayPickerStyles>
        {labelLeft && <Label>{labelLeft}</Label>}
        <DayPickerInput
          {...props}
          value={from}
          placeholder="Fecha"
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={handleOnFromDayChange}
          dayPickerProps={{
            renderDay: (date: Date) => renderDay(date, prices),
            selectedDays: [from, { from, to }],
            disabledDays: [{ before: today }],
            modifiers,
            numberOfMonths: 1,
            onDayClick: () => nextInput && nextInput.getInput().focus(),
            firstDayOfWeek: 1,
            months: MONTHS,
            weekdaysShort: WEEKDAYS,
            showOutsideDays: true,
            fixedWeeks: true,
            navbarElement: ({ month, onPreviousClick, onNextClick }) => (
              <Navbar
                date={month}
                onPreviousClick={onPreviousClick}
                onNextClick={onNextClick}
              />
            ),
          }}
        />
        {helperLeft && (
          <Helper>
            <Checkbox
              checked={checkLeftState}
              onChange={checkLeftHandleChange}
            />
            <HelperText>{helperLeft}</HelperText>
          </Helper>
        )}
      </DayPickerStyles>
      <DayPickerStyles>
        {labelRight && <Label>{labelRight}</Label>}
        <DayPickerInput
          {...props}
          ref={(element) => setNextInput(element)}
          value={to}
          placeholder="Fecha"
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={handleOnToDayChange}
          dayPickerProps={{
            renderDay: (date: Date) => renderDay(date, prices),
            selectedDays: [from, { from, to }],
            disabledDays: [{ before: today }],
            modifiers,
            month: from,
            fromMonth: from,
            numberOfMonths: 1,
            firstDayOfWeek: 1,
            months: MONTHS,
            weekdaysShort: WEEKDAYS,
            showOutsideDays: true,
            fixedWeeks: true,
            navbarElement: ({ month, onPreviousClick, onNextClick }) => (
              <Navbar
                date={month}
                onPreviousClick={onPreviousClick}
                onNextClick={onNextClick}
              />
            ),
          }}
        />
        {helperRight && (
          <Helper>
            <Checkbox
              checked={checkRightState}
              onChange={checkRightHandleChange}
            />
            <HelperText>{helperRight}</HelperText>
          </Helper>
        )}
      </DayPickerStyles>
    </Container>
  );
};

export default memo(DateRangePicker);
