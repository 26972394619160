import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const SPACE_CATEGORY_FRAGMENT = gql`
  fragment SpaceCategoryParts on SpaceCategory {
    id
    slug
    name
    description
    image {
      url
      name
    }
  }
`;
