import React, { FC } from 'react';
import {Formik, Field, ErrorMessage} from 'formik';
import {
  Container,
  Body,
  Form,
  Input,
  Button,
  LoginButton,
  Text,
  Bottom,
  LegalText,
  Flex, Checkbox, RegisteredButton,
} from './styles';
import { Props } from './types';
import useConnect from './connect';
import {Error} from "../../../../components/Input/styles";
import {ModalSection} from "../../../Private/Booking/styles";
import {CloseIcon, Header, ModalContent} from "../../../../components/Modal/styles";
import Dialog from "@material-ui/core/Dialog";
import {Section, SectionText, SectionTitle} from "../TransferModal/styles";
const Login: FC<Props> = ({ handleClose, goToLogin, open, ...props }) => {
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    registerLoading,
      registered,
    closeRegistered
  } = useConnect({ handleClose });

  if (registerLoading) return <>Loading...</>;

  return (
      <>
        <Container
            title="Registro"
            open={open}
            handleClose={handleClose}
            {...props}
        >
          <Body>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
              <Form noValidate>
                <Field type="email" name="email" as={Input} label="Email" />
                <Field
                    type="password"
                    name="password"
                    as={Input}
                    label="Contraseña"
                />
                <Field
                    type="text"
                    name="telephone"
                    as={Input}
                    label="Teléfono"
                />
                <Flex>
                  <Field type={'checkbox'} name="check" as={Checkbox}/>
                  <LegalText>
                    Aceptas nuestros Términos y Condiciones y nuestra Política de
                    Privacidad y Cookies
                  </LegalText>
                </Flex>
                <ErrorMessage component={Error} name={'check'} />

                <Button variant="primary" fullWidth type="submit">
                  Continuar
                </Button>
              </Form>
            </Formik>

            <Bottom>
              <Text>¿Ya tienes cuenta?</Text>{' '}
              <LoginButton onClick={goToLogin}>Inicia Sesión</LoginButton>
            </Bottom>
          </Body>
        </Container>
        {registered && (
            <ModalSection>
              <ModalContent className="modal-ui">
                <Dialog
                    open={registered}
                    onClose={closeRegistered}
                >
                  <Header>
                    <RegisteredButton type="button" onClick={closeRegistered}>
                      <CloseIcon />
                    </RegisteredButton>
                  </Header>
                  <Section>
                    <SectionTitle>
                      Registro a Sedee
                    </SectionTitle>
                    <SectionText>
                      ¡Enhorabuena! Te has registrado a Sedee, esperamos que disfrutes con nosotros
                    </SectionText>
                  </Section>
                </Dialog>
              </ModalContent>
            </ModalSection>
        )

        }
      </>

  );
};

export default Login;
