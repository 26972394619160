import { memo } from 'react';
import DefaultRadio from '@material-ui/core/Radio';
import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';

const RadioSelector = styled(DefaultRadio)`
  &.MuiRadio-root {
    width: 2.5rem;
    height: 2.5rem;
  }
  &.MuiRadio-colorSecondary:hover {
    background-color: ${({ theme }) => theme.colors.gray300};
  }
  &.MuiRadio-colorSecondary:focus {
    background-color: ${({ theme }) => theme.colors.gray600};
  }
  &.MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.1)};
  }
  &.MuiRadio-colorSecondary.Mui-checked:focus {
    background-color: ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.2)};
  }
  &.MuiRadio-root.Mui-checked .MuiTouchRipple-root {
    color: ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.8)};
  }
  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.black};
  }
  &.Mui-checked .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.royalBlue};
  }
  &.Mui-disabled .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.gray400};
  }
  &.Mui-disabled.Mui-checked .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

export default memo(RadioSelector);
