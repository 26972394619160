import styled from 'styled-components';
import DefaultSelect from '@material-ui/core/Select';
import DefaultMenuItem from '@material-ui/core/MenuItem';
import { FiChevronDown, FiCheck } from 'react-icons/fi';
import { SmallSemi, Small } from 'components/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SelectContainer = styled.div`
  display: inline-flex;
  position: relative;
`;

export const CheckIcon = styled(FiCheck)`
  opacity: 0;
  margin-right: 0.5rem;
`;

export const ItemLabel = styled(Small)`
  color: ${({ theme }) => theme.colors.gray700};
`;

export const MenuItem = styled(DefaultMenuItem)`
  &.MuiMenuItem-root {
    height: 2rem;
    padding: 0.375rem 1.25rem;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    outline: none;
    border: none;
    cursor: pointer;
    width: 100%;

    span {
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
    span svg:last-child {
      color: ${({ theme }) => theme.colors.royalBlue};
    }
  }

  &.Mui-selected {
    ${CheckIcon} {
      opacity: 1;
    }
  }

  &.MuiListItem-root.Mui-selected {
    background-color: transparent;
    font-weight: 500;
  }

  &.MuiListItem-root:hover,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: ${({ theme }) => theme.colors.royalBlue};
    color: ${({ theme }) => theme.colors.white};
    span svg {
      color: ${({ theme }) => theme.colors.white};
    }

    ${ItemLabel} {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Icon = styled(FiChevronDown)`
  width: 1rem;
  height: 1rem;
`;

export const Component = styled(DefaultSelect)`
  &.MuiInputBase-root {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray700};
    outline: none;
    font-size: 0.875rem;
    line-height: 1.25rem;
    appearance: none;
    color: ${({ theme }) => theme.colors.ebonyClay};
    background-color: ${({ theme }) => theme.colors.gray200};
    border: 0.125rem solid ${({ theme }) => theme.colors.white};
    border-radius: 0.3125rem;
    height: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    transition: 0.3s;
    &:focus-within {
      border: 0.125rem solid ${({ theme }) => theme.colors.royalBlue};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray300};
    }
  }

  .MuiSelect-selectMenu {
    height: 3rem;
    display: flex;
    align-items: center;
  }

  .MuiSelect-select.MuiSelect-select {
    padding: 0;
    &:focus,
    &:active {
      background: inherit;
    }
  }

  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    content: none;
  }

  .MuiSelect-icon {
    position: absolute;
    pointer-events: none;
    color: ${({ theme }) => theme.colors.gray700};
    top: 1rem;
    right: 0.75rem;
  }

  .MuiSelect-select.Mui-disabled {
    color: ${({ theme }) => theme.colors.gray400};
    cursor: default;
    ${Icon} {
      color: ${({ theme }) => theme.colors.gray400};
    }
  }

  .MuiPopover-paper {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0rem 0.3125rem 1.25rem rgba(51, 46, 191, 0.2);
    border-radius: 0.3125rem;
    height: auto;
    margin-top: 0;
    padding: 0.75rem 0px;
  }
`;

export const Label = styled(SmallSemi)`
  color: ${({ theme }) => theme.colors.gray700};
  margin-bottom: 0.25rem;
`;
