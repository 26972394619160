import React from 'react';
import { Formik, Form, Field } from 'formik';

import Modal, { Footer } from 'components/Modal';
import Button from 'components/Button';
import {
  Container,
  Checkbox,
  Flex,
  Label,
  CancelButton,
  TextArea,
} from './styles';
import useConnect from './connect';
import { Props } from './types';

const CancelButtonModal = ({ id }: Props) => {
  const {
    handleSubmit,
    initialValues,
    validationSchema,
    isOpen,
    openModal,
    closeModal,
  } = useConnect({ id });

  return (
    <>
      <CancelButton onClick={openModal}>Cancelar Solicitud</CancelButton>
      <Modal title="Cancelar" open={isOpen} handleClose={closeModal}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate style={{ height: '100%' }}>
              <Container>
                <Flex>
                  <Field
                    name="message"
                    as={TextArea}
                    label="Motivo de la cancelación"
                  />
                </Flex>
                <Flex>
                  <Checkbox
                    id="accept"
                    checked={values.done}
                    onChange={() => setFieldValue('done', !values.done)}
                  />
                  <Label htmlFor="accept">
                    Estoy seguro que quiero cancelar la solicitud de información.
                  </Label>
                </Flex>
              </Container>

              <Footer>
                <Button
                  type="submit"
                  size="small"
                  variant="primary"
                  disabled={!values.done}
                >
                  Aceptar
                </Button>
              </Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CancelButtonModal;
