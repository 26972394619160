import React, { FC } from 'react';
import { Form, Formik, Field } from 'formik';
import Selector from 'components/Selector';
import {
  Modal,
  Container,
  Title,
  Text,
  Button,
  DeleteButton,
  Footer,
  Input,
  Rangue,
  Capacity,
  Categories,
  ModalFooter,
} from './styles';
import { Props } from './types';
import { validationSchema } from './constants';
import useConnect from './connect';

const FilterModal: FC<Props> = ({ open, handleClose, ...props }) => {
  const {
    loading,
    spaceCategories,
    initialValues,
    handleSubmit,
    handleRemoveValues,
    categorySelected,
    handleSelectCategory,
    isMobile,
  } = useConnect({
    handleClose,
  });

  if (loading) return null;

  return (
    <Modal
      title="Más filtros"
      open={open}
      handleClose={handleClose}
      headerElement={
        isMobile && (
          <DeleteButton type="button" onClick={handleRemoveValues}>
            Restablecer
          </DeleteButton>
        )
      }
      {...props}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Form noValidate>
          <Container>
            <Title>Rango de precio</Title>
            <Text>El precio medio por día es de 102€</Text>
            <Rangue>
              <Field
                as={Input}
                label="Precio mínimo"
                name="minPrice"
                type="number"
              />
              <Field
                as={Input}
                label="Precio máximo"
                name="maxPrice"
                type="number"
              />
            </Rangue>

            <Title>Aforo del espacio</Title>
            <Capacity>
              <Field
                as={Input}
                label="Aforo mínimo"
                name="maxCapacity"
                type="number"
              />
            </Capacity>

            <Title>Tipo de espacio</Title>
            <Categories>
              {spaceCategories.map((item) => (
                <Selector
                  $checked={categorySelected.includes(item.slug)}
                  type="button"
                  key={item.id}
                  value={item.slug}
                  onClick={handleSelectCategory}
                >
                  {item.name}
                </Selector>
              ))}
            </Categories>
          </Container>

          <ModalFooter>
            <Footer>
              {!isMobile && (
                <DeleteButton type="button" onClick={handleRemoveValues}>
                  Borrar filtros
                </DeleteButton>
              )}
              <Button
                type="submit"
                size="small"
                variant="primary"
                fullWidth={isMobile}
              >
                {isMobile ? 'Actualizar' : 'Actualizar búsqueda'}
              </Button>
            </Footer>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};

export default FilterModal;
