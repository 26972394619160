import styled, { css } from 'styled-components';
import { Caption as DefaultCaption } from 'components/Typography';
import { ComponentProps } from './types';

export const Container = styled.span<ComponentProps>`
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.5rem;
  margin-right: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.ebonyClay};
  border-radius: 0.3125rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.ebonyClay};
  :last-child {
    margin-right: 0;
  }
  ${({ $type }) => {
    switch ($type) {
      case 'outlined':
        return css`
          color: ${({ theme }) => theme.colors.royalBlue};
          border-color: ${({ theme }) => theme.colors.royalBlue};
          background-color: ${({ theme }) => theme.colors.white};
        `;
      case 'colored':
        return css`
          color: ${({ theme }) => theme.colors.royalBlue};
          border-color: ${({ theme }) => theme.colors.royalBlue};
          background-color: ${({ theme }) => theme.colors.hawkesBlue};
        `;
      case 'solid':
        return css`
          color: ${({ theme }) => theme.colors.white};
          border-color: ${({ theme }) => theme.colors.royalBlue};
          background-color: ${({ theme }) => theme.colors.royalBlue};
        `;
      default:
      case 'disabled':
        return css`
          border-color: ${({ theme }) => theme.colors.gray600};
          color: ${({ theme }) => theme.colors.gray600};
          background-color: ${({ theme }) => theme.colors.white};
        `;
    }
  }}
`;

export const Caption = styled(DefaultCaption)``;
