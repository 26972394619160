import useBookingActions from 'graphql/hooks/useBookingActions';
import { useCallback } from 'react';

type Args = {
  id: string;
};

const useConnect = ({ id }: Args) => {
  const { bookingConfirmed, data} = useBookingActions();
  const initialValues = {
    id,
  };
  type FormValues = typeof initialValues;
    const handleBookingConfirmed = useCallback(
        async (values: FormValues) => {
            try {
                await bookingConfirmed({
                    id: values.id
                });
            } catch (error) {
                console.log(error);
            }
        },
        [bookingConfirmed]
    );
  return {
    initialValues,
      handleBookingConfirmed,
      data
  };
};

export default useConnect;
