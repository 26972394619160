import useBookingActions from 'graphql/hooks/useBookingActions';
import { useCallback, useState } from 'react';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  done: yup.boolean().required('Debe aceptar'),
});

type Args = {
  id: string;
};

const useConnect = ({ id }: Args) => {
  const { cancelBooking } = useBookingActions();
  const initialValues = {
    message: '',
    id,
    done: false,
  };
  type FormValues = typeof initialValues;
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await cancelBooking({
          id: values.id,
          message: values.message,
        });
        closeModal();
      } catch (error) {
        console.log(error);
      }
    },
    [cancelBooking, closeModal]
  );

  return {
    handleSubmit,
    initialValues,
    validationSchema,
    isOpen,
    openModal,
    closeModal,
  };
};

export default useConnect;
