import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { SPACE_BOOKINGS } from 'graphql/queries/bookings';
import { Bookings, BookingsVariables } from 'graphql/generated/Bookings';
import { normalizeBooking } from 'model/Booking';
import notEmpty from 'utils/notEmpty';

const useBookings = (variables?: BookingsVariables) => {
  const { loading: bookingLoading, data } = useQuery<
    Bookings,
    BookingsVariables
  >(SPACE_BOOKINGS, {
    fetchPolicy: "no-cache",
    variables,
  });
  const bookings = useMemo(
    () => (data?.bookings || []).filter(notEmpty).map(normalizeBooking),
    [data]
  );

  return {
    bookings,
    bookingLoading,
  };
};

export default useBookings;
