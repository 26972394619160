import React, { FC } from 'react';
import { Card, ContentLoader, Container, TextContainer } from './styles';
import { Props } from './types';

const BACKGROUND_COLOR = '#f1f1f1';

export const CityLoader: FC<Props> = ({ opacity = 1, ...props }) => {
  return (
    <Card {...props}>
      <ContentLoader
        speed={1}
        height="16.6994rem"
        backgroundColor={BACKGROUND_COLOR}
        $opacity={opacity}
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
    </Card>
  );
};

export const SpaceLoader: FC<Props> = ({ opacity = 1, ...props }) => {
  return (
    <Card {...props}>
      <ContentLoader
        speed={1}
        height="21.8125rem"
        backgroundColor={BACKGROUND_COLOR}
        $opacity={opacity}
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
    </Card>
  );
};

export const CategoryLoader: FC<Props> = ({ opacity = 1, ...props }) => {
  return (
    <Card {...props}>
      <ContentLoader
        speed={1}
        height="19.3506rem"
        backgroundColor={BACKGROUND_COLOR}
        $opacity={opacity}
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
    </Card>
  );
};

export const BoxLoader: FC<Props> = ({ opacity = 1, ...props }) => {
  return (
    <Container {...props}>
      <ContentLoader
        speed={1}
        style={{ height: '100%', width: '100%' }}
        backgroundColor={BACKGROUND_COLOR}
        $opacity={opacity}
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" style={{height: '100vh'}} />
      </ContentLoader>
    </Container>
  );
};

export const TitleLoader: FC<Props> = ({ opacity = 1, ...props }) => {
  return (
    <TextContainer {...props}>
      <ContentLoader
        speed={1}
        style={{ height: '2.4375rem', width: '13.75rem' }}
        backgroundColor={BACKGROUND_COLOR}
        $opacity={opacity}
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
    </TextContainer>
  );
};

export const TextLoader: FC<Props> = ({ opacity = 1, ...props }) => {
  return (
    <TextContainer {...props}>
      <ContentLoader
        width="6.25rem"
        height="1.3rem"
        backgroundColor={BACKGROUND_COLOR}
        $opacity={opacity}
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
    </TextContainer>
  );
};

export const DescriptionLoader: FC<Props> = ({ opacity = 1, ...props }) => {
  return (
    <TextContainer {...props}>
      <ContentLoader
        speed={1}
        backgroundColor={BACKGROUND_COLOR}
        $opacity={opacity}
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="1rem" />
        <rect x="0" y="25" rx="5" ry="5" width="100%" height="1rem" />
        <rect x="0" y="50" rx="5" ry="5" width="100%" height="1rem" />
        <rect x="0" y="75" rx="5" ry="5" width="70%" height="1rem" />
      </ContentLoader>
    </TextContainer>
  );
};
