import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { BodyMedium, Small } from 'components/Typography';

export const Component = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.ebonyClay};
  display: flex;
  flex-direction: column;
  box-shadow: 0rem 0.3125rem 1.25rem
    ${({ theme }) => addAlpha(theme.colors.ebonyClay, 0.05)};
  border-radius: 0.3125rem;
  transition: 0.3s;

  :hover {
    box-shadow: 0rem 0.3125rem 1.25rem
      ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.2)};
  }
`;

export const Section = styled.div``;

export const ImageWrapper = styled.div``;

export const Image = styled.img`
  height: 12.5rem;
  width: 100%;
  object-fit: cover;
  border-radius: 0.3125rem 0.3125rem 0 0;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 0.9rem 0.7rem 0.9rem;
  margin-top: -0.4rem;
  border-radius: 0 0 0.3125rem 0.3125rem;
`;

export const Title = styled(BodyMedium)`
  font-size: 1rem;
`;

export const Text = styled(Small)`
  display: block;
  margin-top: 0.1875rem;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const Price = styled(BodyMedium)`
  display: inline-block;
`;
