import React, { memo } from 'react';
import {
  Container,
  Title,
  ButtonBack,
  Header,
  Content,
  Section,
  Card,
  InfoCard,
  InfoTitle,
  PaperPlaneIcon,
  InfoText,
  Button,
  ButtonContainer,
  ArrowIcon,
  Invoices,
} from '../styles';
import useConnect from '../connect';

const Completed = () => {
  const { isLoading, booking, handleGoBack, handleBookingAgain } = useConnect();

  if (isLoading || !booking) return null;

  return (
    <Container>
      <Header>
        <ButtonBack type="button" onClick={handleGoBack} />
        <Title>Reserva Nº {booking.id}</Title>
      </Header>

      <Content>
        <Section>
          <InfoCard>
            <Header>
              <PaperPlaneIcon />
              <InfoTitle $isBlue>Reserva completada</InfoTitle>
            </Header>
            <InfoText>
              ¡Genial!. Esta reserva ha sido completada. En breve se pondrá en contacto el equipo de Sedee y te enviará una factura por email
            </InfoText>
            <Invoices
              preReserva={booking.prereservaInvoice?.url}
              reserva={booking.reservaInvoice?.url}
            />
          </InfoCard>
          <ButtonContainer>
            <Button
              type="button"
              onClick={handleBookingAgain}
              rightIcon={<ArrowIcon />}
              fullWidth
            >
              Volver a reservar
            </Button>
          </ButtonContainer>
        </Section>

        {booking.space && booking.space.location &&
        <Card
          id={booking.id}
          spaceName={booking.space.name}
          location={booking.space.location.name}
          images={booking.space.images}
          dates={booking.dates}
          capacity={booking.space.capacity}
          totalToPay={booking.total}
          bookingPrice={booking.bookingPrice}
          percentage={booking.percentage}
        />
      }
      </Content>
    </Container>
  );
};

export default memo(Completed);
