import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { ContainerProps } from '../types';

/* eslint-disable import/prefer-default-export */

export const Container = styled.div<ContainerProps>`
  position: sticky;
  top: 0;
  width: 100%;
  height: 5rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0rem 0.3125rem 1.25rem
    ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.2)};
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  z-index: 3;
  ${({ $isVisible }) =>
    $isVisible &&
    css`
      display: flex;
    `}
`;
