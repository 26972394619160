import React, {ChangeEvent, memo, useEffect,} from 'react';
import { useState } from 'react';
import {
  InfoTitle,
  InfoText,
  Content,
  Card,
  Select,
  Option,
  OptionCard,
  RadioSelector,
  OptionSection,
  SectionBottom,
  Section,
  Header,
  Container,
  Title,
  LinkText,
  InfoCard,
  SectionText, ButtonSection, BothContainers, RadioContent
} from '../styles';
import useConnect from '../connect';
import { Divider } from '@material-ui/core';
import PaymentButton from "./PaymentButton";
import TransferModal from "../../../Shared/Modals/TransferModal";
import ConfirmedButon from "../../../../components/ConfirmedButon";
import TransferButton from "./TransferButton";
import {BookingButtonWrapper} from "../../../Open/Spaces/styles";

const Payment = () => {
    const {isLoading, booking, handleChangeTotal} = useConnect();
    const [optionValue, setOptionValue] = useState(5);
    useEffect(
        ()=>{
            if (booking?.isTransfered){
                setOptionValue(2)
            }
        },[booking, optionValue]
    )
    if (isLoading || !booking) return null;

    const handleChangeOption = (event: ChangeEvent<HTMLSelectElement>) => {
        setOptionValue(+event.currentTarget.value)
    };
  return (
      <BothContainers>
          <Container>
              <Header>
                  <ConfirmedButon id={booking.id}/>
                  <Title>Pago</Title>
              </Header>
              <RadioContent>
                  <Section>
                      <Content>
                          <OptionCard>
                              <RadioSelector name='hola' onChange={handleChangeTotal} checked={!booking.totalPayment}/>
                              <Content>
                                  <OptionSection>
                                      <InfoTitle>
                                          Pagar solo el 10%
                                      </InfoTitle>
                                  </OptionSection>
                              </Content>
                          </OptionCard>
                      </Content>
                      <Content>
                          <OptionCard>
                              <RadioSelector name='hola' onChange={handleChangeTotal} checked={booking.totalPayment}/>
                              <Content>
                                  <OptionSection>
                                      <InfoTitle>
                                          Pagar todo ahora
                                      </InfoTitle>
                                  </OptionSection>
                              </Content>
                          </OptionCard>
                      </Content>
                      <Content>
                          <LinkText to="/cancellation-policies">
                              Más información
                          </LinkText>
                      </Content>
                      <Divider/>
                      <SectionBottom>
                          <Title>
                              Pagar con
                          </Title>
                          <Section>
                              <InfoText>Método de pago</InfoText>
                              <Select onChange={handleChangeOption}>
                                  {optionValue === 5 && (
                                      <Option value='1'>Selecciona una opción</Option>
                                  )}
                                  {!booking.isTransfered && (
                                    <Option value='1'>Tarjeta de débito o crédito</Option>
                                  )
                                  }
                                  <Option value='2'>Transferencia</Option>
                              </Select>
                              {optionValue === 1 && (
                                  <Section>
                                  </Section>

                              )}
                              {optionValue === 2 && (
                                  <SectionText>
                                      <TransferModal bookingId={booking.id}/>
                                  </SectionText>
                              )}
                          </Section>
                      </SectionBottom>
                      <Divider/>
                      <SectionBottom>
                          <InfoCard>
                              <Header>
                                  <InfoTitle>Política de cancelación</InfoTitle>
                              </Header>
                              <InfoText>
                                  Al hacer clic en el siguiente botón, acepto las Normas del espacio, Información sobre seguridad y Política de Cancelación.
                              </InfoText>
                              <InfoText>
                                  También acepto abonar el importe total mostrado, que incluye las tarifas de servicio. Términos de Pago entre tú y Sedee S.L.
                              </InfoText>
                          </InfoCard>
                      </SectionBottom>
                      <SectionBottom>
                          <ButtonSection>
                              {optionValue === 1 && (
                                  <PaymentButton id={booking.id}/>
                              )}
                              {optionValue === 2 && (
                                  <TransferButton />
                              )}
                          </ButtonSection>
                      </SectionBottom>
                  </Section>
                  {booking.space && booking.space.location && (
                      <Section>
                          {optionValue === 2 &&
                              (<Card
                                  id={booking.id}
                                  spaceName={booking.space.name}
                                  location={booking.space.location.name}
                                  images={booking.space.images}
                                  dates={booking.dates}
                                  capacity={booking.space.capacity}
                                  totalToPay={booking.total}
                                  cancellationInsurance={false}
                                  bookingPrice={booking.bookingPrice}
                                  percentage={booking.percentage}
                                  daysCount={booking.totalDays}
                                  Reservation={booking.totalPayment}
                                  isTransfered={booking.isTransfered}
                                  transferButton={true}
                              />)
                          }
                          { optionValue === 1 &&
                              (
                                  <Card
                                      id={booking.id}
                                      spaceName={booking.space.name}
                                      location={booking.space.location.name}
                                      images={booking.space.images}
                                      dates={booking.dates}
                                      capacity={booking.space.capacity}
                                      totalToPay={booking.total}
                                      cancellationInsurance={false}
                                      bookingPrice={booking.bookingPrice}
                                      percentage={booking.percentage}
                                      daysCount={booking.totalDays}
                                      Reservation={!booking.totalPayment}
                                      payButtons
                                      payEverything
                                  />
                              )
                          }
                          { optionValue !== 1 && optionValue !== 2 &&
                              (
                                  <Card
                                      id={booking.id}
                                      spaceName={booking.space.name}
                                      location={booking.space.location.name}
                                      images={booking.space.images}
                                      dates={booking.dates}
                                      capacity={booking.space.capacity}
                                      totalToPay={booking.total}
                                      cancellationInsurance={false}
                                      bookingPrice={booking.bookingPrice}
                                      percentage={booking.percentage}
                                      daysCount={booking.totalDays}
                                      Reservation={!booking.totalPayment}
                                      isTransfered={booking.isTransfered}
                                  />
                              )
                          }
                      </Section>
                  )}
              </RadioContent>
          </Container>

          {optionValue === 1 && (
              <BookingButtonWrapper>
                  <PaymentButton id={booking.id}/>
              </BookingButtonWrapper>
          )}
          {optionValue === 2 && (
              <BookingButtonWrapper>
                  <TransferButton/>
              </BookingButtonWrapper>
          )}
      </BothContainers>

  );
};

export default memo(Payment);
