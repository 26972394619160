import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const { loading, me } = useMe();

  const isAuth = !!me;

  return {
    loading,
    isAuth,
  };
};

export default useConnect;