import { memo } from 'react';
import styled, { css } from 'styled-components';

const styles = css`
  display: block;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.ebonyClay};
  border: 0.125rem solid ${({ theme }) => theme.colors.gray400};
  border-radius: 0.3125rem;
  padding: 0.4688rem 0.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const Component = styled.button<{ $checked: boolean }>`
  ${styles}
  cursor: pointer;
  ${({ $checked }) =>
    $checked &&
    css`
      color: ${({ theme }) => theme.colors.royalBlue};
      background-color: ${({ theme }) => theme.colors.hawkesBlue};
      border-color: ${({ theme }) => theme.colors.hawkesBlue};
    `}
`;

export default memo(Component);

export const Label = styled.div`
  ${styles}
`;
