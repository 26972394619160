const formatPriceNumber = (priceNumber: string | number) => {
  const quantity =
    typeof priceNumber === 'string' ? parseInt(priceNumber, 10) : priceNumber;

  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(quantity);
};

export default formatPriceNumber;
