import styled, { css } from 'styled-components';
import { H2, Body } from 'components/Typography';
import DefaultButton from 'components/Button';
import DefaultSelect from 'components/BaseSelect';
import Space from 'components/Card/Space';
import { from } from 'styles/media';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';
import { ReactComponent as DefaultFilterIcon } from 'assets/icons/filter.svg';
import DefaultPagination from 'components/Pagination';
import MaxContent from 'components/MaxContent';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled(MaxContent)`
  margin-top: 1.5rem;
  margin-bottom: 5rem;
`;

export const Title = styled(H2)``;

export const Subtitle = styled(Body)``;

export const Header = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
`

export const SectionRight = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: flex-end;
  width: 12.5rem;

  ${from.tablet} {
    grid-template-columns: 13.75rem;
    width: 22.5rem;
  }
`;

export const Button = styled(DefaultButton)<{ $isMapButton?: boolean }>`
  ${({ $isMapButton }) => {
    if ($isMapButton) {
      return css`
        position: fixed;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;

        ${from.mobile} {
          top: unset;
          position: unset;
          left: unset;
          z-index: unset;
          transform: unset;
        }
      `;
    }
    return css`
      border: none;
      padding-right: 0;
      padding-left: 0;

      ${from.tablet} {
        padding-right: 0.85rem;
        padding-left: 0.85rem;
      }
    `;
  }}
`;

export const Map = styled(MapIcon)`
  width: 1.3rem;
  height: 1.3rem;
`;

export const FilterIcon = styled(DefaultFilterIcon)`
  width: 1.3rem;
  height: 1.3rem;
`;

export const Select = styled(DefaultSelect)`
  margin-left: 0.5rem;
`;

export const SpacesContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
  grid-gap: 2rem;
  width: 100%;
  height: 100%;

  ${from.tablet} {
    grid-gap: 1.5rem;
  }
`;

export const SpaceCard = styled(Space)``;

export const Pagination = styled(DefaultPagination)`
  margin: 0 auto;
`;
