import styled from 'styled-components';
import DefaultButton from 'components/Button';
import DefaultBulletButton from 'components/BulletButton';
import DefaultFade from '@material-ui/core/Fade';
import { FiX } from 'react-icons/fi';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Fade = styled(DefaultFade)`
  :focus {
    outline: none;
  }
`;

export const Button = styled(DefaultButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 2.25rem;
  margin-right: 4.9375rem;
  border: none;
`;

export const BulletButton = styled(DefaultBulletButton)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem;
  border: none;
`;

export const CloseIcon = styled(FiX)`
  width: 1.3125rem;
  height: 1.3125rem;
`;