import React, { FC, memo } from 'react';
import { DropContainer, Item } from './styles';
import { Props } from './types';

const Dropdown: FC<Props> = ({
  actions,
  open,
  id,
  handleClose,
  anchorEl,
  ...props
}) => {
  return (
    <DropContainer
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    >
      {actions.map(({ label, ...actionProps }) => (
        <Item key={label} label={label} {...actionProps} />
      ))}
    </DropContainer>
  );
};

export default memo(Dropdown);
