import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import { H5 } from 'components/Typography';
import DefaultFade from '@material-ui/core/Fade';
import { FiX } from 'react-icons/fi';
import { from } from 'styles/media';

export const FullScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100%;
`;

export const Fade = styled(DefaultFade)`
  :focus {
    outline: none;
  }
`;

export const Default = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  ${from.mobile}{
    min-width: 30.625rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.white};
  padding: 0rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  :focus {
    outline: none;
  }
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  box-shadow: 0rem 0.3125rem 1.25rem rgba(51, 46, 191, 0.1);
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.75rem;
`;

export const HeaderMobile = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  box-shadow: 0rem 0.3125rem 1.25rem rgba(51, 46, 191, 0.1);
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.75rem 1rem;
`;

export const Body = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0rem -0.3125rem 1.25rem rgba(51, 46, 191, 0.1);
  padding: 1rem;

  ${from.mobile} {
    padding: 1.5rem 2.5rem;
  }
`;

export const Button = styled.button`
  color: ${({ theme }) => theme.colors.ebonyClay};
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
`;

export const CloseIcon = styled(FiX)`
  color: ${({ theme }) => theme.colors.ebonyClay};
  width: 1.5rem;
  height: 1.5rem;
`;

export const Title = styled(H5)`
  width: 100%;
  text-align: center;
`;

export const EmptyBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;
