import { Button } from 'components/Typography';
import styled from 'styled-components';
import { ReactComponent as File } from 'assets/icons/file-text-outline.svg';

export const Container = styled.a`
  text-decoration: none;
  background-color: inherit;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.ebonyClay};
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  transition: all 0.2s;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.royalBlue};
    path {
      fill: ${({ theme }) => theme.colors.royalBlue};
    }
  }
`;

export const Text = styled(Button)``;

export const Icon = styled(File)`
  width: 1.3125rem;
  height: 1.3125rem;
  margin-right: 0.5rem;

  path {
    fill: ${({ theme }) => theme.colors.ebonyClay};
  }
`;
