import { SPACE_CATEGORIES } from 'graphql/queries/spaceCategories';
import { normalizeSpaceCategory } from 'model/SpaceCategory';
import { SpaceCategories, SpaceCategoriesVariables } from 'graphql/generated/SpaceCategories';
import notEmpty from 'utils/notEmpty';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

const useSpaceCategories = (variables?: SpaceCategoriesVariables) => {
  const { loading, data } = useQuery<SpaceCategories, SpaceCategoriesVariables>(SPACE_CATEGORIES, {
    variables,
  });

  const spaceCategories = useMemo(
    () => (data?.spaceCategories || []).filter(notEmpty).map(normalizeSpaceCategory),
    [data]
  );

  return {
    spaceCategories,
    loading,
  };
};

export default useSpaceCategories; 