import styled from 'styled-components';
import { Button } from 'components/Typography';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import addAlpha from 'utils/addAlpha';

export const Container = styled(Link)`
  text-decoration: none;
  max-width: 18.875rem;
  padding: 8.8125rem 5.875rem;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0rem 0.3125rem 1.25rem ${({ theme }) => addAlpha(theme.colors.ebonyClay, 0.05)};
  border-radius: 0.3125rem;
  transition: 0.8s;

  :hover {
    box-shadow: 0rem 0.3125rem 1.25rem ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.2)};
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.royalBlue};
`;

export const Text = styled(Button)``;

export const Icon = styled(FiPlus)`
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
`;