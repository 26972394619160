import React from 'react';
import Topbar from 'containers/Shared/Topbar';
import useConnect from './connect';
import {
  Container,
  Content,
  Row,
  SpaceCard,
  AddCard,
  RowContainer,
  CardsContainer,
  Title,
} from './styles';

const Favorites = () => {
  const { favorites, favoriteLoading } = useConnect();

  if (favoriteLoading) return null;

  return (
    <Container>
      <Topbar isAuth />

      <Content />

      <Row>
        <Title>Espacio favoritos</Title>
        <RowContainer>
          <CardsContainer>
            {favorites.length > 0 ? (
              favorites.map((space) => (
                <SpaceCard
                  linkProps={{
                    to: `/spaces/${space?.slug}`,
                  }}
                  key={space.id}
                  title={space.name || ''}
                  location={space.fullLocation}
                  maxCapacity={space.capacity}
                  images={space.images}
                  price="1.200€"
                  canBeBookedInstantly
                />
              ))
            ) : (
              <AddCard to="/search" />
            )}
          </CardsContainer>
        </RowContainer>
      </Row>
    </Container>
  );
};

export default Favorites;
