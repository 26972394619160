import { gql } from '@apollo/client';
import { SPACE_CATEGORY_FRAGMENT } from 'graphql/fragments/spaceCategory';

/* eslint-disable import/prefer-default-export */

export const SPACE_CATEGORIES = gql`
  query SpaceCategories (
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    spaceCategories (
        sort: $sort
        limit: $limit
        start: $start
        where: $where
      ) {
      ...SpaceCategoryParts
    }
  }
  ${SPACE_CATEGORY_FRAGMENT}
`;