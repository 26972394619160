import {
  useQueryParams,
  encodeQueryParams,
  StringParam,
  NumberParam,
  BooleanParam,
  ArrayParam,
} from 'use-query-params';
import { stringify } from 'query-string';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

const allowedParams = {
  code: StringParam,
  location: StringParam,
  startDate: StringParam,
  endDate: StringParam,
  isCanceled: BooleanParam,
  spaceCategory: ArrayParam,
  minPrice: NumberParam,
  maxPrice: NumberParam,
  capacity: NumberParam,
  reducedMovility: BooleanParam,
  sort: StringParam,
  start: NumberParam,
  limit: NumberParam,
};

const useQuery = () => {
  const [query, setQuery] = useQueryParams(allowedParams);
  const encodedQuery = encodeQueryParams(allowedParams, query);
  const search = stringify(encodedQuery);

  const resetQueryValues = () => {
    setQuery({
      location: undefined,
      startDate: undefined,
      endDate: undefined,
      spaceCategory: undefined,
      minPrice: undefined,
      maxPrice: undefined,
      capacity: undefined,
      reducedMovility: undefined,
      sort: undefined,
      code: undefined,
    });
  };

  const queryNotEmptyKeys = omitBy(query, isNil);
  const queryNotPaginationKeys = omit(queryNotEmptyKeys, ['start', 'limit']);
  const filterCount = Object.values(queryNotPaginationKeys).length;

  return {
    query,
    setQuery,
    search,
    filterCount,
    resetQueryValues,
  };
};

export default useQuery;
