import { differenceInSeconds } from 'date-fns';
import { UtilsProps } from './types';

const getTimeDiferrence = ({ to, from }: UtilsProps) => {
  let difference = differenceInSeconds(to, from);

  const countedSeconds = Math.floor(difference % 60);
  difference -= countedSeconds;

  const countedMinutes = Math.floor((difference / 60) % 60);
  difference -= countedMinutes * 60;

  const countedHours = Math.floor(difference / 60 / 60);

  return {
    seconds: countedSeconds,
    minutes: countedMinutes,
    hours: countedHours,
  };
};

export default getTimeDiferrence;
