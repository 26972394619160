import { gql } from '@apollo/client';
import { USER_FRAGMENT } from 'graphql/fragments/user';

/* eslint-disable import/prefer-default-export */

export const USER = gql`
  query User ($id: ID!) {
    user (id: $id) {
      ...UserParts
    }
  }
  ${USER_FRAGMENT}
`;