import TextStyles from "../../../components/TextStyles";
import {Container} from "../TermsAndConditions/styles";
import React from "react";
import {StarText} from "./styles";
import {Li} from "../PaymentOptions/styles";

const HowSedeeWorks = () => {
  return (
    <TextStyles>
      <Container>
        <h2>¿CÓMO FUNCIONA?</h2>
        <h4>REGISTRO</h4>
        <p>Regístrate en nuestra web para comenzar la búsqueda del espacio ideal para tu evento.</p>
        <p>Simplemente completa los datos requeridos en nuestro formulario de registro.</p>
        <h4>BÚSQUEDA DE ESPACIOS</h4>
        <p>Una vez que te hayas registrado y hayas iniciado sesión, sigue estos pasos para encontrar el espacio perfecto para tu evento:</p>
        <ol>
          <Li>Selecciona la provincia donde deseas buscar tu espacio y elige las fechas en las que planeas realizar tu evento.</Li>
          <Li>En la página de búsqueda, encontrarás opciones para refinar tus resultados. Puedes utilizar filtros como el rango de precios, capacidad, o tipo de espacio. Además, tienes la opción de ordenar las ubicaciones disponibles por precio.</Li>
          <Li>Haz clic en el espacio de tu interés para acceder a su ficha detallada. En esta ficha, encontrarás una variedad de información útil, que incluye fotografías, un recorrido virtual (cuando esté disponible), una descripción detallada de las características y un plano de ubicación.</Li>
          <Li>En el caso de los espacios que ofrecen la opción de alquilar zonas o salas específicas, también encontrarás enlaces directos a las fichas de esas alternativas dentro de la misma ubicación.</Li>
        </ol>
        <p>Este proceso te ayudará a explorar y encontrar el espacio perfecto que se adapte a tus necesidades para tu evento. ¡Estamos aquí para ayudarte en cada paso del camino!</p>
        <h4>RESERVA</h4>
        <p>Dentro de la ficha de cada espacio, encontrarás un cuadro para solicitar una reserva. Sigue estos pasos para elegir la opción que mejor se adapte a tus necesidades:</p>
        <StarText>
          ★ Reserva Inmediata
        </StarText>
        <h5>
          ¿Qué significa "Reserva Inmediata"?
        </h5>
        <p>Ofrecemos espacios con disponibilidad actualizada en tiempo real, lo que significa que la disponibilidad para la fecha seleccionada está verificada y la tarifa indicada está actualizada.</p>
        <h5>¿Cómo identificar qué espacios permiten "Reserva Inmediata"?</h5>
        <p>Los espacios que permiten "Reserva Inmediata" se destacan con un recuadro azul en las tarjetas de búsqueda y en la ficha del espacio encontrarás el mismo recuadro bajo el calendario de fechas para reservar.</p>
        <h5>¿El espacio permite "Reserva Inmediata" para cualquier fecha?</h5>
        <p>No necesariamente. Debes consultar el calendario dentro de la ficha del espacio en busca de fechas con un rayo delante del precio. Estas fechas específicas están disponibles para la modalidad de "Reserva Inmediata" y garantizan la disponibilidad y tarifa indicada si continúas con la reserva.</p>
        <p>Puedes encontrar fechas tachadas, lo que indica que no hay disponibilidad, o el precio sin el rayo delante, lo que indica disponibilidad sujeta a confirmación.</p>
        <h5>
          ¿Cómo proceder a la reserva inmediata?
        </h5>
        <p>
          Selecciona las fechas para tu evento y haz clic en el botón "Reserva Inmediata". Inmediatamente comenzará el proceso de reserva, donde verás el precio total y un desglose del depósito requerido para confirmar la reserva. Puedes pagar el depósito en ese momento o la totalidad de la reserva.
        </p>
        <p>
          Si no realizas el pago inmediato, tendrás 24 horas para completarlo y enviarnos el comprobante a reservas@sedee.io junto con el ID de la reserva.
        </p>
        <p>
          Tu espacio para el evento quedará reservado una vez realizado el pago.
        </p>
        <StarText>
          ★ Reserva bajo Disponibilidad:
        </StarText>
        <p>
          A diferencia de la Reserva Inmediata, algunos espacios no tienen disponibilidad y tarifa actualizadas en tiempo real. En este caso, necesitamos confirmar la disponibilidad y tarifa con el espacio para las fechas que solicitas
        </p>
        <h5>¿Puedo solicitar reserva para cualquier fecha?</h5>
        <p>Puedes solicitar reserva para las fechas que tengan un precio en el calendario dentro de la ficha del espacio. Las fechas tachadas no tienen disponibilidad.</p>
        <h5>¿Cómo proceder a realizar una reserva bajo disponibilidad?</h5>
        <p>
          Selecciona las fechas en el calendario dentro de la ficha del espacio y haz clic en el botón "Disponibilidad y precios". Obtendrás un desglose del depósito requerido para confirmar la reserva y el importe pendiente de pago más adelante. Ten en cuenta que se trata de un precio aproximado.
        </p>
        <p>
          En un plazo máximo de 48 horas, revisaremos la disponibilidad y tarifa con el espacio para las fechas solicitadas. Te enviaremos un mensaje comunicando si se acepta la reserva y el precio real, o si no está disponible.
        </p>
        <p>
          Si se acepta tu reserva, tendrás 24 horas para realizar el pago del depósito o de la totalidad de la reserva y enviar el comprobante a reservas@sedee.io
        </p>
        <p>
          Una vez realizado el pago, tu espacio para el evento estará reservado.
        </p>
        <p>
          Estos procedimientos te ayudarán a encontrar y reservar el espacio perfecto para tu evento, ya sea de forma inmediata o bajo disponibilidad. ¡Estamos aquí para asistirte en cada paso del proceso!
        </p>
        <h4>
          ¿QUÉ TENGO QUE HACER DESPUÉS DE REALIZAR LA RESERVA?
        </h4>
        <p>
          Una vez que tu reserva ha sido aceptada y has efectuado el pago del depósito, solo queda realizar el pago final antes de la fecha indicada en la confirmación de la reserva, en caso de no haber completado el pago total en el primer paso. Asegúrate de enviar el comprobante de pago a reservas@sedee.io.
        </p>
        <p>
          Una vez cumplidos estos pasos, estarás listo para disfrutar de tu evento sin preocupaciones.
        </p>
      </Container>
    </TextStyles>
  );
};

export default HowSedeeWorks;
