export default {
  fonts: {},
  colors: {
    ebonyClay: '#212330',
    royalBlue: '#4640DF',
    royalBlue700: '#2420A0',
    royalBlue600: '#332EBF',
    royalBlue300: '#918CF5',
    royalBlue200: '#B7B3FB',
    royalBlue100: '#DBD9FD',
    outrageousOrange: '#FB6144',
    hawkesBlue: '#DCF0FC',
    hawkesBlue600: '#BDE3F9',
    hawkesBlue400: '#ECF7FD',
    white: '#FFFFFF',
    gray200: '#F5F6F7',
    gray300: '#E3E5E6',
    gray400: '#CACBCC',
    gray500: '#AFB1B3',
    gray600: '#898B8C',
    gray700: '#646566',
    gray800: '#3E3F40',
    black: '#16181A',
  },
  weights: {},
  maxWidth: {
    wide: '90rem',
    normal: '80rem',
  },
};
