import React, { FC } from 'react';
import { Formik, Field } from 'formik';
import { Container, Body, Form, Input, Button, Text } from './styles';
import { Props } from './types';
import { validationSchema } from './constants';
import useConnect from './connect';

const Forgot: FC<Props> = ({ handleClose, open, ...props }) => {
  const { initialValues, handleSubmit, isLoading } = useConnect({
    handleClose,
  });

  if (isLoading) return <>Loading...</>;

  return (
    <Container
      title="¿Olvidaste la contraseña?"
      open={open}
      handleClose={handleClose}
      {...props}
    >
      <Body>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form noValidate>
            <Text>
              Introduce la dirección de correo asociada a tu cuenta y te
              enviaremos un enlace para restablecer tu contraseña.
            </Text>

            <Field type="email" name="email" as={Input} label="Email*" />

            <Button variant="primary" fullWidth type="submit">
              Restablecer contraseña
            </Button>
          </Form>
        </Formik>
      </Body>
    </Container>
  );
};

export default Forgot;
