import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_PROFILE } from 'graphql/mutations/updateProfile';
import { UPDATE_PASSWORD } from 'graphql/mutations/updatePassword';
import { FORGOT_PASSWORD } from 'graphql/mutations/forgotPassword';
import { SedeeError } from 'graphql/helpers';
import { UpdateUser, UpdateUserVariables } from 'graphql/generated/UpdateUser';
import {
  ForgotPassword,
  ForgotPasswordVariables,
} from 'graphql/generated/ForgotPassword';
import {
  ResetPassword,
  ResetPasswordVariables,
} from 'graphql/generated/ResetPassword';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

const useUserActions = () => {
  const [
    updateProfileMutation,
    { loading: isUpdateProfileLoading },
  ] = useMutation<UpdateUser, UpdateUserVariables>(UPDATE_PROFILE);
  const [
    updatePasswordMutation,
    { loading: isUpdatePasswordLoading },
  ] = useMutation<ResetPassword, ResetPasswordVariables>(UPDATE_PASSWORD);
  const [
    forgotPasswordMutation,
    { loading: isForgotPasswordLoading },
  ] = useMutation<ForgotPassword, ForgotPasswordVariables>(FORGOT_PASSWORD);

  const updateProfile = useCallback(
    async (input: UpdateUserVariables) => {
      try {
        const cleanInput: UpdateUserVariables = {
          input: {
            where: input.input.where,
            data: omitBy(input.input.data, isNil),
          },
        };

        await updateProfileMutation({ variables: cleanInput });
      } catch (error) {
        throw new SedeeError('Failed to update user', error);
      }
    },
    [updateProfileMutation]
  );

  const updatePassword = useCallback(
    async (input: ResetPasswordVariables) => {
      try {
        await updatePasswordMutation({ variables: input });
      } catch (error) {
        throw new SedeeError('Failed to update password', error);
      }
    },
    [updatePasswordMutation]
  );

  const forgotPassword = useCallback(
    async (input: ForgotPasswordVariables) => {
      try {
        await forgotPasswordMutation({ variables: input });
      } catch (error) {
        throw new SedeeError('Failed to request password reset', error);
      }
    },
    [forgotPasswordMutation]
  );

  return {
    isLoading:
      isUpdateProfileLoading ||
      isUpdatePasswordLoading ||
      isForgotPasswordLoading,
    updateProfile,
    updatePassword,
    forgotPassword,
  };
};

export default useUserActions;
