import styled from "styled-components";
export const TransferButton = styled.button`
  border: none;
  color: #4640DF;
  text-decoration: underline;
  cursor: pointer;
  background: none;
`;
export const Container = styled.div`
`;
export const Section = styled.div`
  padding: 1rem 1.25rem;
`;

export const SectionTitle = styled.h3`
    font-weight: bold;
    font-size: larger;
`;

export const ButtonTransfer = styled.button`
  color: ${({ theme }) => theme.colors.ebonyClay};
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
`

export const SectionText = styled.p`
  padding: 1rem 1.75rem;
`;

export  const SectionUl = styled.ul`
  padding: 0 3rem;
`;

export const SectionLi = styled.li`
  list-style: circle;
  padding: 0.25rem 0;
`;

export const Strong = styled.strong`
  font-weight: bold;
`;