import { CUSTOM_SPACE_FRAGMENT, SPACE_FRAGMENT } from 'graphql/fragments/space';

import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const AVAILABILITY_SPACES = gql`
  query AvailabilitySpaces(
    $startDate: Date
    $endDate: Date
    $location: String
    $slug: String
    $spaceCategory: [String]
    $concerts: Boolean
    $fairs: Boolean
    $festivals: Boolean
    $exhibitions: Boolean
    $conferences: Boolean
    $meetings: Boolean
    $meals: Boolean
    $wifi: Boolean
    $furniture: Boolean
    $naturalLight: Boolean
    $outdoorBathroom: Boolean
    $indoorBathroom: Boolean
    $noTimeLimit: Boolean
    $soundProofing: Boolean
    $hasAccommodation: Boolean
    $lat: String
    $long: String
    $garden: Boolean
    $pool: Boolean
    $wardrobe: Boolean
    $airConditioner: Boolean
    $minPrice: Int
    $maxPrice: Int
    $capacity: Int
    $reducedMovility: Boolean
    $roomType: [String]
    $sort: String
    $limit: Int
    $start: Int
  ) {
    availabilitySpaces(
      startDate: $startDate
      endDate: $endDate
      location: $location
      slug: $slug
      spaceCategory: $spaceCategory
      concerts: $concerts
      fairs: $fairs
      festivals: $festivals
      exhibitions: $exhibitions
      conferences: $conferences
      meetings: $meetings
      meals: $meals
      wifi: $wifi
      furniture: $furniture
      naturalLight: $naturalLight
      outdoorBathroom: $outdoorBathroom
      indoorBathroom: $indoorBathroom
      noTimeLimit: $noTimeLimit
      soundProofing: $soundProofing
      hasAccommodation: $hasAccommodation
      lat: $lat
      long: $long
      garden: $garden
      pool: $pool
      wardrobe: $wardrobe
      airConditioner: $airConditioner
      minPrice: $minPrice
      maxPrice: $maxPrice
      capacity: $capacity
      reducedMovility: $reducedMovility
      roomType: $roomType
      sort: $sort
      limit: $limit
      start: $start
    ) {
      ...CustomSpaceParts
    }
  }
  ${CUSTOM_SPACE_FRAGMENT}
`;

export const SPACES = gql`
  query Spaces($sort: String, $limit: Int, $start: Int, $where: JSON) {
    spaces(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...SpaceParts
    }
  }
  ${SPACE_FRAGMENT}
`;

export const GET_SPACE = gql`
  query GetSpace($slug: String!) {
    spaces(where: { slug: $slug }) {
      ...SpaceParts
    }
  }
  ${SPACE_FRAGMENT}
`;
