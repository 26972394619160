import React, { memo } from 'react';
import { Subtitle, EmptyTitle, EmptyContainer } from './styles';

const Placeholder = () => {
  return (
    <EmptyContainer>
      <EmptyTitle>No hay resultados</EmptyTitle>
      <Subtitle>
        Prueba cambiar las fechas, eliminar filtros o ampliar el radio de
        búsqueda
      </Subtitle>
    </EmptyContainer>
  );
};

export default memo(Placeholder);
