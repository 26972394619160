import React, { FC, memo } from 'react';
// import useLogic from './logic';
import {
  Component,
  CarouselWrapper,
  Carousel,
  // LikeButton,
  Content,
  BottomSection,
  Text,
  MaxCapacity,
  SmallText,
  Title,
  Price,
  Tag,
  PersonIcon,
} from './styles';
import { LinkOverlay } from '../styles';
import { Props } from './types';

const SpaceCard: FC<Props> = ({
  linkProps,
  title,
  location,
  maxCapacity,
  images,
  price,
  canBeBookedInstantly,
  ...props
}) => {
  // const { isLiked, handleClickFav } = useLogic();
  return (
      <>
            <Component {...props}>
              {linkProps && <LinkOverlay {...linkProps} />}
              <CarouselWrapper>
                <Carousel images={images} />
                {
                  // <LikeButton isLiked={isLiked} onClick={handleClickFav} />
                }
              </CarouselWrapper>
              <Content>
                <Title>{title}</Title>
                <Text>{location}</Text>
                <MaxCapacity>
                  <PersonIcon /> {maxCapacity} Máx
                </MaxCapacity>
                <BottomSection>
                  {price !== '0 €' && (
                      <div>
                        {!canBeBookedInstantly && (
                            <SmallText style={{ marginRight: '3px' }}>desde</SmallText>
                        )}
                        <Price> {price} </Price> <SmallText>día</SmallText>
                      </div>
                  )}
                  {canBeBookedInstantly && <Tag type="solid">Reserva Inmediata</Tag>}
                </BottomSection>
              </Content>
            </Component>
      </>
  );
};

export default memo(SpaceCard);
