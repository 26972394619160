import { useState, useCallback } from 'react';
import useAuth from 'graphql/hooks/useAuth';

const useConnect = () => {
  const { logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleLogout = useCallback(() => logout(), [logout]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);

  const id: 'simple-popover' | undefined = open ? 'simple-popover' : undefined;

  const actions = [
    { label: 'Mis reservas', to: '/bookings' },
    // { label: 'Favoritos', to: '/favorites' },
    { label: 'Mi perfil', to: '/profile' },
    { label: 'Ayuda', to: '/help' },
    { label: 'Cerrar sesión', onClick: handleLogout },
  ];

  return {
    handleClick,
    handleClose,
    open,
    id,
    actions,
    anchorEl,
  };
};

export default useConnect;
