import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Me } from 'graphql/generated/Me';
import { ME } from 'graphql/queries/me';
import { normalizeMe } from 'model/Me';

const useMe = () => {
  const { data, refetch, loading } = useQuery<Me>(ME);

  const me = useMemo(() => (data?.me ? normalizeMe(data.me) : undefined), [
    data,
  ]);

  return {
    me,
    refetch,
    loading,
  };
};

export default useMe;