import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;
