import styled from 'styled-components';
import DefaultAvatar from 'components/Avatar';
import DefaultDropdown from 'components/Dropdown';
import { FiAlignJustify } from 'react-icons/fi';

export const Container = styled.div``;

export const Clickable = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const Box = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 3rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.royalBlue};
  box-sizing: border-box;
  border-radius: 0.3125rem;
`;

export const Icon = styled(FiAlignJustify)`
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.colors.royalBlue};
`;

export const Avatar = styled(DefaultAvatar)``;

export const Dropdown = styled(DefaultDropdown)``;
