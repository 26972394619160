import { gql } from '@apollo/client';
import { ME_FRAGMENT } from 'graphql/fragments/me';

/* eslint-disable import/prefer-default-export */

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        ...MeParts
      }
    }
  }
  ${ME_FRAGMENT}
`;

export const REGISTER = gql`
  mutation Register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        ...MeParts
      }
    }
  }
  ${ME_FRAGMENT}
`;
