import { from as fromMedia, useMediaQuery } from 'styles/media';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useMe from 'graphql/hooks/useMe';
import { formatISO } from 'date-fns';
import useAvailability from 'graphql/hooks/useAvailability';
import useAvailabilitySpace from 'graphql/hooks/useAvailabilitySpace';
import useBookingActions from 'graphql/hooks/useBookingActions';
import usePrices from 'graphql/hooks/usePrices';
import { CreateBookingVariables } from 'graphql/generated/CreateBooking';
import { useModalContext } from 'provider/Modal';
import { BookingInput } from 'components/BookingCard/types';

const useConnect = () => {
  const { me } = useMe();
  const isMobile = !useMediaQuery(fromMedia.tablet);
  const { goBack, push } = useHistory();
  const { spaceSlug } = useParams<{ spaceSlug: string }>();
  const { loading: spaceLoading, spaces: space } = useAvailability({
    slug: spaceSlug,
  });

  const { loading: pricesLoading, prices } = usePrices({
    id: spaceSlug
  });

  const [openTour, setOpenTour] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);

  const params = new URLSearchParams(window.location.search)

  let startDate = new Date();
  let endDate = new Date();
  if (params.get('startDate')) {
    startDate = new Date(params.get('startDate')!);
  }
  if (params.get('endDate')) {
    endDate = new Date(params.get('endDate')!);
  }
  const [from, setFrom] = useState<Date>(startDate);
  const [to, setTo] = useState<Date>(endDate);
  const [isLiked, setIsLiked] = useState(false);
  const { handleOpenModalLogin } = useModalContext();

  const handleOpenTour = useCallback(() => {
    setOpenTour(true);
  }, [setOpenTour]);

  const handleCloseTour = useCallback(() => {
    setOpenTour(false);
  }, [setOpenTour]);

  const handleOpenGallery = useCallback(() => {
    setOpenGallery(true);
  }, [setOpenGallery]);

  const handleCloseGallery = useCallback(() => {
    setOpenGallery(false);
  }, [setOpenGallery]);

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const { loading: spacesLoading, spaces } = useAvailability({
    start: 0,
    limit: 4,
  });

  spaces.sort(
      (value) => {
        return value.canInstantBooking ? -1 : 1;
      }
  )

  const { createBooking } = useBookingActions();

  const {
    availability,
    getAvailability,
    loading: loadingAvailability,
  } = useAvailabilitySpace({
    spaceId: spaceSlug,
    startDate: formatISO(new Date(from), { representation: 'date' }).toString(),
    endDate: formatISO(new Date(to), { representation: 'date' }).toString(),
  });

  const handleSubmitBooking = useCallback(
    async (values: BookingInput) => {
      if (!me) {
        handleOpenModalLogin();
        return;
      }
      if (!values.space) {
        return;
      }
      const bookingArgs: CreateBookingVariables = {
        input: {
          startDate: formatISO(new Date(from), {
            representation: 'date',
          }).toString(),
          endDate: formatISO(new Date(to), {
            representation: 'date',
          }).toString(),
          spaceId: values.space,
        },
      };
      try {
        const booking = await createBooking(bookingArgs);
        if (booking) {
          push(`/bookings/${booking?.id}`);
        } else {
          push(`/booking`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [push, createBooking, from, to, handleOpenModalLogin, me]
  );

  useEffect(() => {
    getAvailability();
  }, [from, to, getAvailability]);

  return {
    me,
    isMobile,
    spacesLoading,
    spaces,
    prices,
    isLoading: spaceLoading || pricesLoading,
    space,
    from,
    setFrom,
    to,
    setTo,
    isLiked,
    setIsLiked,
    handleGoBack,
    handleOpenTour,
    handleCloseTour,
    openTour,
    handleOpenGallery,
    handleCloseGallery,
    openGallery,
    loadingAvailability,
    availability,
    handleSubmitBooking,
  };
};

export default useConnect;
