import styled from 'styled-components';
import { H3 } from 'components/Typography';
import DefaultSpace from 'components/Card/Space';
import DefaultAddCard from 'components/AddCard';
import { from } from 'styles/media';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 80rem;
  padding: 0 1rem;
`;

export const SpaceCard = styled(DefaultSpace)``;

export const AddCard = styled(DefaultAddCard)``;

export const RowContainer = styled.div<{ $colored?: boolean }>`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4.0625rem;
  padding: ${({ $colored }) => ($colored ? '2rem 0' : '0')};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.ebonyClay};

  :first-child {
    margin-top: 5rem;
  }
  :last-child {
    margin-bottom: 5rem;
  }
  ${from.mobile} {
    margin-bottom: 5rem;
    padding: '5rem 0';
    :first-child {
      margin-top: 6.5rem;
    }
    :last-child {
      margin-bottom: 6.5rem;
    }
  }
`;

export const RowTextContainer = styled.div<{ $bottomAlign?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ $bottomAlign }) =>
    $bottomAlign ? 'flex-end' : 'baseline'};
  flex-wrap: wrap;
  margin-bottom: 0rem;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 2rem;
  margin-top: 1.5rem;
  width: 100%;
  height: 100%;

  ${from.tablet} {
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 1.5rem;
  }
`;

export const Title = styled(H3)`
  margin-bottom: 1.125rem;
  margin-top: 1.3125rem;
`;
