import styled from 'styled-components';
import { H5, Small } from 'components/Typography';
import DefaultButton from 'components/Button';
import DefaultInput from 'components/Input';
import { from } from 'styles/media';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30.625rem;
  width: 100%;
  padding: 2.5rem;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 12.5rem;

  ${from.tablet} {
    margin-top: 7.5rem;
  }
`;

export const Title = styled(H5)`
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
`;

export const FormButton = styled(DefaultButton)``;

export const Input = styled(DefaultInput)`
  width: 100%;
`;

export const RowForm = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  :last-child {
    margin-top: 2rem;
  }

  ${from.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const Text = styled(Small)``;
