import { AVAILABILITY_SPACE_FRAGMENT } from 'graphql/fragments/availabilitySpace';
import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const AVAILABILITY_SPACE = gql`
  query AvailabilitySpace(
    $startDate: String!
    $endDate: String!
    $spaceId: ID!
  ) {
    availabilitySpace(
      startDate: $startDate
      endDate: $endDate
      spaceId: $spaceId
    ) {
      ...AvailabilityParts
    }
  }
  ${AVAILABILITY_SPACE_FRAGMENT}
`;
