import React, { memo } from 'react';
import {
  Header,
  InfoCard,
  InfoTitle,
  InfoText,
  Invoices,
  PaperPlaneIcon,
  Section,
} from '../styles';
import useConnect from '../connect';
import DeferPayment from '../DeferPayment';

const ReservationPreCompleted = () => {

    const { isLoading, booking} = useConnect();
    if (isLoading || !booking) return null;
    return (
        <Section>
            <InfoCard>
                  <Header>
                    <PaperPlaneIcon />
                    <InfoTitle $isBlue>Reservada</InfoTitle>
                  </Header>
                  <InfoText>
                    ¡Hola! Hemos verificado tu reserva. Ahora solo falta el pago final. No te preocupes, el equipo de
                    Sedee se pondrá en contacto contigo para completar la reserva.{' '}
                  </InfoText>
                  <Invoices
                    preReserva={booking.prereservaInvoice?.url}
                    reserva={booking.reservaInvoice?.url}
                  />
            </InfoCard>

            <DeferPayment/>
        </Section>

    );
  };

export default memo(ReservationPreCompleted);



