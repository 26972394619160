import React from 'react';
import { Line, Dot } from './styles';

const Divider = () => {
  return (
    <Line>
      <Dot />
    </Line>
  );
};

export default Divider;
