import React, { FC, memo } from 'react';
import MobileTopBar from './Mobile';
import DesktopTopBar from './Desktop';
import useConnect from './connect';
import { Props } from './types';

const TopBar: FC<Props> = ({
  isVisible = true,
  isAuth = true,
  allowSearch = false,
  wide = false,
  ...props
}) => {
  const { isMobile } = useConnect();

  if (isMobile) {
    return (
      <MobileTopBar
        isVisible={isVisible}
        isAuth={isAuth}
        allowSearch={allowSearch}
        {...props}
      />
    );
  }

  return (
    <DesktopTopBar
      isVisible={isVisible}
      isAuth={isAuth}
      allowSearch={allowSearch}
      wide={wide}
      {...props}
    />
  );
};

export default memo(TopBar);
