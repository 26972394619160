import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export const USER_FRAGMENT = gql`
  fragment UserParts on UsersPermissionsUser {
    id
    username
    email
   	firstName
    lastName
    phoneNumber
    homeAddress
    city
    dni
    fiscalAddress
    companyName
    confirmed
    blocked
  }
`;