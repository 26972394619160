import useLocations from 'graphql/hooks/useLocations';
import useSpaceCategories from 'graphql/hooks/useSpaceCategories';

const useConnect = () => {
  const { loading: locationsLoading, locations } = useLocations({
    start: 0,
    limit: 8,
  });
  const {
    loading: spaceCategoriesLoading,
    spaceCategories,
  } = useSpaceCategories({
    start: 0,
    limit: 8,
  });

  return {
    isLoading: locationsLoading || spaceCategoriesLoading,
    locations,
    spaceCategories,
  };
};

export default useConnect;