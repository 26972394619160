import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { SPACE_BOOKING } from 'graphql/queries/booking';
import { Booking, BookingVariables } from 'graphql/generated/Booking';
import { normalizeBooking } from 'model/Booking';

const useBooking = (variables?: BookingVariables) => {
  const { loading: bookingLoading, data } = useQuery<Booking, BookingVariables>(
    SPACE_BOOKING,
    {
      variables
    }
  );
  const booking = useMemo(
    () => (data?.booking ? normalizeBooking(data.booking) : undefined),
    [data]
  );

  return {
    booking,
    bookingLoading,
  };
};

export default useBooking;
