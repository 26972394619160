import { useCallback, useState } from 'react';

const useLogic = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page);
    },
    [setCurrentPage]
  );

  return {
    currentPage,
    handleChangePage,
  };
};

export default useLogic;
