import styled from 'styled-components';
import { FiPlusCircle as DefaultPlusCircle } from 'react-icons/fi';
import { H3, H5, Overline } from 'components/Typography';
import DefaultSpace from 'components/Card/BookedSpace';
import DefaultAddCard from 'components/AddCard';
import { from } from 'styles/media';
import {Button} from "@material-ui/core";

export const Container = styled.div`
  min-height:100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 80rem;
  padding: 0 1rem;
`;

export const SpaceCard = styled(DefaultSpace)``;

export const AddCard = styled(DefaultAddCard)``;

export const RowContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.ebonyClay};

  :first-child {
    margin-top: 5rem;
  }
  :last-child {
    margin-bottom: 5rem;
  }
  ${from.mobile} {
    margin-bottom: 5rem;
    padding: 0rem 0;
    :first-child {
      margin-top: 6.5rem;
    }
    :last-child {
    }
  }
`;

export const RowTextContainer = styled.div<{ $bottomAlign?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ $bottomAlign }) =>
    $bottomAlign ? 'flex-end' : 'baseline'};
  flex-wrap: wrap;
  margin-bottom: 0rem;
`;

export const RowSubtitle = styled(H5)`
  font-weight: 600;
`;

export const Link = styled(Button)`
  color: ${({ theme }) => theme.colors.ebonyClay};
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const LinkIcon = styled(DefaultPlusCircle)`
  margin-right: 0.5813rem;
`;

export const LinkTextUppercase = styled(Overline)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 2rem;
  margin-top: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem;

  ${from.tablet} {
    grid-gap: 1.5rem;
    overflow-y: hidden;
  }
`;

export const Title = styled(H3)`
  margin-bottom: 1.125rem;
  margin-top: 1.3125rem;
`;
