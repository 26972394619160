import styled from 'styled-components';
import { H5, Body } from 'components/Typography';
import DefaultCheckbox from 'components/Checkbox';
import { from } from 'styles/media';

export const Container = styled.div`
  padding: 2rem;
  height: 100%;

  ${from.mobile} {
    padding: 2.5rem;
  }
`;

export const Title = styled(H5)`
  margin-bottom: 1rem;
`;

export const Text = styled(Body)``;

export const Checkbox = styled(DefaultCheckbox)``;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.05rem;
  letter-spacing: 4%;
`;
