import React, { FC, memo } from 'react';
import { Container, Link, Button, Fragment, Logo, Divider } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Menu: FC<Props> = ({
  isAuth,
  openMenu,
  handleOpenModal,
  handleCloseMenu,
}) => {
  const { handleLogout } = useConnect();

  return (
    <Container anchor="right" open={openMenu} onClose={handleCloseMenu}>
      <Logo />
      {isAuth ? (
        <Fragment>
          <Link
            label="Mis reservas"
            {...{ to: '/bookings' }}
            hasCheck={false}
          />
          {
            // <Link label="Favoritos" {...{ to: '/favorites' }} hasCheck={false} />
          }
          <Divider />
          <Link label="Mi perfil" {...{ to: '/profile' }} hasCheck={false} />
          <Link label="Ayuda" {...{ to: '/help' }} hasCheck={false} />
          <Divider />
          <Link
            label="Cerrar sesión"
            {...{ onClick: handleLogout }}
            hasCheck={false}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Link label="Ayuda" {...{ to: '/help' }} hasCheck={false} />
          <Button onClick={handleOpenModal}>Iniciar sesión</Button>
        </Fragment>
      )}
    </Container>
  );
};

export default memo(Menu);
