import React, {FC, useState} from 'react';
import {
    Section, Strong, SectionText, Container, TransferButton, SectionTitle
} from './styles';
import { Props } from './types';
import Dialog from "@material-ui/core/Dialog";
import {Button, CloseIcon, Header, ModalContent} from "../../../../components/Modal/styles";
import {MessageCircle} from "../../../Private/Booking/styles";
const MoreInfo: FC<Props> = () => {
    const [itsOpen, setItsOpen] = useState(false);
    const handleClose = () =>{
        setItsOpen(false)
    }
    const handleClick = () =>{
        setItsOpen(true)
    }
    return (
        <Container>
            <TransferButton onClick={handleClick}>
                MÁS INFO
                <MessageCircle/>
        </TransferButton>
        { itsOpen && (
            <ModalContent className="modal-ui">
            <Dialog
                open={itsOpen}
    onClose={handleClose}
    >
    <Header>
        <Button type="button" onClick={handleClose}>
        <CloseIcon />
        </Button>
        </Header>
            <Section>
                <SectionTitle>
                    Aplazar el pago
                </SectionTitle>
                <SectionText>
                    Para más información ponte en contacto con el equipo de Sedee en el siguiente correo <Strong>info@sedee.io</Strong>
                </SectionText>
            </Section>
        </Dialog>
    </ModalContent>
)}
    </Container>
);
};

export default MoreInfo;
