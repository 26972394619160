import styled, { css } from 'styled-components';
import DefaultCarousel from 'components/Carousel';
import { H5, Body, BodyMedium, Small, PSemi } from 'components/Typography';
import DefaultBulletButton from 'components/BulletButton';
import DefaultButton from 'components/Button';
import {ReactComponent as Avatar} from 'assets/icons/avatar.svg';
import { Link } from 'react-router-dom';
import { from } from 'styles/media';

export const Section = styled.div<{ $top?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ $top }) =>
    $top &&
    css`
      margin-top: 0.5rem;
    `}
`;

export const Row = styled.div<{ $top?: boolean; $mediumTop?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ $mediumTop }) =>
    $mediumTop &&
    css`
      margin-top: 0.25rem;
    `}

  ${({ $top }) =>
    $top &&
    css`
      margin-top: 0.5rem;
    `}
`;

export const Column = styled.div<{ $height?: boolean,$top?: boolean; $mediumTop?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({$height})=>
    $height &&
    css`
      height: 7.34rem;
    `}

  ${({ $mediumTop }) =>
    $mediumTop &&
    css`
      margin-top: 0.25rem;
    `}

  ${({ $top }) =>
    $top &&
    css`
      margin-top: 0.5rem;
    `}
`;

export const Button = styled(DefaultButton)`
  margin-top: 1.5rem;
  :last-child {
    margin-top: 1rem;
  }
`;

export const ButtonSection = styled.div`
  display: none;
  ${from.laptop}{
    display: block;  
  }
`

export const CancelButton = styled.button`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.black};
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  border: 0;
  background: none;
  padding: 0;
  outline: none;
  margin-top: 1.5rem;
  cursor: pointer;
`;

export const LinkText = styled(Link)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.black};
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  border: 0;
  background: none;
  padding: 0;
  outline: none;
  margin-top: 1.5rem;
  cursor: pointer;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: ${({ theme }) => theme.colors.gray300};
  margin: 1.5rem 0;
`;

export const CarouselWrapper = styled.div`
  position: relative;
  border-radius: 0.3125rem 0.3125rem 0 0;
  overflow: hidden;
`;

export const Carousel = styled(DefaultCarousel)``;

export const PrevButton = styled(DefaultBulletButton)`
  position: absolute;
  top: 45%;
  left: 1rem;
  opacity: 0;
`;

export const NextButton = styled(DefaultBulletButton)`
  position: absolute;
  top: 45%;
  right: 1rem;
  opacity: 0;
`;

export const Content = styled.div`
  width: 100%;
  position: relative;
  padding: 1rem 1.5rem 2.5rem 1.5rem;
  border-radius: 0 0 0.3125rem 0.3125rem;
  min-height: 7.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AvatarIcon = styled(Avatar)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;

`;

export const Title = styled(H5)`
  font-size: 1rem;
`;

export const BodyText = styled(Body)`
  color: ${({ theme }) => theme.colors.gray700};
`;

export const BodyMediumText = styled(BodyMedium)`
  color: ${({ theme }) => theme.colors.gray700};
`;

export const BodyMediumDarkText = styled(BodyMedium)`
  color: ${({ theme }) => theme.colors.black};
`;

export const SmallText = styled(Small)`
  color: ${({ theme }) => theme.colors.gray700};
`;

export const PSemiText = styled(PSemi)`
  color: ${({ theme }) => theme.colors.black};
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1.5rem;
`;

export const Component = styled.div`
  text-decoration: none;
  border-radius: 0.3125rem;
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.ebonyClay};
  box-shadow: 0rem 0.3125rem 1.25rem rgba(33, 35, 48, 0.05);
  transition: 0.3s;
  display: block;
  :hover {
    ${PrevButton}, ${NextButton} {
      opacity: 1;
    }
  }

  ${from.tablet} {
    width: 25.6875rem;
  }
`;
