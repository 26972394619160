import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';

export const LinkOverlay = styled(DefaultLink)`
  cursor: pointer;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export default LinkOverlay;
