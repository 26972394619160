import styled from 'styled-components';
import { H4, Body } from 'components/Typography';

export const EmptyContainer = styled.div`
  margin-top: 1.8438rem;
  margin-bottom: 24.875rem;
`;

export const EmptyTitle = styled(H4)`
  margin-bottom: 0.25rem;
`;

export const Subtitle = styled(Body)``;
