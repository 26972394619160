import { gql } from '@apollo/client';
import { USER_FRAGMENT } from 'graphql/fragments/user';

/* eslint-disable import/prefer-default-export */

export const UPDATE_PROFILE = gql`
  mutation UpdateUser($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        ...UserParts
      }
    }
  }
  ${USER_FRAGMENT}
`;
