import styled from 'styled-components';

export const Dot = styled.span`
  margin: 0 1.375rem;
  flex: 0 1 auto;
  height: 0.4375rem;
  width: 0.4375rem;
  border: 0.0655rem solid ${({ theme }) => theme.colors.gray700};
  border-radius: 50%;
`;

export const Line = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  ::before,
  ::after {
    content: '';
    flex: 1 1 auto;
    border-bottom: solid 0.0655rem ${({ theme }) => theme.colors.gray700};
  }
`;