import React, { FC, memo } from 'react';
import { Marker } from 'react-map-gl';
import { IconBox, MarkerIcon } from './styles';
import { Props } from './types';

const MarkerComponent: FC<Props> = ({ latitude, longitude, ...props }) => {
  const size = 35;

  return (
    <Marker
      draggable={false}
      offsetTop={0}
      offsetLeft={0}
      latitude={latitude}
      longitude={longitude}
      {...props}
    >
      <IconBox style={{ transform: `translate(${-size / 2}px,${-size}px)` }}>
        <MarkerIcon />
      </IconBox>
    </Marker>
  );
};

export default memo(MarkerComponent);
