import { gql } from '@apollo/client';
import { SPACE_FRAGMENT } from 'graphql/fragments/space';
import { BOOKING_FRAGMENT } from 'graphql/fragments/booking';

/* eslint-disable import/prefer-default-export */

export const SPACE_BOOKING = gql`
  query Booking($id: ID!) {
    booking(id: $id) {
      ...BookingParts
      space {
        ...SpaceParts
      }
    }
  }
  ${BOOKING_FRAGMENT}
  ${SPACE_FRAGMENT}
`;