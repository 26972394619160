import styled from 'styled-components';
import { H4, H5, Small } from 'components/Typography';
import DefaultButton from 'components/Button';
import { from } from 'styles/media';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 6.25rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 39.25rem;
  width: 100%;
  padding: 1rem;
  margin-top: 2.5rem;

  ${from.tablet} {
    margin-left: 5.75rem;
    margin-top: 2.5rem;
  }
`;

export const Row = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 80rem;
  padding: 0 1rem;
`;

export const Title = styled(H4)`
  display: none;

  ${from.mobile} {
    display: block;
    margin-bottom: 1.5rem;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

export const SectionTitle = styled(H5)`
  font-weight: 600;
  margin-bottom: 2rem;
`;

export const Text = styled(Small)`
  margin-top: 1rem;
`;

export const Button = styled(DefaultButton)`
  width: 19.4375rem;
  margin-top: 1.5rem;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray300};
  margin: 3.5rem 0;
`;
