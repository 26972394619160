import { from, useMediaQuery } from 'styles/media';

const useConnect = () => {
  const isMobile = !useMediaQuery(from.mobile);

  return {
    isMobile,
  };
};

export default useConnect;