import { gql } from '@apollo/client';
import { LOCATION_FRAGMENT } from 'graphql/fragments/location';

/* eslint-disable import/prefer-default-export */

export const LOCATIONS = gql`
  query Locations($limit: Int, $start: Int, $sort: String) {
    customLocations(limit: $limit, start: $start, sort: $sort) {
      count
      locations {
        ...LocationParts
      }
    }
  }
  ${LOCATION_FRAGMENT}
`;
