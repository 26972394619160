import styled from 'styled-components';
import DefaultPagination from 'components/Pagination';

export const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 5.375rem;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Pagination = styled(DefaultPagination)``;