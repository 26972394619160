import React, { FC, memo } from 'react';
import { Container, Content, Text, Icon } from './styles';
import { Props } from './types';

const AddCard: FC<Props> = ({ to, ...props }) => {
  return (
    <Container to={to} {...props}>
      <Content>
        <Text>Añadir</Text>
        <Icon />
      </Content>
    </Container>
  );
};

export default memo(AddCard);
