import { useEffect, useState } from 'react';

const keysOf = <T extends object>(o: T) => Object.keys(o) as (keyof typeof o)[];

export const sizes = {
  mobile: 576,
  tablet: 768,
  laptop: 992,
  desktop: 1200,
  largeScreen: 1328,
} as const;

const minWidthQuery = (width: number) => `@media (min-width: ${width}px)`;

export const from = keysOf(sizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: minWidthQuery(sizes[key]),
  }),
  {} as { [key in keyof typeof sizes]: string }
);

export const useMediaQuery = (query: string): boolean => {
  const mediaQuery = query.replace('@media', '').trim();

  const [match, setMatch] = useState(
    () => window.matchMedia(mediaQuery).matches
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);

    const handleChange = (event: MediaQueryListEvent) =>
      setMatch(event.matches);

    mediaQueryList.addListener(handleChange);

    return () => mediaQueryList.removeListener(handleChange);
  }, [mediaQuery]);

  return match;
};
