import useLocations from 'graphql/hooks/useLocations';

const useConnect = () => {
  const { loading: isLoading, locations } = useLocations();

  return {
    isLoading,
    locations,
  };
};

export default useConnect;
