import styled from 'styled-components';
import { H1, Subtitle as DefaultSubtitle } from 'components/Typography';
import DefaultSearch from 'containers/Shared/Search';

import { from } from 'styles/media';

export const Container = styled.div`
  position: relative;
  display: flex;
  place-items: center;
  flex-direction: column;
  width: 100%;
  height: 37rem;
  margin-bottom: 6.5rem;

  ${from.tablet} {
    height: 27rem;
  }
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

export const Content = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Title = styled(H1)`
  max-width: 21.4375rem;
  margin-bottom: 1.5rem;

  ${from.mobile} {
    max-width: 30rem;
    margin-bottom: 2rem;
  }

  ${from.tablet} {
    max-width: 41.875rem;
    margin-bottom: 2rem;
  }
`;

export const Subtitle = styled(DefaultSubtitle)``;

export const SearchCard = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  padding: 2rem 1rem;
  margin: 0 1rem -3rem 1rem;

  box-shadow: 0rem 1rem 3.9375rem rgba(33, 35, 48, 0.0168519),
    0rem 0.6482rem 2.306rem rgba(33, 35, 48, 0.0274815),
    0rem 0.3852rem 1.2542rem rgba(33, 35, 48, 0.035),
    0rem 0.2rem 0.6398rem rgba(33, 35, 48, 0.0425185),
    0rem 0.0815rem 0.3208rem rgba(33, 35, 48, 0.0531481),
    0rem 0.0185rem 0.1549rem rgba(33, 35, 48, 0.07);

  ${from.tablet} {
    padding: 2rem 2.5rem;
    margin: 0 1rem -4.0625rem 1rem;
  }
`;

export const Search = styled(DefaultSearch)``;
