import { gql } from '@apollo/client';
import { SPACE_FRAGMENT } from 'graphql/fragments/space';

/* eslint-disable import/prefer-default-export */

export const SPACE_FAVORITES = gql`
  query Favorites($sort: String, $limit: Int, $start: Int, $where: JSON) {
    favorites(sort: $sort, limit: $limit, start: $start, where: $where) {
      space {
        ...SpaceParts
      }
    }
  }
  ${SPACE_FRAGMENT}
`;
