import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export const today = new Date();

export const formatDate = (date: Date | string, formatConstant: string) => {
  if (typeof date === 'string') {
    return format(new Date(date), formatConstant, { locale: es });
  }
  return format(date, formatConstant, { locale: es });
};

export const API_DAY_FORMAT = 'yyyy-MM-dd';
export const toApiDayFormat = (date: Date | string) =>
  formatDate(date, API_DAY_FORMAT);

export const queryDayFormat = (date: string | null) => {
  if (date) {
    return new Date(date);
  }
  return today;
}

export const INTERFACE_DAY_FORMAT = 'd MMM yyyy';
export const toInterfaceDayFormat = (date: Date | string) =>
  formatDate(date, INTERFACE_DAY_FORMAT);
