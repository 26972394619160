import styled from 'styled-components';
import { PSemi, Overline } from 'components/Typography';

export const Container = styled.div`
  width: 17.5rem;
  height: 3.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.royalBlue};
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 5.25rem;
`;

export const Time = styled(PSemi)``;

export const Unit = styled(Overline)`
  margin-top: 0.0625rem;
`;

export const Divider = styled.div`
  width: 0.0625rem;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.hawkesBlue};
  margin: 0 0.1875rem;
`;
