import { useCallback } from 'react';
import useAuth from 'graphql/hooks/useAuth';

const useConnect = () => {
  const { logout } = useAuth();

  const handleLogout = useCallback(() => logout(), [logout]);

  return {
    handleLogout,
  };
};

export default useConnect;
