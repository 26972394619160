import React, { FC } from 'react';
import {
  Container,
  Step,
  Indicator,
  DateIndicator,
  CheckIcon,
  CancelledIcon,
  Content,
} from './styles';
import { Props } from './types';

const Stepper: FC<Props> = ({
  steps,
  activeStep,
  completed,
  pending,
  cancelled,
  reservation,
  ...props
}) => {
  return (
    <Container {...props}>
        {steps.map((step) => (
          <Step
            key={step.label}
            $active={activeStep}
            $completed={completed}
            $pending={pending}
            $cancelled={cancelled}
            $reservation={reservation}
          >
            {cancelled ? (
                <Content>
                  <Indicator>Cancelada</Indicator>
                  <DateIndicator>2023</DateIndicator>
                </Content>
            ) : (
              <Content>
                <Indicator>{step.label}</Indicator>
                <DateIndicator>2023</DateIndicator>
              </Content>
            )}
            <CheckIcon />
            <CancelledIcon />
          </Step>
        ))}
    </Container>
  );
};

export default Stepper;
