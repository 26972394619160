import React from 'react';
import {
    Container,
    Title,
} from './styles';
import {Header, InfoContainer, InfoText, InfoTitle} from "../Cookies/styles";

const WhatItsSedee = () => {
    return (
        <Container>
            <Header>
                <Title>Qué es SEDEE</Title>
            </Header>
            <InfoContainer>
                <InfoText>
                    SEDEE es la primera plataforma donde encontrar Venues para eventos, con disponibilidad en tiempo real, visor de precios y posibilidad de hacer la reserva de forma inmediata.
                </InfoText>
                <InfoText>
                    Si quieres celebrar y organizar un evento, congreso, boda o fiesta puedes encontrar la guía más completa de espacios únicos, con información completa y cuidada de cada uno
                </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                    Os preguntareis ¿qué es un Venue?
                </InfoTitle>
                <InfoText>
                    Primero: ¿sabes lo que quiere decir la palabra?
                </InfoText>
                <InfoText>
                    Internacionalmente se usa cada día, aunque en España aún no es tan familiar.
                </InfoText>
                <InfoText>
                    Es un espacio, sitio o lugar donde se puede realizar un evento de cualquier naturaleza. Eventos particulares, de empresa, congresos y convenciones, bodas , fiestas ,etc.
                </InfoText>
                <InfoText>
                    Los venues a los que nos referimos son: Fincas, Castillos, Hoteles , Restaurantes, Parques temáticos, Jardines, Terrazas, Museos, Bodegas, espacios emblemáticos, naves industriales, espacios multiusos y un largo etc...
                </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                    ¿Por qué SEDEE?
                </InfoTitle>
                <InfoText>
                    Porque sabemos que la elección del lugar donde celebrar un evento es determinante para tener éxito.
                </InfoText>
                <InfoText>
                    El equipo de SEDEE tiene 20 años de experiencia en el sector de la organización de eventos. Hemos hecho la mejor selección de venues especiales, lo hemos hecho bajo los más altos estándares de calidad y hemos acompañado a muchos de los espacios trabajando la mejora continua para adaptar sus procesos y servicios a las exigencias del mercado.
                </InfoText>
                <InfoText>
                    A través del Buscador de espacios de SEDEE te ayudamos a tomar la decisión más acertada , encontrarás una selección de espacios singulares completísima con toda la información al detalle que necesitas para elegir un venue especial para tu evento.
                </InfoText>
                <InfoText>
                    Podrás ver presentaciones de fotos, videos, menús , presupuestos , planos de acceso, servicios que ofrecen , características del espacio, y sobre todo, las referencias que son de mucha ayuda en la toma de decisiones sobre cual es el espacio más adecuado.
                </InfoText>
                <InfoText>
                    Si lo que lees y ves te gusta, pero por algún motivo el Venue no tiene fecha o precio disponible, podrás solicitarnos dicha información sin compromiso y te contestaremos en menos de 24 H.
                </InfoText>
                <InfoText>
                    Pedir presupuestos a través de SEDEE es completamente gratuito.
                </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                    Puedes contar con SEDEE
                </InfoTitle>
                <InfoText>
                    No creas que por ser una web online no hay gente detrás trabajando para que la elección de tu venue sea un éxito. Queremos ser transparentes, así que si tienes dudas, necesitas información sobre el servicio, o quieres detalles sobre Venues, escríbenos a: info@sedee.io
                </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                    ¿Cuál es nuestro objetivo?
                </InfoTitle>
                <InfoText>
                    Facilitar el proceso de búsqueda, selección y reserva de Venues para eventos.
                </InfoText>
                <InfoText>
                    Además nos preocupamos de que la organización sea impecable, para que la experiencia sea totalmente satisfactoria
                </InfoText>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                    Nuestros Servicios.
                </InfoTitle>
                <InfoText>
                    Servicio personalizado de búsqueda de espacios y organización del evento. Si no encuentras lo que necesitas en el buscador no te preocupes, nuestros equipos te asesoran gratuitamente por teléfono o por chat hasta que encuentres el venue perfecto para tu evento.
                </InfoText>
            </InfoContainer>
        </Container>
    );
};

export default WhatItsSedee;
