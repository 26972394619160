import styled, { css } from 'styled-components';
import { from } from 'styles/media';
import {
  FiChevronLeft as ArrowLeftIcon,
  FiChevronRight as ArrowRightIcon,
} from 'react-icons/fi';
import BulletButton from 'components/BulletButton';
import { NumberProps } from './types';

export const Container = styled.div`
  ${from.tablet} {
    display: flex;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Number = styled.button<NumberProps>`
  color: ${({ theme }) => theme.colors.gray700};
  width: 2.375rem;
  height: 2.375rem;
  background-color: transparent;
  border: 0;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3125rem;
  border-radius: 50%;
  &:focus {
    outline: none;
  }
  :hover:not(:disabled) {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
  }

  ${({ $current }) =>
    $current &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.gray400};
      border-radius: 50%;
    `};
`;
export const FullRange = styled.div<NumberProps>`
  color: ${({ theme }) => theme.colors.gray700};
  width: 2.375rem;
  height: 2.375rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3125rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowLeft = styled(ArrowLeftIcon)`
  width: 0.9375rem;
`;

export const ArrowRight = styled(ArrowRightIcon)`
  width: 0.9375rem;
`;

export const IconButton = styled(BulletButton)`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  padding: 0;
  width: 2.375rem;
  height: 2.375rem;

  :first-child {
    margin-right: 1rem;
  }
  :last-child {
    margin-left: 1rem;
  }
`;
