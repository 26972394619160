/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_BOOKING_STATE {
  archivado = "archivado",
  pre_reserva_aprobada = "pre_reserva_aprobada",
  pre_reserva_confirmada = "pre_reserva_confirmada",
  pre_reserva_solicitada = "pre_reserva_solicitada",
  reserva_completada = "reserva_completada",
  reserva_confirmada = "reserva_confirmada",
  reserva_en_proceso = "reserva_en_proceso",
  reserva_pago_enviado = "reserva_pago_enviado",
  reserva_solicitada = "reserva_solicitada",
}

export interface InputID {
  id: string;
}

export interface UsersPermissionsLoginInput {
  identifier: string;
  password: string;
  provider?: string | null;
}

export interface UsersPermissionsRegisterInput {
  username: string;
  email: string;
  password: string;
  phoneNumber: string;
  check?: boolean | null;
}

export interface createMeBookingInput {
  startDate: string;
  endDate: string;
  spaceId: string;
}

export interface editUserInput {
  username?: string | null;
  email?: string | null;
  provider?: string | null;
  password?: string | null;
  resetPasswordToken?: string | null;
  confirmationToken?: string | null;
  confirmed?: boolean | null;
  blocked?: boolean | null;
  role?: string | null;
  favorites?: (string | null)[] | null;
  bookings?: (string | null)[] | null;
  firstName?: string | null;
  lastName?: string | null;
  homeAddress?: string | null;
  phoneNumber?: string | null;
  city?: string | null;
  dni?: string | null;
  fiscalAddress?: string | null;
  companyName?: string | null;
  created_by?: string | null;
  updated_by?: string | null;
}

export interface updateUserInput {
  where?: InputID | null;
  data?: editUserInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
