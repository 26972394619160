import { useCallback, useEffect } from 'react';
import useUserActions from 'graphql/hooks/useUserActions';
import { ResetPasswordVariables } from 'graphql/generated/ResetPassword';
import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import useQueryParams from 'utils/useQueryParams';

const useConnect = () => {
  const { isLoading, updatePassword } = useUserActions();
  const { query } = useQueryParams();
  const { code } = query;
  const history = useHistory();

  useEffect(() => {
    if (!code) {
      history.push('/');
    }
  }, [history, code]);

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };
  type FormValues = typeof initialValues;

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
    ) => {
      try {
        const input: ResetPasswordVariables = {
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
          code: code || '',
        };
        await updatePassword(input);
        history.push('/');
      } catch (error) {
        setFieldError(
          'passwordConfirmation',
          error.fields.exception.data.message[0].messages[0].message
        );
      }
    },
    [updatePassword, history, code]
  );

  return {
    isLoading,
    handleSubmit,
    initialValues,
  };
};

export default useConnect;
