import React, { FC, memo } from 'react';
import Tag from 'components/Tag';
import { ENUM_BOOKING_STATE as states } from 'graphql/generated/globalTypes';
import { Props } from './types';

const StateTag: FC<Props> = ({ state, isPrevious }) => {
  if (isPrevious) {
    if (state === 'isCancelled') return <Tag type="disabled">Cancelada</Tag>;
    if (states.reserva_pago_enviado===state) {
      return <Tag type="colored">Confirmada</Tag>;
    }
    if (states.reserva_confirmada===state || states.reserva_en_proceso===state) {
      return <Tag type="outlined">Reserva</Tag>;
    }
    if (states.reserva_completada===state) {
      return <Tag type="solid">Completada</Tag>;
    }
    return null;
  }
  return null;

};

export default memo(StateTag);
