import { BOOKING_FRAGMENT } from 'graphql/fragments/booking';
import { gql } from '@apollo/client';
import {REDSYS_FRAGMENT} from "../fragments/redsysForm";
/* eslint-disable import/prefer-default-export */

export const CREATE_BOOKING = gql`
  mutation CreateBooking($input: createMeBookingInput!) {
    createMeBooking(input: $input) {
      ...BookingParts
    }
  }
  ${BOOKING_FRAGMENT}
`;

export const BOOKING_TOTAL_PAYMENT = gql`
  mutation changeTotalPayment($id: ID!) {
    changeTotalPayment(id: $id) {
      ...BookingParts
    }
  }
  ${BOOKING_FRAGMENT}
`;

export const PAY_BOOKING = gql`
  mutation PayBooking($id: ID!) {
    payBooking(id: $id) {
      ...RedsysFormFragment
    }
  }
  ${REDSYS_FRAGMENT}
`;

export const BOOKING_CONFIRMED = gql`
  mutation bookingConfirmed($id: ID!) {
    bookingConfirmed(id: $id) {
      ...BookingParts
    }
  }
  ${BOOKING_FRAGMENT}
`;

export const PAY_IN_PROGRESS = gql`
  mutation payInProgress($id: ID!) {
    payInProgress(id: $id){
      ...BookingParts
    }
  }${BOOKING_FRAGMENT}
`;

export const TRANSFER_BOOKING = gql`
  mutation transferBooking($id: ID!) {
    transferBooking(id: $id){
      ...BookingParts
    }
  }${BOOKING_FRAGMENT}
`;


export const CANCEL_BOOKING = gql`
  mutation CancelBooking($id: ID!, $message: String) {
    cancelBooking(id: $id, message: $message) {
      ...BookingParts
    }
  }
  ${BOOKING_FRAGMENT}
`;
