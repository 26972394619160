import { useMediaQuery, from } from 'styles/media';
import useQuery from 'utils/useQueryParams';
import useAvailability from 'graphql/hooks/useAvailability';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { SelectType } from 'model/global';

const useConnect = () => {
  const ITEMS_PER_PAGE = 16;
  const isMobile = !useMediaQuery(from.tablet);
  const { query, setQuery, filterCount } = useQuery();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [start, setStart] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const {
    location,
    startDate,
    endDate,
    spaceCategory,
    minPrice,
    maxPrice,
    capacity,
    reducedMovility,
    sort,
  } = query;
  const options = [
    { label: 'Ordenar por', value: 'default' },
    { label: 'Precio más alto', value: 'price:DESC' },
    { label: 'Precio más bajo', value: 'price:ASC' },
  ];

  useEffect(() => {;
  }, [setQuery, start]);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleUpdatePage = useCallback(
    (newPage: number) => {
      setCurrentPage(newPage);
      setStart(newPage * ITEMS_PER_PAGE - ITEMS_PER_PAGE);
      // setQuery({ start, limit: ITEMS_PER_PAGE });
    },
    []
  );

  const handleOrderChange = useCallback(
    (event: ChangeEvent<SelectType>) => {
      const order = event.target.value as string;
      setQuery({ sort: order === 'default' ? undefined : order });
    },
    [setQuery]
  );

  const { loading: loadingTemp, spaces: tempCount } = useAvailability({
    location,
    startDate,
    endDate,
    spaceCategory,
    minPrice,
    maxPrice,
    capacity,
    reducedMovility,
    sort,
  });

  const { loading, spaces } = useAvailability(query);

  const formatTitle = (title?: string | null) => {
    if (title) {
      const res = (title.charAt(0).toUpperCase() + title.slice(1))
        .split('-')
        .join(' ');
      return res;
    }
    return title;
  };

  const title =
    formatTitle(location) ||
    formatTitle(spaceCategory && spaceCategory[0]) ||
    'Espacios disponibles';

  return {
    isLoading: loading || loadingTemp,
    title,
    isMobile,
    spaces,
    tempCount,
    handleOrderChange,
    sort,
    currentPage,
    handleUpdatePage,
    ITEMS_PER_PAGE,
    open,
    handleOpenModal,
    handleCloseModal,
    start,
    filterCount,
    options,
  };
};

export default useConnect;
