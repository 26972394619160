import React, { FC } from 'react';

import {
  Container,
  Image,
  Content,
  Title,
  Subtitle,
  SearchCard,
  Search,
} from './styles';
import { Props } from './types';
import background from './background.png';

const Hero: FC<Props> = ({
  title = 'Encuentra el espacio perfecto para tu evento',
  subtitle = 'Disponibilidad y precio, en tiempo real',
}) => {
  return (
    <Container>
      <Image src={background} />

      <Content>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Content>

      <SearchCard>
        <Search showLabels />
      </SearchCard>
    </Container>
  );
};

export default Hero;
