import { gql } from '@apollo/client';
import { ME_FRAGMENT } from 'graphql/fragments/me';

/* eslint-disable import/prefer-default-export */

export const UPDATE_PASSWORD = gql`
  mutation ResetPassword(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      jwt
      user {
        ...MeParts
      }
    }
  }
  ${ME_FRAGMENT}
`;