import useBookingActions from 'graphql/hooks/useBookingActions';
import { useCallback, useState } from 'react';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  done: yup.boolean().required('Debe aceptar'),
});

type Args = {
  id: string;
  isPrebooking: boolean;
};

const useConnect = ({ id, isPrebooking }: Args) => {
  const { payBooking } = useBookingActions();
  const initialValues = {
    done: false,
    id,
  };
  type FormValues = typeof initialValues;
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
          await payBooking({
            id: values.id,
          });
      } catch (error) {
        console.log(error);
      }
    },
    [payBooking]
  );

  return {
    handleSubmit,
    initialValues,
    validationSchema,
    isOpen,
    openModal,
    closeModal,
  };
};

export default useConnect;
