import { useParams, useHistory } from 'react-router-dom';
import { useCallback, useState } from 'react';
import useBooking from 'graphql/hooks/useBooking';
import { ENUM_BOOKING_STATE as states } from 'graphql/generated/globalTypes';
import { validationSchema } from './constants';
import useBookingActions from "../../../graphql/hooks/useBookingActions";
const steps = [
  { label: 'Reserva' },
  { label: 'Confirmada' },
];


const useConnect = () => {

  const { totalPayment, transferBooking } = useBookingActions();
  const { bookingId } = useParams<{ bookingId: string }>();
  const { bookingLoading, booking } = useBooking({ id: bookingId });
  const { push } = useHistory();
  const [itsOpen, setItsOpen] = useState(false);
  let date = new Date(booking?.created_at);
  date.setHours(date.getHours()+48);
  const [confirmationCompleted, setConfirmationCompleted] = useState(false);
  const isCompleted =
    booking &&
    booking.state === states.reserva_completada;

    const initialValues = {
      bookingId,
    };
  type FormValues = typeof initialValues;
  const handleChangeTotal = useCallback(
      async () => {
        try {
          await totalPayment({
            id: bookingId
          });
        } catch (error) {
          console.log(error);
        }
      },
      [totalPayment, bookingId]
  );
  const handleGoBack = useCallback(() => {
    push('/');
  }, [push]);

  const handleGoSearch = useCallback(() => {
    push('/search');
  }, [push]);

  const handleBookingAgain = useCallback(() => {
    push(`/spaces/${booking?.space?.slug}`);
  }, [booking, push]);

  const handleClose = () =>{
    setItsOpen(false)
  }

  const handleSubmitTransfer = useCallback(
      async (values: FormValues) => {
        try {
          await transferBooking({
            id: values.bookingId
          });

          setItsOpen(true)
        } catch (error) {
          console.log(error);
        }
      },
      [transferBooking]
  );

  return {
    isLoading: bookingLoading,
    booking,
    steps,
    handleGoBack,
    handleGoSearch,
    confirmationCompleted,
    setConfirmationCompleted,
    handleSubmitTransfer,
    handleChangeTotal,
    date,
    states,
    isCompleted,
    handleBookingAgain,
    validationSchema,
    initialValues,
    itsOpen,
    handleClose
  };
};

export default useConnect;
