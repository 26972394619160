import React, { FC, memo } from 'react';
import EmptyImage from 'assets/images/Card/emptyCards.svg';

import {
  Component,
  ImageWrapper,
  Image,
  Content,
  Section,
  Text,
  Title,
  Price,
} from './styles';
import { LinkOverlay } from '../styles';
import { Props } from './types';

const City: FC<Props> = ({
  linkProps,
  title,
  spacesCount,
  image,
  price,
  ...props
}) => {
  return (
    <Component {...props}>
      {linkProps && <LinkOverlay {...linkProps} />}
      <ImageWrapper>
        {image ? (
          <Image src={process.env.REACT_APP_API_URL+image.src} alt={image.alt} />
        ) : (
          <Image src={EmptyImage} alt={title} />
        )}
      </ImageWrapper>
      <Content>
        <Section>
          <Title>{title}</Title>
          <Text>{spacesCount} espacios</Text>
        </Section>
        {(price || price === 0) && (
          <Section>
            <Price>Desde {price}€</Price>
          </Section>
        )}
      </Content>
    </Component>
  );
};

export default memo(City);
