import React from 'react';
import { Formik, Form } from 'formik';
import Button from 'components/Button';
import useConnect from './connect';
import { Props } from './types';

const PayButton = ({ id }: Props) => {
  const {
      handleSubmit,
      initialValues,
  } = useConnect({ id });

  return (
    <>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          <Form noValidate style={{ height: '100%', width: '100%' }}>
              <Button
                type="submit"
                size="big"
                fullWidth
                variant="primary"
              >
                Aceptar
              </Button>
          </Form>
        </Formik>
    </>
  );
};

export default PayButton;
