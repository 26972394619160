import React, { FC, memo } from 'react';
import EmptyImage from 'assets/images/Card/emptyCards.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  FiChevronLeft as PrevIcon,
  FiChevronRight as NextIcon,
  FiMaximize as MaximizeIcon,
} from 'react-icons/fi';
import {
  Component,
  ImageWrapper,
  Image,
  PrevButton,
  NextButton,
  Dot,
  OpenButton,
} from './styles';
import { Props } from './types';

const Carousel: FC<Props> = ({
  images,
  hasOpenButton = false,
  handleOpenGallery,
  ...props
}) => {
  if (!images || images.length === 0) {
    return (
      <ImageWrapper>
        <Image src={EmptyImage} />
      </ImageWrapper>
    );
  }

  return (
    <Component
      {...props}
      emulateTouch
      showStatus={false}
      showThumbs={false}
      {...(images.length > 1 && {
        infiniteLoop: true,
      })}
      {...(images.length === 1 && {
        showIndicators: false,
      })}
      renderArrowPrev={(onClickHandler, hasPrev) =>
        hasPrev && (
          <PrevButton
            type="button"
            onClick={onClickHandler}
            icon={<PrevIcon />}
          />
        )
      }
      renderArrowNext={(onClickHandler, hasNext) =>
        hasNext && (
          <NextButton
            type="button"
            onClick={onClickHandler}
            icon={<NextIcon />}
          />
        )
      }
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        return (
          <Dot
            $isCurrentPhoto={isSelected}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
    >
      {images.slice(0, 6).map((image) => (
        <ImageWrapper key={image.alt}>
          {hasOpenButton && (
            <OpenButton icon={<MaximizeIcon />} onClick={handleOpenGallery} />
          )}
          <Image src={process.env.REACT_APP_API_URL+image.src} alt={image.alt} loading="lazy" />
        </ImageWrapper>
      ))}
    </Component>
  );
};

export default memo(Carousel);
