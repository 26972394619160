import { useCallback, useMemo } from 'react';
import useUserActions from 'graphql/hooks/useUserActions';
import useUser from 'graphql/hooks/useUser';
import useMe from 'graphql/hooks/useMe';
import { UpdateUserVariables } from 'graphql/generated/UpdateUser';

const useConnect = () => {
  const { isLoading: updateLoading, updateProfile } = useUserActions();
  const { loading: meLoading, me } = useMe();
  const { loading: userLoading, user } = useUser({ id: me?.id as string });

  const initialValues = useMemo(() => {
    return {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      homeAddress: user?.homeAddress || '',
      city: user?.city || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
        dni: user?.dni || '',
        fiscalAddress: user?.fiscalAddress || '',
        companyName: user?.companyName || '',
    };
  }, [user]);

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      const updateArgs: UpdateUserVariables = {
        input: {
          where: me && me.id ? { id: me.id } : null,
          data: values
            ? {
                firstName: values.firstName ? values.firstName : null,
                lastName: values.lastName ? values.lastName : null,
                homeAddress: values.homeAddress ? values.homeAddress : null,
                email: values.email ? values.email : null,
                city: values.city ? values.city : null,
                phoneNumber: values.phoneNumber ? values.phoneNumber : null,
                  dni: values.dni ? values.dni : null,
                  fiscalAddress: values.fiscalAddress ? values.fiscalAddress : null,
                  companyName: values.companyName ? values.companyName : null,
              }
            : null,
        },
      };

      await updateProfile(updateArgs);
    },
    [updateProfile, me, initialValues]
  );

  return {
    isLoading: updateLoading || userLoading || meLoading,
    handleSubmit,
    user,
    initialValues,
  };
};

export default useConnect;
