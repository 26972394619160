import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { PSemi, Overline } from 'components/Typography';
import DefaultBaseSelect from 'components/BaseSelect';
import DefaultOption from 'components/Option';
import { FiUser, FiSettings, FiBell } from 'react-icons/fi';
import { from } from 'styles/media';

export const Select = styled(DefaultBaseSelect)``;

export const Link = styled(DefaultOption)``;

export const Mobile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 1rem;
`;

export const Container = styled.div`
  min-width: 33.1875rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: sticky;
  top: 0;
  padding-top: 3rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 37.5rem;
  display: flex;
  flex-direction: column;
  padding: 0 2.5rem 3rem 5rem;
  border-right: 0.0625rem solid ${({ theme }) => theme.colors.gray300};
`;

export const Title = styled(Overline)`
  color: ${({ theme }) => theme.colors.gray700};
  margin-bottom: 1rem;

  ${from.mobile} {
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }
`;

export const MenuLink = styled(NavLink)`
  width: 100%;
  height: 3.625rem;
  padding: 1rem;
  border-radius: 0.3125rem;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  &:hover {
    background-color: ${({ theme }) => theme.colors.hawkesBlue400};
    color: ${({ theme }) => theme.colors.royalBlue};
  }
  &.selected {
    background-color: ${({ theme }) => theme.colors.hawkesBlue400};
    color: ${({ theme }) => theme.colors.royalBlue};
  }
`;

export const Text = styled(PSemi)``;

export const UserIcon = styled(FiUser)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const SettingsIcon = styled(FiSettings)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const BellIcon = styled(FiBell)`
  width: 1.5rem;
  height: 1.5rem;
`;
