import React, { FC } from 'react';

import {
  ArrowLeft,
  ArrowRight,
  Container,
  IconButton,
  Number,
  FullRange,
  Wrapper,
} from './styles';
import { Props } from './types';

const LIMIT = 7;
const START = 5;

const Pagination: FC<Props> = ({
  fullRange = true,
  onChangePage,
  page,
  pages,
  ...props
}) => {
  const hasNext = page === pages;
  const hasPrev = page - 1 === 0;

  const handleNext = () => {
    onChangePage(page + 1);
  }
  const handlePrev = () => {
    onChangePage(page - 1);
  }

  const numbers = Array.from(Array(pages).keys()).map((_, index) => (
    <Number
      $current={page === index + 1}
      disabled={page === index + 1}
      key={index.toString()}
      onClick={()=> onChangePage(index + 1)}
    >
      {index + 1}
    </Number>
  ));

  const from = Math.max(page - 3, 0);
  const to = Math.max(page + 2, START);

  let segments =
    numbers.length > LIMIT
      ? [
          ...numbers.slice(from, to),
          <Number
            $current={false}
            onClick={() => {
              const half = Math.floor(from + (numbers.length - from) / 2);
              onChangePage(half);
            }}
          >
            ...
          </Number>,
          numbers[numbers.length - 1],
        ]
      : numbers;

  if (numbers.length > LIMIT && page > numbers.length - START) {
    segments = numbers.slice(-START);
  }

  return (
    <Container {...props}>
      <Wrapper>
        <IconButton
          icon={<ArrowLeft />}
          onClick={handlePrev}
          disabled={hasPrev}
        />
        {fullRange ? (
          segments
        ) : (
          <FullRange
            $current={false}
            onClick={() => {
              const half = Math.floor(from + (numbers.length - from) / 2);
              onChangePage(half);
            }}
          >
            {page}/{pages}
          </FullRange>
        )}

        <IconButton
          icon={<ArrowRight />}
          onClick={handleNext}
          disabled={hasNext}
        />
      </Wrapper>
    </Container>
  );
};

export default Pagination;
