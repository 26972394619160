import {
  AvailabilitySpace,
  AvailabilitySpaceVariables,
} from 'graphql/generated/AvailabilitySpace';

import { AVAILABILITY_SPACE } from 'graphql/queries/availabilitySpace';
import { normalizeAvailabilitySpace } from 'model/availabilitySpace';
import { useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';

const useAvailability = (variables?: AvailabilitySpaceVariables) => {
  const [getAvailability, { loading, data }] = useLazyQuery<
    AvailabilitySpace,
    AvailabilitySpaceVariables
  >(AVAILABILITY_SPACE, {
    variables,
    fetchPolicy: "no-cache",
  });

  const availability = useMemo(
    () =>
      data?.availabilitySpace
        ? normalizeAvailabilitySpace(data.availabilitySpace)
        : undefined,
    [data]
  );

  return {
    getAvailability,
    availability,
    loading,
  };
};

export default useAvailability;
