import React, { FC, memo } from 'react';
import { Component, Icon, TextWrapper } from './styles';
import { Props } from './types';

const SocialButton: FC<Props> = ({
  variant,
  icon,
  fullWidth = true,
  children,
  onClick,
  ...props
}) => {
  return (
    <Component
      type="button"
      $variant={variant}
      $fullWidth={fullWidth}
      onClick={onClick}
      {...props}
    >
      <Icon>{icon}</Icon>
      {children && <TextWrapper>{children}</TextWrapper>}
      <Icon style={{ backgroundColor: 'transparent' }} />
    </Component>
  );
};

export default memo(SocialButton);
