import React, { FC, memo } from 'react';
import {
  Container,
  Label,
  Component,
  SelectContainer,
  Icon,
  MenuItem,
  CheckIcon,
  ItemLabel,
} from './styles';
import { Props } from './types';

const Select: FC<Props> = ({
  options,
  label,
  disabled,
  value,
  handleChange,
  children,
  ...props
}) => {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <SelectContainer>
        <Component
          IconComponent={Icon}
          value={value}
          onChange={handleChange}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            disablePortal: true,
          }}
          disabled={disabled}
          {...props}
        >
          {options &&
            options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                <CheckIcon />
                <ItemLabel>{option.label}</ItemLabel>
              </MenuItem>
            ))}
        </Component>
      </SelectContainer>
    </Container>
  );
};

export default memo(Select);
