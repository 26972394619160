import { useCallback } from 'react';
import useUserActions from 'graphql/hooks/useUserActions';

type Args = {
  handleClose: () => void;
};

const useConnect = ({ handleClose }: Args) => {
  const { isLoading, forgotPassword } = useUserActions();

  const initialValues = {
    email: '',
  };

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      await forgotPassword(values);
      handleClose();
    },
    [initialValues, handleClose, forgotPassword]
  );

  return {
    isLoading,
    handleSubmit,
    initialValues,
  };
};

export default useConnect;
