import { useCallback } from 'react';
import { setToken, removeToken } from 'services/token';
import { useApolloClient, useMutation } from '@apollo/client';
import { LOGIN, REGISTER } from 'graphql/mutations/auth';

import { ME } from 'graphql/queries/me';
import { SedeeError } from 'graphql/helpers';
import { Me } from 'graphql/generated/Me';
import { Login, LoginVariables } from 'graphql/generated/Login';
import { Register, RegisterVariables } from 'graphql/generated/Register';

const useAuth = () => {
  const client = useApolloClient();
  const [loginMutation, { loading: loginLoading }] = useMutation<
    Login,
    LoginVariables
  >(LOGIN);
  const [registerMutation, { loading: registerLoading }] = useMutation<
    Register,
    RegisterVariables
  >(REGISTER);

  const login = useCallback(
    async ({ input }: LoginVariables) => {
      try {
        const response = await loginMutation({ variables: { input } });
        const data = response.data?.login;

        if (data && data.jwt) {
          setToken(data.jwt);

          client.cache.writeQuery<Me>({
            data: { me: data.user },
            query: ME,
          });
        }
      } catch (error) {
        throw new SedeeError('Failed to login', error);
      }
    },
    [client, loginMutation]
  );

  const register = useCallback(
    async ({ input }: RegisterVariables) => {
      try {
        const response = await registerMutation({ variables: { input } });
        const data = response.data?.register;

        if (data && data.jwt) {
          setToken(data.jwt);

          client.cache.writeQuery<Me>({
            data: { me: data.user },
            query: ME,
          });
        }
      } catch (error) {
        throw new SedeeError('Failed to register', error);
      }
    },
    [client, registerMutation]
  );

  const logout = useCallback(async () => {
    removeToken();
    await client.cache.reset();

    client.cache.writeQuery<Me>({
      data: { me: null },
      query: ME,
    });
  }, [client]);

  return {
    loading: loginLoading,
    login,
    register,
    registerLoading,
    logout,
  };
};

export default useAuth;
