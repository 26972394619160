import styled from 'styled-components';

export const Component = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  height: 2.375rem;
  width: 2.375rem;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: all 0.2s;
  box-shadow: 0rem 1rem 3.9375rem rgba(33, 35, 48, 0.0168519),
    0rem 0.6482rem 2.306rem rgba(33, 35, 48, 0.0274815),
    0rem 0.3852rem 1.2542rem rgba(33, 35, 48, 0.035),
    0rem 0.2rem 0.6398rem rgba(33, 35, 48, 0.0425185),
    0rem 0.0815rem 0.3208rem rgba(33, 35, 48, 0.0531481),
    0rem 0.0185rem 0.1549rem rgba(33, 35, 48, 0.07);
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.royalBlue100};
  }
  :active:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.royalBlue200};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

export const Icon = styled.span`
  font-size: 1rem;
  display: grid;
  place-items: center;
`;
