import * as yup from 'yup';

export const initialValues = {
  email: '',
  password: '',
  telephone: '',
  check: false
};

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email no válido')
    .required('Email requerido'),
  password: yup
      .string()
      .required('Contraseña requerida'),
  telephone: yup
      .number()
      .typeError('El teléfono tiene que ser numérico')
      .min(100000000, 'Tiene que tener un mínimo de 9 dígitos')
      .required('Teléfono requerido'),
  check: yup.boolean().typeError('Debe aceptar los términos y condiciones.').oneOf([true], 'Tienes que aceptar los términos y condiciones').required(),
});
