import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';
import DefaultCarousel from 'components/Carousel';
import { BodyMedium, Small } from 'components/Typography';
import DefaultTag from 'components/Tag';
import DefaultBulletButton from 'components/BulletButton';
import DefaultLikeButton from 'components/LikeButton';
import { ReactComponent as Person } from 'assets/icons/avatar.svg';

export const Component = styled.div`
  position: relative;
  width: 100%;
  border-radius: 0.3125rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.ebonyClay};
  box-shadow: 0rem 0.3125rem 1.25rem
    ${({ theme }) => addAlpha(theme.colors.ebonyClay, 0.05)};
  transition: 0.3s;
  display: flex;
  flex-direction: column;

  :hover {
    box-shadow: 0rem 0.3125rem 1.25rem
      ${({ theme }) => addAlpha(theme.colors.royalBlue, 0.2)};
  }
`;

export const PrevButton = styled(DefaultBulletButton)`
  position: absolute;
  top: 45%;
  left: 1rem;
  opacity: 0;
`;

export const NextButton = styled(DefaultBulletButton)`
  position: absolute;
  top: 45%;
  right: 1rem;
  opacity: 0;
`;

export const CarouselWrapper = styled.div`
  position: relative;
  border-radius: 0.3125rem 0.3125rem 0 0;
  overflow: hidden;

  :hover {
    ${PrevButton}, ${NextButton} {
      opacity: 1;
    }
  }
`;

export const Carousel = styled(DefaultCarousel)``;

export const LikeButton = styled(DefaultLikeButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
`;

export const Content = styled.div`
  position: relative;
  padding: 1rem;
  border-radius: 0 0 0.3125rem 0.3125rem;
  display: grid;
  grid-template-rows: 1fr auto auto 1.6875rem;
  flex: 1;
  grid-gap: 0.25rem;
`;

export const Title = styled(BodyMedium)`
  font-size: 1rem;
`;

export const Text = styled(Small)`
  display: block;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const MaxCapacity = styled(Text)``;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Price = styled(BodyMedium)`
  display: inline-block;
`;

export const SmallText = styled(Small)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray700};
  font-size: 0.75rem;
`;

export const Tag = styled(DefaultTag)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

export const PersonIcon = styled(Person)`
  margin-right: 0.2rem;
`;
