import { useHistory, useLocation, useParams } from 'react-router-dom';
import { setToken } from 'services/token';
import { useState, useEffect } from 'react';
import { ME } from 'graphql/queries/me';
import { useApolloClient } from '@apollo/client';
import { Me } from 'graphql/generated/Me';
import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const client = useApolloClient();
  const backendUrl = process.env.REACT_APP_API_URL;
  const [text, setText] = useState('Loading...');
  const location = useLocation();
  const { providerName } = useParams<{ providerName: string }>();
  const history = useHistory();
  const { refetch } = useMe();

  useEffect(() => {
    fetch(`${backendUrl}/auth/${providerName}/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        setToken(res.jwt);
        client.writeQuery<Me>({
          query: ME,
          data: { me: res.user },
        });
        setText(
          'Te has logeado con éxito. En unos segundos serás redirigido...'
        );
        history.push('/');
        refetch();
      })
      .catch(() => {
        setText('Ha ocurrido un error, por favor inténtelo de nuevo.');
      });
  }, [
    client,
    refetch,
    history,
    location.search,
    providerName,
    backendUrl,
    setText,
  ]);

  return {
    text,
  };
};

export default useConnect;
