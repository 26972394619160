import useSpaceCategories from 'graphql/hooks/useSpaceCategories';
import { useCallback, useMemo, useState } from 'react';
import useQuery from 'utils/useQueryParams';
import { from, useMediaQuery } from 'styles/media';
import { initialValues as initial } from './constants';

type Args = {
  handleClose: () => void;
};

type Category = (string | null)[];

const useConnect = ({ handleClose }: Args) => {
  const isMobile = !useMediaQuery(from.mobile);
  const { loading, spaceCategories } = useSpaceCategories();
  const { query, setQuery, resetQueryValues } = useQuery();
  const {
    minPrice,
    maxPrice,
    capacity,
    reducedMovility,
    spaceCategory,
  } = query;
  const [initialValues, setInitialValues] = useState(initial);
  const [categorySelected, setCategorySelected] = useState<Category>(
    spaceCategory || []
  );

  useMemo(() => {
    setInitialValues({
      minPrice: minPrice || undefined,
      maxPrice: maxPrice || undefined,
      maxCapacity: capacity || undefined,
      reducedMovility: reducedMovility || undefined,
    });
  }, [setInitialValues, minPrice, maxPrice, capacity, reducedMovility]);

  type FormValues = typeof initialValues;

  const handleSelectCategory = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const newCategory = event.currentTarget.value;

      if (!categorySelected.includes(newCategory)) {
        setCategorySelected((oldCategories) => [...oldCategories, newCategory]);
      } else if (categorySelected.includes(newCategory)) {
        const newCategories = categorySelected.filter((item) => {
          return item !== newCategory;
        });
        setCategorySelected(newCategories);
      }
    },
    [setCategorySelected, categorySelected]
  );

  const handleRemoveValues = useCallback(() => {
    setInitialValues(initial);
    setCategorySelected([]);
    resetQueryValues();
    handleClose();
  }, [setInitialValues, handleClose, resetQueryValues]);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      setQuery({
        minPrice: values.minPrice,
        maxPrice: values.maxPrice,
        capacity: values.maxCapacity,
        reducedMovility: values.reducedMovility,
        spaceCategory:
          categorySelected.length === 0 ? undefined : categorySelected,
      });
      handleClose();
    },
    [handleClose, setQuery, categorySelected]
  );

  return {
    loading,
    spaceCategories,
    initialValues,
    handleSubmit,
    handleRemoveValues,
    handleSelectCategory,
    categorySelected,
    isMobile,
  };
};

export default useConnect;
