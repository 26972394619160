import React from 'react';
import { range } from 'lodash';
import { CityLoader } from 'components/Loaders';
import useConnect from './connect';
import { Hero, Cards, Card, Content, Title } from './styles';

const Cities = () => {
  const { isLoading, locations } = useConnect();

  return (
    <>
      <Hero />
      <Content>
        <Title>¿Pensando en celebrar un evento?</Title>
        <Cards>
          {isLoading
            ? range(8).map((item) => <CityLoader opacity={1} key={item} />)
            : locations.list.map((location) => (
                <Card
                  key={location.id}
                  linkProps={{
                    to: `/search?location=${location.slug}`,
                  }}
                  title={location.name}
                  spacesCount={location.spacesCount}
                  price={location.minPrice}
                  image={location.image}
                />
              ))}
        </Cards>
      </Content>
    </>
  );
};

export default Cities;
