import {useCallback, useState} from 'react';
import { FormikHelpers } from 'formik';
import useAuth from 'graphql/hooks/useAuth';
import { initialValues, validationSchema } from './constants';

type FormValues = typeof initialValues;
type Args = {
  handleClose: () => void;
};
const useConnect = ({ handleClose }: Args) => {
  const { loading, login } = useAuth();


  const [error, setError] = useState();
  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setErrors, setFieldError }: FormikHelpers<FormValues>
    ) => {
      try {
        await login({ input: values });
        handleClose();
      } catch (error) {
        setError(error.message);
        if (error.fields) {
          setErrors(error.fields);
        } else {
          setFieldError('password', error.message);
        }
      }
    },
    [login, handleClose]
  );

  const handleLoginProvider = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/connect/google`;
  };

  return {
    loading,
    handleSubmit,
    initialValues,
    validationSchema,
    handleLoginProvider,
    error
  };
};

export default useConnect;
