import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { AVAILABILITY_SPACES } from 'graphql/queries/spaces';
import { normalizeCustomSpace } from 'model/Space';
import {
  AvailabilitySpaces,
  AvailabilitySpacesVariables,
} from 'graphql/generated/AvailabilitySpaces';
import notEmpty from 'utils/notEmpty';

const useAvailability = (variables?: AvailabilitySpacesVariables) => {
  const { loading, data } = useQuery<
    AvailabilitySpaces,
    AvailabilitySpacesVariables
  >(AVAILABILITY_SPACES, {
    fetchPolicy: "no-cache",
    variables
  });

  const spaces = useMemo(
    () =>
      (data?.availabilitySpaces || [])
        .filter(notEmpty)
        .map(normalizeCustomSpace),
    [data]
  );

  if (variables?.sort === undefined) {
    spaces.sort(
        (value) => {
          return value.canInstantBooking ? -1 : 1;
        }
    )
  }
  return {
    spaces,
    loading,
  };
};

export default useAvailability;
