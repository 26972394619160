import React from 'react';
import { Formik, Form } from 'formik';
import useConnect from './connect';
import { Props } from './types';
import {Component, Icon} from "../ButtonBack/styles";

const ConfirmedButton = ({ id }: Props) => {
    const {
        handleBookingConfirmed,
        initialValues,
    } = useConnect({ id });

    return (
        <>
            <Formik
                onSubmit={handleBookingConfirmed}
                initialValues={initialValues}
            >
                <Form noValidate>
                    <Component>
                        <Icon />
                    </Component>
                </Form>
            </Formik>
        </>
    );
};

export default ConfirmedButton;