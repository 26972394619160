import styled, {css} from "styled-components";
export const TransferButton = styled.button`
    background: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: ${
    ({theme}) =>  {
      return css`
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.royalBlue};
        
          color: ${theme.colors.royalBlue};
          path {
            fill: ${theme.colors.royalBlue};
          }
        
          :hover {
            background-color: ${theme.colors.royalBlue100};
            border-color: ${theme.colors.royalBlue};
          }
        
          :active,
          :focus {
            background-color: ${theme.colors.royalBlue200};
            border-color: ${theme.colors.royalBlue600};
            box-shadow: 0 0 0 0.0625rem ${theme.colors.royalBlue600};
          }
        
          :disabled {
            border-color: ${theme.colors.gray400};
            color: ${theme.colors.gray400};
          }
        `;}};
    vertical-align: middle;
    border-radius: 0.3125rem;
    margin-bottom: 0.4rem;
      padding: 0.40rem 1.70rem;
      border: 0.0625rem solid;
`;
export const Container = styled.div`
`;
export const Section = styled.div`
  padding: 2rem 1.5rem;
`;

export const SectionTitle = styled.h3`
    font-weight: bold;
    font-size: larger;
`;

export const SectionText = styled.p`
  padding: 1rem 1.75rem;
`;

export  const SectionUl = styled.ul`
  padding: 0 3rem;
`;

export const SectionLi = styled.li`
  list-style: circle;
  padding: 0.25rem 0;
`;

export const Strong = styled.strong`
  font-weight: bold;
`;