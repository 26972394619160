import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { Carousel as DefaultCarousel } from 'react-responsive-carousel';
import DefaultBulletButton from 'components/BulletButton';

export const PrevButton = styled(DefaultBulletButton)`
  position: absolute;
  z-index: 2;
  top: 45%;
  left: 1rem;
  opacity: 0;
`;

export const NextButton = styled(DefaultBulletButton)`
  position: absolute;
  z-index: 2;
  top: 45%;
  right: 1rem;
  opacity: 0;
`;

export const OpenButton = styled(DefaultBulletButton)`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  margin: 1rem;
`;

export const Component = styled(DefaultCarousel)`
  :hover {
    ${PrevButton}, ${NextButton} {
      opacity: 1;
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const Image = styled.img`
  height: 12.5rem;
  width: 100%;
  object-fit: cover;
`;

export const Dot = styled.li<{ $isCurrentPhoto: boolean }>`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.3)};
  border-radius: 50%;
  border: none;
  margin: 0 8px;
  cursor: pointer;
  transition: 0.1s;
  display: inline-block;
  :not(:last-child) {
    margin-right: 0.5rem;
  }
  :last-child {
    transform: scale(0.7);
  }
  ${({ $isCurrentPhoto }) =>
    $isCurrentPhoto &&
    css`
      background-color: ${({ theme }) => addAlpha(theme.colors.white, 1)};
      :last-child {
        transform: scale(1);
      }
    `}
`;
