import {useCallback, useState} from 'react';
import { FormikHelpers } from 'formik';
import useAuth from 'graphql/hooks/useAuth';
import { initialValues, validationSchema } from './constants';

type FormValues = typeof initialValues;

type Args = {
  handleClose: () => void;
};

const useConnect = ({ handleClose }: Args) => {
  const { registerLoading, register } = useAuth();
  const [registered, setRegistered] = useState(false);
  const closeRegistered = () => {
    setRegistered(false);
  }
  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setErrors, setFieldError }: FormikHelpers<FormValues>
    ) => {
      try {
        await register({
          input: {
            username: values.email,
            email: values.email,
            password: values.password,
            phoneNumber: values.telephone,
            check: values.check
          },
        });
        setRegistered(true);
        handleClose();
      } catch (error) {
        error(error);
        if (error.fields) {
          error(error.fields);
        } else {
          setFieldError('password', error.message);
        }
      }
    },
    [register, handleClose]
  );

  const handleLoginProvider = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/connect/google`;
  };

  return {
    registerLoading,
    handleSubmit,
    initialValues,
    validationSchema,
    handleLoginProvider,
    registered,
    closeRegistered
  };
};

export default useConnect;
