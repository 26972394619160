import React, { memo } from 'react';
import {
  Container,
  Title,
  MenuLink,
  Text,
  UserIcon,
  SettingsIcon,
  Content,
  Select,
  Mobile,
} from './styles';
import useConnect from './connect';

const Menu = () => {
  const { isMobile, currentValue, handleChange, options } = useConnect();

  if (isMobile) {
    return (
      <Mobile>
        <Title>Mi perfil</Title>
        <Select
          options={options}
          value={currentValue}
          onChange={handleChange}
        />
      </Mobile>
    );
  }

  return (
    <Container>
      <Content>
        <Title>Mi perfil</Title>
        <MenuLink activeClassName="selected" to="/profile/edit">
          <Text>Editar perfil</Text>
          <UserIcon />
        </MenuLink>
        <MenuLink activeClassName="selected" to="/profile/account">
          <Text>Cuenta</Text>
          <SettingsIcon />
        </MenuLink>
        {/* <MenuLink activeClassName="selected" to="/profile/notifications">
          <Text>Notificaciones</Text>
          <BellIcon />
        </MenuLink> */}
      </Content>
    </Container>
  );
};

export default memo(Menu);
