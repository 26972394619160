import React, { FC, memo } from 'react';
import { Component, HeartIcon } from './styles';
import { Props } from './types';

const LikeButton: FC<Props> = ({ variant, isLiked, onClick, ...props }) => {
  return (
    <Component type="button" onClick={onClick} {...props}>
      <HeartIcon $isLiked={isLiked} $variant={variant} />
    </Component>
  );
};

export default memo(LikeButton);
