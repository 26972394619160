import { AvailabilitySpace_availabilitySpace } from 'graphql/generated/AvailabilitySpace';
import formatPriceNumber from 'utils/formatPrice';

type Input = AvailabilitySpace_availabilitySpace;

export const normalizeAvailabilitySpace = (input: Input) => {
  return {
    available: input.available || false,
    message: input.message || '',
    total: formatPriceNumber(input.total || 0),
    totalNumber: input.total || 0,
    canInstantBooking: input.canInstantBooking || false,
  };
};

export type AvailabilitySpace = ReturnType<typeof normalizeAvailabilitySpace>;
