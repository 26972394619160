import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useConnect = () => {
  const { goBack } = useHistory();

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  return { handleGoBack };
};

export default useConnect;
