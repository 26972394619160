import useBookingActions from 'graphql/hooks/useBookingActions';
import {useCallback, useState} from 'react';

type Args = {
  id: string;
};


const useConnect = ({ id }: Args) => {
  const { payInProgress, payBooking, data, transferBooking} = useBookingActions();
  const [itsOkay, setItsOkay] = useState(false);
    const [itsOpen, setItsOpen] = useState(false);
  const initialValues = {
    id,
  };
  type FormValues = typeof initialValues;

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
          await payInProgress({
          id: values.id
        });
      } catch (error) {
        console.log(error);
      }
    },
    [payInProgress]
  );


    const handleClose = () =>{
        setItsOpen(false)
    }


    const handleSubmitPayment = useCallback(
      async (values: FormValues) => {
        try {
          await payBooking({
              id: values.id
          });


        setItsOkay(true);
        } catch (error) {
          console.log(error+'');
        }
      },
      [payBooking]
  );

  const handleSubmitTransfer = useCallback(
      async (values: FormValues) => {
          try {
              await transferBooking({
                  id: values.id
              });

              setItsOkay(true);
              setItsOpen(true)
          } catch (error) {
              console.log(error);
          }
      },
      [transferBooking]
  );

  return {
      handleSubmit,
      initialValues,
      handleSubmitPayment,
      handleSubmitTransfer,
      itsOkay,
      data,
      itsOpen,
      handleClose
  };
};

export default useConnect;
