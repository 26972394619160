import { CategoryLoader, CityLoader, SpaceLoader } from 'components/Loaders';

import React from 'react';
import { range } from 'lodash';
import {
  BigRowSubtitle,
  BorderedLink,
  CardsContainer,
  CityCard,
  Content,
  Hero,
  Link,
  LinkIcon,
  LinkText,
  Row,
  RowContainer,
  RowSubtitle,
  RowText,
  RowTextContainer,
  SpaceCard,
  SpaceTypeCard,
} from './styles';
import useConnect from './connect';

const Home = () => {
  const {
    instantSpaces,
    locations,
    spaceCategories,
    isSpacesLoading,
    isLocationsLoading,
    isSpaceCategoriesLoading,
  } = useConnect();

  return (
    <>
      <Hero />
      <Content>
        <RowContainer>
          <Row>
            <RowTextContainer>
              <RowSubtitle>¿Pensando en celebrar un evento?</RowSubtitle>
              <Link to="/cities">
                <LinkIcon />
                <LinkText>Ver todos</LinkText>
              </Link>
            </RowTextContainer>
            <CardsContainer>
              {isLocationsLoading
                ? range(4).map((item) => <CityLoader opacity={1} key={item} />)
                : locations.list.map((location) => (
                    <CityCard
                      key={location.id}
                      linkProps={{
                        to: `/search?location=${location.slug}`,
                      }}
                      image={location.image}
                      title={location.name}
                      spacesCount={location.spacesCount}
                      price={location.minPrice}
                    />
                  ))}
            </CardsContainer>
          </Row>
        </RowContainer>

        <RowContainer $colored>
          <Row>
            <RowTextContainer $bottomAlign>
              <div>
                <BigRowSubtitle>Reserva inmediata</BigRowSubtitle>
                <RowText>
                  Encuentra el espacio perfecto para tu evento y reserva sin
                  esperas.
                </RowText>
              </div>
              <BorderedLink to="/search">
                <LinkText>Explorar más espacios</LinkText>
              </BorderedLink>
            </RowTextContainer>
            <CardsContainer>
              {isSpacesLoading
                ? range(4).map((item) => <SpaceLoader opacity={1} key={item} />)
                : instantSpaces.map((space) => (
                    <SpaceCard
                      linkProps={{
                        to: `/spaces/${space.slug}`,
                      }}
                      key={space.id}
                      title={space.name}
                      location={space.fullLocation}
                      maxCapacity={space.capacity}
                      price={space.price}
                      canBeBookedInstantly={space.canInstantBooking}
                      images={space.images}
                    />
                  ))}
            </CardsContainer>
          </Row>
        </RowContainer>

        <RowContainer>
          <Row>
            <RowSubtitle>¿Pensando en tu próximo evento?</RowSubtitle>
            <CardsContainer>
              {isSpaceCategoriesLoading
                ? range(4).map((item) => (
                    <CategoryLoader opacity={1} key={item} />
                  ))
                : spaceCategories.slice(0, 4).map((spaceCategory) => (
                    <SpaceTypeCard
                      key={spaceCategory.id}
                      linkProps={{
                        to: `/search?spaceCategory=${spaceCategory.slug}`,
                      }}
                      image={spaceCategory.image}
                      title={spaceCategory.name}
                      description={spaceCategory.description}
                    />
                  ))}
            </CardsContainer>
          </Row>
        </RowContainer>
        {spaceCategories.length > 4 ?? (
          <RowContainer>
            <Row>
              <RowSubtitle>¿Buscas un lugar especial para tu evento?</RowSubtitle>
                <CardsContainer>
                  {isSpaceCategoriesLoading
                    ? range(4).map((item) => (
                    <CategoryLoader opacity={1} key={item} />
                    ))
                    : spaceCategories.slice(4, 8).map((spaceCategory) => (
                    <SpaceTypeCard
                    key={spaceCategory.id}
                    linkProps={{
                    to: `/search?spaceCategory=${spaceCategory.slug}`,
                  }}
                    title={spaceCategory.name}
                    image={spaceCategory.image}
                    description={spaceCategory.description}
                    />
                  ))}
            </CardsContainer>
          </Row>
        </RowContainer>
      )}
      </Content>
    </>
  );
};

export default Home;
