import React, { FC, memo } from 'react';
import { Props } from './types';
import {
  Header,
  InfoCard,
  InfoTitle,
  PaperPlaneIcon,
  InfoText,
  ButtonContainer,
  Button,
  ArrowIcon,
} from '../styles';
import useConnect from '../connect';

const Cancelled: FC<Props> = ({ reason,preReservation, ...props }) => {
  const { handleGoSearch } = useConnect();
  return (
    <>
      <InfoCard {...props}>
        <Header>
          <PaperPlaneIcon />
          {preReservation ? (
            <InfoTitle $isBlue>Reserva cancelada</InfoTitle>
          ):(
            <InfoTitle $isBlue>Reserva cancelada</InfoTitle>
          )}
        </Header>
        <InfoText>{reason}</InfoText>
      </InfoCard>

      <ButtonContainer>
        <Button
          type="button"
          onClick={handleGoSearch}
          rightIcon={<ArrowIcon />}
          fullWidth
        >
          Nueva reserva
        </Button>
      </ButtonContainer>
    </>
  );
};

export default memo(Cancelled);
