import styled, { css } from 'styled-components';
import { SmallSemi, Caption } from 'components/Typography';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import { LabelProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div<{ $error: boolean }>`
  background-color: ${({ theme }) => theme.colors.gray200};
  border: 0.125rem solid ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  padding: 0.875rem 0.75rem;
  transition: 0.3s;

  &:focus-within {
    border: 0.125rem solid ${({ theme }) => theme.colors.royalBlue};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray300};
  }
  ${({ $error }) =>
    $error &&
    css`
      border-color: ${({ theme }) => theme.colors.outrageousOrange};
    `}
`;

export const Component = styled.input`
  color: ${({ theme }) => theme.colors.ebonyClay};
  background-color: inherit;
  border: none;
  font-size: 0.875rem;
  outline: 0;
  line-height: 1.225rem;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray700};
  }
  :disabled {
    ::placeholder {
      color: ${({ theme }) => theme.colors.gray400};
    }
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

export const Label = styled(SmallSemi)<LabelProps>`
  color: ${({ theme }) => theme.colors.gray700};
  margin-bottom: 0.25rem;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray400};
    `}
`;

export const IconError = styled(FiAlertCircle)`
  color: ${({ theme }) => theme.colors.outrageousOrange};
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.75rem;
`;

export const IconClean = styled(FiX)`
  color: ${({ theme }) => theme.colors.gray600};
  height: 1.25rem;
  width: 1.25rem;
`;

export const Button = styled.button<LabelProps>`
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.75rem;
  ${({ $disabled }) =>
    $disabled &&
    css`
      display: none;
    `}
`;

export const Error = styled(Caption)`
  color: ${({ theme }) => theme.colors.outrageousOrange};
  margin-top: 0.25rem;
`;
