import { UserParts } from 'graphql/generated/UserParts';

type InputUser = UserParts;

export const normalizeUser = (input: InputUser) => {
  return {
    id: input.id,
    email: input.email,
    confirmed: input.confirmed || undefined,
    blocked: input.blocked || false,
    username: input.username,
   	firstName: input.firstName || undefined,
    lastName: input.lastName || undefined,
    phoneNumber: input.phoneNumber || undefined,
    homeAddress: input.homeAddress || undefined,
    city: input.city || undefined,
    dni: input.dni || undefined,
    fiscalAddress: input.fiscalAddress || undefined,
    companyName: input.companyName || undefined,
  };
};

export type User = ReturnType<typeof normalizeUser>;