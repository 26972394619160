import React, { FC, memo } from 'react';
import { Component, Icon, TextWrapper } from 'components/Button/styles';
import { Props } from './types';

const Button: FC<Props> = ({
  variant,
  size = 'big',
  leftIcon,
  rightIcon,
  fullWidth,
  isCentered = true,
  children,
  onClick,
    isHidden,
  ...props
}) => {
  return (
    <Component
      type="button"
      $variant={variant}
      $size={size}
      $fullWidth={fullWidth}
      $isCentered={isCentered}
      $isHidden={isHidden}
      onClick={onClick}
      {...props}
    >
      {leftIcon && <Icon>{leftIcon}</Icon>}
      {children && <TextWrapper>{children}</TextWrapper>}
      {rightIcon && <Icon>{rightIcon}</Icon>}
    </Component>
  );
};

export default memo(Button);
