import { useCallback, useState } from 'react';
import useUserActions from 'graphql/hooks/useUserActions';
import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const { isLoading: forgotLoading, forgotPassword } = useUserActions();
  const { loading: meLoading, me } = useMe();
  const [info, setInfo] = useState('');

  const handleForgotPassword = useCallback(async () => {
    try {
      if (me && me.email) {
        await forgotPassword({ email: me.email });
        setInfo(`Le hemos enviado un correo a ${me.email}`);
      }
    } catch (error) {
      setInfo(error.fields.exception.data.message[0].messages[0].message);
    }
  }, [forgotPassword, me]);

  return {
    isLoading: forgotLoading || meLoading,
    handleForgotPassword,
    info,
  };
};

export default useConnect;
