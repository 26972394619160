import React from 'react';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { Container, Title, Text } from './styles';
import useConnect from './connect';
import { Props } from './types';

const PayModal = ({
  buttonCaption,
  variant,
  id,
  price,
  isPrebooking = false,
}: Props) => {
  const { isOpen, openModal, closeModal } = useConnect({ id, isPrebooking });

  return (
    <>
      <Button variant={variant} fullWidth onClick={openModal}>
        {buttonCaption}
      </Button>
      <Modal title="Pagar" open={isOpen} handleClose={closeModal}>
        <Container>
          <Text>
            Para confirmar la reserva debes hacer una transferencia bancaría al
            siguiente número:
          </Text>
          <Title>ES82 01821 6002 3020 1931 429</Title>

          <Text>Precio a pagar:</Text>
          <Title>{price}€</Title>

          <Text>
            Recuerda añadir en el concepto el siguiente número de referencia:
          </Text>
          <Title>{id}</Title>

          <Text>
            Por último, envía el justificante de pago a nuestra dirección de
            correo electrónico o avísanos por teléfono
          </Text>
          <Title>
            <a href="mailto:hola@sedee.io" target="__blank">
              hola@sedee.io
            </a>{' '}
            <a href="tel:+34666999333" target="__blank">
              +34 666 999 333
            </a>
          </Title>
        </Container>
      </Modal>
    </>
  );
};

export default PayModal;
