import React, { FC, memo } from 'react';
import ReactMarkdown from 'react-markdown';
import TextStyles from 'components/TextStyles';

type Props = {
  md?: string;
};

const Markdown: FC<Props> = ({ md, ...props }) =>
  md ? (
    <TextStyles>
      <ReactMarkdown {...props}>{md}</ReactMarkdown>
    </TextStyles>
  ) : null;

export default memo(Markdown);
