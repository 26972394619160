import {
  Locations_customLocations_locations,
  Locations_customLocations,
} from 'graphql/generated/Locations';
import notEmpty from 'utils/notEmpty';
import { normalizeImage } from './global';

type InputLocation = Locations_customLocations;
type InputLocationParts = Locations_customLocations_locations;

export const normalizeLocation = (input: InputLocationParts) => {
  return {
    id: input.id,
    slug: input.slug,
    name: input.name || `${input.id}`,
    description: input.description || undefined,
    minPrice: input.minPrice || 0,
    spacesCount: input.spacesCount || 0,
    image: input.image ? normalizeImage(input.image) : undefined,
  };
};

export const normalizeCustomLocations = (input?: InputLocation | null) => {
  return {
    count: input?.count || 0,
    list: (input?.locations || []).filter(notEmpty).map(normalizeLocation),
  };
};
export type Location = ReturnType<typeof normalizeLocation>;
