import { InfoText, InfoTextBottom, InfoTitle } from 'containers/Private/Booking/styles';
import React from 'react';
import useConnect from '../Cookies/connect';
import {
  Container,
  Header,
  Title,
  ButtonBack,
  Section,
  Card,
  CardTitle,
  CardCaption,
  CardText,
  SlashIcon,
  MinusIcon,
  CheckIcon,
  Content,
  ContentCancel,
  Button,
  MessageCircle,
  QuestionCard,
  SectionQuestion,
  PaperPlane,
  SectionBottom
} from './styles';

const CancellationOptions = () => {
  const { handleGoBack } = useConnect();

  return (
    <Container>
      <Header>
        <ButtonBack onClick={handleGoBack} />
        <Title>Opciones de cancelación</Title>
      </Header>

      <Section>
        <Card>
          <CheckIcon />
          <CardTitle>Gratuita</CardTitle>
          <CardCaption>20 días antes</CardCaption>
          <CardText>
            Para obtener un reembolso completo, la cancelación debe realizarse
            al menos 20 días antes del evento.
          </CardText>
        </Card>

        <Card>
          <MinusIcon />
          <CardTitle>Flexible</CardTitle>
          <CardCaption>7 días antes</CardCaption>
          <CardText>
            Si cancelas 7 días antes del evento, no se te reembolsará el importe
            de la reserva.
          </CardText>
        </Card>

        <Card>
          <SlashIcon />
          <CardTitle>Sin reembolso</CardTitle>
          <CardCaption>2 días antes</CardCaption>
          <CardText>
            Si cancelas 7 días antes del evento, no se te reembolsará el importe
            de la reserva.
          </CardText>
        </Card>
      </Section>
      <Content>
        <ContentCancel>
          <InfoTitle>Seguro de cancelación</InfoTitle>
          <InfoText>Contrata nuestro seguro y disfruta de cancelación aún más Flexible antes imprevistos. Para más información,
            ponte en contacto con el equipo de Sedee.
          </InfoText>
        </ContentCancel>
        <Button variant='floating'>
            MÁS INFO
            <MessageCircle/>
        </Button>
      </Content>
      <SectionQuestion>
          <QuestionCard>
            <InfoTitle>
            Política de Causas de Fuerza Mayor relativa al coronavirus (COVID-19)
            </InfoTitle>
            <InfoText>
            Et volutpat ac etiam sociis in ultricies ut eu dolor. Risus ullamcorper amet sit commodo interdum felis sit nam. Leer más
            </InfoText>
          </QuestionCard>
          <QuestionCard>
            <InfoTitle>
            ¿Cuánto se tarda en recibir un reembolso?
            </InfoTitle>
            <InfoText>
            Et volutpat ac etiam sociis in ultricies ut eu dolor. Risus ullamcorper amet sit commodo interdum felis sit nam. Leer más
            </InfoText>
          </QuestionCard>
          <QuestionCard>
            <InfoTitle>
            ¿Tengo que contratar un seguro?
            </InfoTitle>
            <InfoText>
            Et volutpat ac etiam sociis in ultricies ut eu dolor. Risus ullamcorper amet sit commodo interdum felis sit nam. Leer más
            </InfoText>
          </QuestionCard>
          <QuestionCard>
            <InfoTitle>
            ¿Obtener un reembolso si el espacio no es lo que esperaba?
            </InfoTitle>
            <InfoText>
            Et volutpat ac etiam sociis in ultricies ut eu dolor. Risus ullamcorper amet sit commodo interdum felis sit nam. Leer más
            </InfoText>
          </QuestionCard>
        </SectionQuestion>
        <Content>
          <SectionBottom>
            <InfoTextBottom>
              ¿Tienes alguna duda?
            </InfoTextBottom>
            <Button variant='floating'>
                CONTÁCTANOS
                <PaperPlane/>
            </Button>
          </SectionBottom>
      </Content>
    </Container>
  );
};

export default CancellationOptions;
