import { ApolloError } from '@apollo/client';

/* eslint-disable import/prefer-default-export */

export class SedeeError extends Error {
  public fields?: Record<string, string[]>;

  constructor(message = 'Something went wrong!', error: ApolloError) {
    super(message);

    if (error?.networkError) {
      return;
    }

    this.fields = error?.graphQLErrors?.[0]?.extensions;
  }
}