import { gql } from '@apollo/client';
import { ME_FRAGMENT } from 'graphql/fragments/me';

/* eslint-disable import/prefer-default-export */

export const ME = gql`
  query Me {
    me {
      ...MeParts
    }
  }
  ${ME_FRAGMENT}
`;