  import React, {memo, useState} from 'react';
import Topbar from 'containers/Shared/Topbar';
import useConnect from './connect';
import {
  Container,
  Content,
  Row,
  SpaceCard,
  AddCard,
  RowContainer,
  RowTextContainer,
  RowSubtitle,
  Link,
  LinkIcon,
  CardsContainer,
  Title,
} from './styles';
const Bookings = () => {
  let {
    previousBookings,
    nextBookings,
  } = useConnect();
  previousBookings = previousBookings
    .filter((booking)=>{
        let response;
        if (booking.state === "reserva_completada" || booking.isCancelled || booking.endDate < new Date()) {
          response = booking;
        } else {
          response = null;
        }
        return response;

      })
  const elementInnerText = "Ver más";
  const elementsToSlice = 4;
  const [buttonInnerText, setButtonInnerText] = useState(elementInnerText);
  const [buttonPreviousInnerText, setButtonPreviousInnerText] = useState(elementInnerText);
  const [slicedElements, setSlicedElements] = useState(elementsToSlice);
  const [slicedPreviousElements, setSlicedPreviousElements] = useState(elementsToSlice);

  const handleClick = () => {
    if (nextBookings && slicedElements === 4) {
      setSlicedElements(nextBookings.length);
      setButtonInnerText("Ver menos")
    } else if (nextBookings) {
      setSlicedElements(elementsToSlice);
      setButtonInnerText(elementInnerText)
    }
  };

  const handlePreviousClick = () => {
    if (nextBookings && slicedPreviousElements === 4) {
      setSlicedPreviousElements(nextBookings.length);
      setButtonPreviousInnerText("Ver menos")
    } else if (nextBookings) {
      setSlicedPreviousElements(elementsToSlice);
      setButtonPreviousInnerText(elementInnerText)
    }
  }

  return (
    <Container>
      <Topbar isAuth />
      <Row>
        <Title>Mis reservas</Title>
      </Row>
      <Content />
      <Row>
        <RowTextContainer>
          <RowSubtitle>Próximas</RowSubtitle>
          <Link onClick={handleClick}>
            <LinkIcon />
            {buttonInnerText}
          </Link>
        </RowTextContainer>
        <RowContainer>
              <CardsContainer>
                {nextBookings.length > 0 ? (
                    nextBookings.slice(0,slicedElements).map((booking) => (
                        <SpaceCard
                            linkProps={{
                              to: `/bookings/${booking.id}`,
                            }}
                            key={booking.id}
                            title={booking.space?.name || ''}
                            location={booking.space?.fullLocation || ''}
                            price={`${booking.total}`}
                            images={booking.space?.images}
                            startDate={booking.startDate}
                            state={booking.isCancelled ? 'isCancelled' : booking.state}
                            availabilityRange={booking.dates}
                            isPrevious={true}
                        />
                    ))
                ) : (
                    <AddCard to="/search" />
                )}
              </CardsContainer>

        </RowContainer>
      </Row>
      {previousBookings.length > 0 ? (
        <Row>
          <RowTextContainer>
            <RowSubtitle>Anteriores</RowSubtitle>
            <Link onClick={handlePreviousClick}>
              <LinkIcon />
              {buttonPreviousInnerText}
            </Link>
          </RowTextContainer>
          <RowContainer>
            <CardsContainer>
              {previousBookings.map((booking) => (
                <SpaceCard
                  linkProps={{
                    to: `/bookings/${booking.id}`,
                  }}
                  key={booking.id}
                  title={booking.space?.name || ''}
                  images={booking.space?.images}
                  location={booking.space?.fullLocation || ''}
                  price={`${booking.total}`}
                  state={booking.isCancelled ? 'isCancelled' : booking.state}
                  availabilityRange={booking.dates}
                  isPrevious={false}
                />
              ))}
            </CardsContainer>
          </RowContainer>
        </Row>
      ): (
        <Row>
          <RowContainer>
            <CardsContainer>
              <AddCard to="/search" />
            </CardsContainer>
          </RowContainer>
        </Row>
      )}
    </Container>
  );
};

export default memo(Bookings);
