import { gql } from '@apollo/client';
import { SPACE_FRAGMENT } from 'graphql/fragments/space';
import { BOOKING_FRAGMENT } from 'graphql/fragments/booking';

/* eslint-disable import/prefer-default-export */

export const SPACE_BOOKINGS = gql`
  query Bookings($sort: String, $limit: Int, $start: Int, $where: JSON) {
    bookings(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...BookingParts
      space {
        ...SpaceParts
      }
    }
  }
  ${BOOKING_FRAGMENT}
  ${SPACE_FRAGMENT}
`;
