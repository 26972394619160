import Filter from 'containers/Shared/Modals/FilterModal';
import React from 'react';
import { SpaceLoader } from 'components/Loaders';
import { range } from 'lodash';
import Placeholder from './Placeholder';
import {
  Button,
  Container,
  Content,
  FilterIcon,
  Header,
  Map,
  Pagination,
  Section,
  SectionRight,
  Select,
  SpaceCard,
  SpacesContainer,
  Subtitle,
  Title,
} from './styles';
import useConnect from './connect';

const Search = () => {
  const {
    isLoading,
    isMobile,
    title,
    spaces,
    tempCount,
    sort,
    handleOrderChange,
    currentPage,
    handleUpdatePage,
    ITEMS_PER_PAGE,
    open,
    handleOpenModal,
    handleCloseModal,
    filterCount,
    options,
  } = useConnect();

  return (
    <>
      <Filter open={open} handleClose={handleCloseModal} />
      <Container>
        <Content>
          <Title>{title}</Title>
          <Subtitle>{tempCount.length} espacios</Subtitle>

          {false && isMobile && (
            <Button
              variant="primary"
              size="small"
              $isMapButton
              leftIcon={<Map />}
            >
              VER MAPA
            </Button>
          )}

          <Header>
            <Button
              variant="floating"
              onClick={handleOpenModal}
              size="small"
              leftIcon={<FilterIcon />}
            >
              {`FILTROS · ${filterCount}`}
            </Button>
            <SectionRight>
              {false && !isMobile && (
                <Button variant="floating" size="small" leftIcon={<Map />}>
                  VER MAPA
                </Button>
              )}
              <Select
                value={sort || 'default'}
                options={options}
                onChange={handleOrderChange}
              />
            </SectionRight>
          </Header>

          {isLoading && (
            <SpacesContainer>
              {range(8).map((item) => (
                <SpaceLoader opacity={1} key={item} />
              ))}
            </SpacesContainer>
          )}

          {!isLoading && spaces.length === 0 && <Placeholder />}

          {!isLoading && spaces.length !== 0 && (
            <SpacesContainer>
              {spaces.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage * ITEMS_PER_PAGE < spaces.length ? currentPage * ITEMS_PER_PAGE : spaces.length)
              ).map((space) => (
                <SpaceCard
                  linkProps={{
                    to: `/spaces/${space.slug}${window.location.search}`,
                  }}
                  key={space.id}
                  title={space.name}
                  location={space.fullLocation}
                  maxCapacity={space.capacity}
                  images={space.images}
                  price={space.price}
                  canBeBookedInstantly={space.canInstantBooking}
                />
              ))}
            </SpacesContainer>
          )}

          <Section>
            {tempCount.length > 0 && (
              <Pagination
                page={currentPage}
                pages={Math.ceil(tempCount.length / ITEMS_PER_PAGE)}
                onChangePage={handleUpdatePage}
              />
            )}
          </Section>
        </Content>
      </Container>
    </>
  );
};

export default Search;
