import React from 'react';
import useConnect from './connect';

const LoginRedirect = () => {
  const { text } = useConnect();

  return <p>{text}</p>;
};

export default LoginRedirect;
