import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Props } from './types';

const PrivateRoute: FC<Props> = ({
  isAuthenticated,
  redirect = '/',
  ...props
}) => {
  if (!isAuthenticated) {
    return <Redirect to={redirect} />;
  }
  return <Route {...props} />;
};

export default PrivateRoute;
