import styled from 'styled-components';
import { H5, Body } from 'components/Typography';
import DefaultCheckbox from 'components/Checkbox';
import { from } from 'styles/media';
import DefaultTextArea from 'components/TextArea';

export const Container = styled.div`
  padding: 2rem;
  height: 100%;

  ${from.mobile} {
    padding: 2.5rem;
  }
`;

export const Title = styled(H5)`
  margin-bottom: 1rem;
`;

export const Text = styled(Body)``;

export const Checkbox = styled(DefaultCheckbox)``;

export const TextArea = styled(DefaultTextArea)`
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.05rem;
  letter-spacing: 4%;
`;

export const CancelButton = styled.button`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.black};
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  border: 0;
  background: none;
  padding: 0;
  outline: none;
  margin-top: 1.5rem;
  cursor: pointer;
`;
